import {getLocalStorageValue, setLocalStorageValue} from "../localStorage";

export function validation(values, rules) {

  const errors = []
  const validations = []

  for(let i in rules) {
    const rule = rules[i];
    // if (values.indexOf(i) === -1) {
    //   throw Error("Chybí požadovaná hodnota pro validaci. " + i);
    // }

    if (rule.match === "not_empty") {
      if (values[i] === null || values[i] === undefined || values[i] === "") {
        errors.push(rule.message);
        validations.push(i)
      }
    }

  }

  return {
    errors: errors,
    validations: validations
  }
}


export function saveDocumentFilter(filter) {
  setLocalStorageValue("filter", JSON.stringify(filter))
}

export function getDocumentFilter() {
  let filters = getLocalStorageValue("filter")
  let filters_start = {
    tab: 'all',
    labels: [],
    period: 'all'
  }
  if (filters) {
    filters = JSON.parse(filters)
  }

  return {...filters_start, ...filters};

}

export function resetDocumentFilter() {
  let filters_start = {
    tab: 'all',
    labels: [],
    period: 'all'
  }

  saveDocumentFilter(filters_start)
  return {...filters_start};

}

export function createFilterRequest(filters, skip = []) {
  let query = []
  for(let i in filters) {
    const filter = filters[i];
    if (filter !== "null" && filter !== undefined) {
      if (skip.indexOf(i) === -1) {
        if (Array.isArray(filter)) {
          if (filter.length > 0) {
            query.push(i+"="+filter)
          }
        } else {
          query.push(i+"="+filter)
        }
      }
    }
  }

  return query.join('&');
}

export function isActiveFilter(filters, skip = []) {
  let query = []
  for(let i in filters) {
    const filter = filters[i];
    if (filter !== "null" && filter !== undefined) {
      if (skip.indexOf(i) === -1) {
        if (Array.isArray(filter)) {
          if (filter.length > 0) {
            query.push(i + "=" + filter)
          }
        } else {
          query.push(i+"="+filter)
        }
      }
    }
  }

  return query.length > 0;
}

import React from 'react'
import {
  CCol,
  CContainer,
  CRow
} from '@coreui/react'
import PrivateLayout from "../../../helpers/privateLayout";
import Translation from "../../../components/translation";

const PageError = () => {
  return (
    <PrivateLayout key={'404'}>
      <div className="c-app c-default-layout flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md="6">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4"><Translation>Ops..</Translation></h1>
                <h4 className="pt-3"><Translation>Bohužel se vyskytla chyba</Translation></h4>
                <p className="text-muted float-left"><Translation>Prosím opakujte akci později</Translation></p>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </PrivateLayout>
  )
}

export default PageError

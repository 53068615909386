import { makeAutoObservable, action } from 'mobx'
import { apiRequest, getHeaders } from '../../helpers/request'
import { REQ_TYPE } from '../../constans/request'
import CompanyStore from './../Company'
class UserStore {
  actualUser = {};
  isLoading = false;
  loadingUser = false;
  loadingDocuments = false;
  loadingCompany = false

  constructor() {
    makeAutoObservable(this, {
      setLoadingUser: action,
      setLoadingDocuments: action,
      getUserData: action,
      getDocuments: action,
      setDocuments: action,
      setUserData: action,
      set: action
    })
  }

  set(name, value) {
    this[name] = value;
  }

  setUserData(data) {
    this.actualUser = data;
  }

  setLoadingUser(value) {
    this.loadingUser = value;
  }

  setDocuments(data) {
    this.documents = data;
  }

  setLoadingDocuments(value) {
    this.loadingDocuments = value;
  }


  async getUserData() {
    this.setLoadingUser(true);
    let res = await apiRequest("/user", REQ_TYPE.get, null, getHeaders());
    if (res.data.user) {
      this.setUserData(res.data.user)
    }
    // if (res.data.companies) {
    //   CompanyStore.setCompanies(res.data.companies);
    // }
    this.setLoadingUser(false);
  }

  async getDocuments () {
    this.setLoadingDocuments(true);
    let res = await apiRequest("/user/documents", REQ_TYPE.get, null, getHeaders());
    if (res.data) {
      this.setDocuments(res.data);
    }
    this.setLoadingDocuments(false);
  }


  async getCompanies () {
    this.set('loadingCompanies',true);
    let res = await apiRequest("/user/companies", REQ_TYPE.get, null, getHeaders());

    if (res.data) {
      CompanyStore.setCompanies(res.data)
    }
    this.set('loadingCompanies',false);
  }

  async setTableSetting (table_data) {

    let res = await apiRequest("/user/document_table", REQ_TYPE.post, {tableSettings: table_data}, getHeaders());

  }
}

export default new UserStore()

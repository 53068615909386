import React, {Component} from "react";
import {
  CButton,
  CTooltip,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CCol,
  CRow,
  CLabel,
  CSelect, CInput, CTextarea, CFormGroup,
  CInputGroup,
} from "@coreui/react";
import {observer} from "mobx-react";
import Translation from "../../../../components/translation";
import CIcon from "@coreui/icons-react";
import NoTableItems from "../../../../components/NoTableItems";
import CompanyStore from "./../../../../stores/Company";
import DocumentStore from "./../../../../stores/Document";
import FileBase64 from 'react-file-base64';
import {showNotification} from "../../../../helpers/notification";
import {NOTIFICATION_TYPE} from "../../../../constans/notification";
import ButtonDefault from "../../../../components/Button";
import DocumentLabelsInlineSelect from "../../../../components/DocumentLabelsInlineSelect";
import {RECEIPT_TYPES} from "../../../../constans/base";
import jsPDF from "jspdf";
import AgendaStores from "./../../../../stores/Agenda";
import PDF2 from "../../../../components/PDF2";
import moment from "moment";
import EXIF from "exif-js";
import {base64ToArrayBuffer, base64toBlob} from "../../../../helpers/base";
const A4_PAPER_DIMENSIONS = {
  width: 210,
  height: 297,
};
const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

class UploadModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      type: null,
      total_amount: undefined,
      name: '',
      extend: '',
      documents: [],
      uploading: false,
      create_new_label: false,
      label_name_new: undefined,
      label_color_new: null,
      select_label: [],
      loading: false,
      receipt_type: null
    }
  }

  async getFiles(documents) {
    let price = null
    let actualState = this.state;
    let filename =  documents.name
    let extend = '';

    let splitFileName = filename.split('.')
    extend = splitFileName[splitFileName.length -1];
    splitFileName[splitFileName.length -1] = ''
    filename = splitFileName.join('');

    documents = { ...documents, exif: await EXIF.readFromBinaryFile(base64ToArrayBuffer(documents.base64))}
    actualState.documents.push(documents)
    this.setState({
      loading: false,
      found_price: price,
      name: filename,
      description: '',
      extend: "."+extend,
      documents: actualState.documents
    })
  }

  async componentDidMount() {
    await CompanyStore.getAllLabels()
  }

  removeDocument(index) {
    const actualDocuments = this.state.documents;
    let filterDocuments = actualDocuments.filter((item,itemIndex) => parseInt(index) !== itemIndex)
    this.setState({
      documents: filterDocuments
    })
  }

  imageDimensionsOnA4 (dimensions) {
    const isLandscapeImage = dimensions.width >= dimensions.height;
    // If the image is in landscape, the full width of A4 is used.
    if (isLandscapeImage) {
      return {
        width: A4_PAPER_DIMENSIONS.width,
        height:
          A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
      };
    }

    // If the image is in portrait and the full height of A4 would skew
    // the image ratio, we scale the image dimensions.
    const imageRatio = dimensions.width / dimensions.height;
    if (imageRatio > A4_PAPER_RATIO) {
      const imageScaleFactor =
        (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

      const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

      return {
        height: scaledImageHeight,
        width: scaledImageHeight * imageRatio,
      };
    }

    // The full height of A4 can be used without skewing the image ratio.
    return {
      width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
      height: A4_PAPER_DIMENSIONS.height,
    };
  };

  async generatePdfFromImages (images) {

    // Default export is A4 paper, portrait, using millimeters for units.
    const doc = new jsPDF();

    // We let the images add all pages,
    // therefore the first default page can be removed.
    doc.deletePage(1);

    images.forEach((image) => {
      let rotate = 0;
      const imageDimensions = this.imageDimensionsOnA4({
        width: image.width,
        height: image.height,
        isRotate: rotate > 0
      });

      let x = (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2;
      let y = (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2;
      let w = imageDimensions.width;
      let h = imageDimensions.height;
      if (image.exif ) {
        switch (image.exif.Orientation) {
          case 1:
            rotate = 0;
            break;
          case 2:
            rotate = 0;
            break;
          case 3:
            rotate = -180;
            y = -150;
            x = 210;
            // y = 0;
            w = 210;
            h = 150;
            break;
          case 4:
            rotate = 180;
            break;
          case 5:
            rotate = -90;
            x = 0;
            y = -210;
            w = 297;
            h = 210;
            break;
          case 6:
            rotate = -90;
            x = 0;
            y = -210;
            w = 297;
            h = 210;
            break;
          case 7:
            rotate = -90;
            x = 0;
            y = -210;
            w = 297;
            h = 210;
            break;
          case 8:
            rotate = -270;
            x = w;
            w = 297;
            h = 210; //210;
            break;
        }
      }

      doc.addPage();
      doc.addImage(
        image.src,
        image.imageType, x, y, w, h,null,null, rotate);
    });
    //
    // var reader = new FileReader();
    // reader.readAsDataURL(doc.output("blob"));
    function blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    }

    // const pdfURL = doc.output("bloburl");
    // window.open(pdfURL, "_blank");
    // Creates a PDF and opens it in a new browser tab.
    return await blobToBase64(doc.output("blob"))

  };

  async handleSend() {
    let sentDocuments = [];
    this.setState({
      uploading: true
    })

    if (this.state.documents[0].name.toLowerCase().indexOf('pdf') > -1 )  {

      for (let i in this.state.documents) {
        const document = this.state.documents[i];
        let data = {
          name: this.state.name+this.state.extend,
          originalName: document.name.toLowerCase().indexOf('image.') > -1? this.state.name+"_"+moment().unix()+this.state.extend : document.name,
          size: document.file.size,
          company_id: CompanyStore.detail.id,
          base64: document.base64,
          total_amount: this.state.total_amount,
          document_type: this.state.receipt_type,
          agenda_code: this.state.agenda_code,
          description: this.state.description || this.state.name,
          labels: this.state.select_label,
          type: this.state.type
        }
        const status = await DocumentStore.add(data);
        sentDocuments.push(status)

      }

      if (sentDocuments.length === this.state.documents.length) {
        CompanyStore.getDocuments(CompanyStore.detail.id)
        showNotification(NOTIFICATION_TYPE.success, <Translation>Dokumenty úspěšně přidány</Translation>);
        this.props.close();
      }


    } else {

      let prepareDoc = []
      for (let i in this.state.documents) {
        const document = this.state.documents[i];
        const img = new Image();
        img.src = encodeURI(document.base64)

        prepareDoc.push({
          name: document.name,
          src: encodeURI(document.base64),
          imageType: document.type,
          width: img.naturalWidth,
          height: img.naturalHeight,
          exif: document.exif
        })

      }

      let g = await this.generatePdfFromImages(prepareDoc)


      // for (let i in this.state.documents) {
      const document = this.state.documents[0];
      let data = {
        name: this.state.name+this.state.extend,
        originalName: document.name.toLowerCase().indexOf('image.') > -1? this.state.name+"_"+moment().unix()+this.state.extend : document.name,
        size: document.file.size,
        company_id: CompanyStore.detail.id,
        base64: document.base64,//g,
        total_amount: this.state.total_amount,
        document_type: document.type,//'application/pdf',
        agenda_code: this.state.agenda_code,
        description: this.state.description || this.state.name,
        labels: this.state.select_label,
        type: this.state.type
      }
      const status = await DocumentStore.add(data);
      if (status === 200) {
        CompanyStore.getDocuments(CompanyStore.detail.id)
        showNotification(NOTIFICATION_TYPE.success, <Translation>Dokumenty úspěšně přidány</Translation>);
        this.props.close();
      }

      // }
    }

  }

  handleChangeDescription(index, e) {
    let documents = this.state.documents
    documents[index]['description'] = e.target.value;

    this.setState({
      documents: documents
    })
  }

  handleCreateNewLabel(e) {
    e.preventDefault();
    this.setState({
      create_new_label: true
    })
    return false;
  }

  handleChangeColor(color) {
    this.setState({
      label_color_new: color.hex
    })
  }

  handleChangeLabelName(e) {
    this.setState({
      label_name_new: e.target.value
    })
  }

  handleSetLabel(label) {
    let new_label_list = this.state.select_label || [];
    const find_label = this.state.select_label.find(item => item.id === label.id)

    if (find_label) {
      new_label_list = this.state.select_label.filter(item => item.id !== label.id);
    } else {
      new_label_list.push(label);
    }

    this.setState({
      select_label: new_label_list
    })
  }

  async handleStoreNewLabel() {
    const data = {
      name: this.state.label_name_new,
      color: this.state.label_color_new
    }

    if (await CompanyStore.creatingNewLabel(data)) {
      await CompanyStore.getAllLabels();
      this.setState({
        label_name_new: undefined,
        label_color_new: undefined,
        create_new_label: false
      })
    }
  }

  renderLabels() {
    let html = [];
    for(let i in this.state.select_label) {
      const label = this.state.select_label[i];

      html.push(<DocumentLabelsInlineSelect
        key={i}
        create_new_label={this.state.create_new_label}
        select_label={label}
        label_name_new={this.state.label_name_new}
        label_color_new={this.state.label_color_new}
        handleSetLabel={this.handleSetLabel.bind(this)}
        handleCreateNewLabel={this.handleCreateNewLabel.bind(this)}
        handleChangeLabelName={this.handleChangeLabelName.bind(this)}
        handleChangeColor={this.handleChangeColor.bind(this)}
        handleStoreNewLabel={this.handleStoreNewLabel.bind(this)}
      />)

    }

    html.push(<DocumentLabelsInlineSelect
      create_new_label={this.state.create_new_label}
      select_label={null}
      label_name_new={this.state.label_name_new}
      label_color_new={this.state.label_color_new}
      handleSetLabel={this.handleSetLabel.bind(this)}
      handleCreateNewLabel={this.handleCreateNewLabel.bind(this)}
      handleChangeLabelName={this.handleChangeLabelName.bind(this)}
      handleChangeColor={this.handleChangeColor.bind(this)}
      handleStoreNewLabel={this.handleStoreNewLabel.bind(this)}
    />)
    return html;
  }

  renderDocumentDescription() {
    return null;
    return <CRow className="mb-3">
      <CCol md="3">
        <CLabel htmlFor="email-input" className=" mb-0">
          <strong>
            <Translation>Poznámka</Translation>
          </strong>
        </CLabel>
      </CCol>
      <CCol md="9">
        <CTextarea
          name={'description'}
          onChange={this.handleChangeInputs.bind(this)}
          rows="3"
        >{this.state.description}</CTextarea>
      </CCol>
    </CRow>

  }

  renderDocuments() {
    const html = [];

    for(let i in this.state.documents) {
      const document = this.state.documents[i];
      html.push(<tr key={i}>
        <td>
          {document.name}
        </td>
        <td>{document.size}</td>
        <td>
          {this.renderLabels()}
        </td>
        <td>
          <CButton
            key={'1'}
            color={'danger'}
            variant={'ghost'}
            onClick={this.removeDocument.bind(this, i)}
          >
            <CTooltip
              content={<Translation>Odebrat</Translation>}
              placement={"top"}
            >
              <CIcon name="cil-trash"/>
            </CTooltip>
          </CButton>
        </td>
      </tr>)
    }

    if (html.length === 0) {
      html.push(
        <tr key={0}>
          <td colSpan={4}>
            <NoTableItems text={"Žádné dokumenty k nahrání"}></NoTableItems>
          </td>
        </tr>
      )
    }
    return html;
  }

  handleSetType(type) {
    this.setState({
      type: type,
      agenda_code: type.indexOf("receipt") > -1? type : "invoice-in",
      loading: true
    })
    if (this.state.documents[0] && this.state.documents[0].name.toLowerCase().indexOf('pdf') === -1) {
      document.getElementById("button_take_photo").getElementsByTagName("input")[0].setAttribute('accept', 'image/*')
    }
    document.getElementById("button_take_photo").getElementsByTagName("input")[0].click();
  }

  handleChangeInputs(e) {
    let data = this.state;
    data[e.target.name] = e.target.value;

    this.setState(data);
  }

  usedFoundPrice(price) {
    this.setState({
      total_amount: price
    })
  }

  renderType() {
    const options = [<option key={"0_1"} value={null}>Vyberte typ účtenky</option>];
    for(let i in RECEIPT_TYPES) {
      const receipt_type = RECEIPT_TYPES[i];
      options.push(<option key={i} value={receipt_type.id}>{receipt_type.text}</option>)
    }

    return <CRow className="mb-3">
      <CCol md={`3`}>
        <CLabel htmlFor="email-input">
          <strong>
            <Translation>Typ účtenky</Translation>
          </strong>
        </CLabel>
      </CCol>
      <CCol md={`9`}>
        <CSelect
          value={this.state.receipt_type}
          onChange={this.handleChangeInputs.bind(this)}
          name={'receipt_type'}
        >
          {options}
        </CSelect>
      </CCol>

    </CRow>
  }

  renderAgenda() {

    return <React.Fragment>
      <CFormGroup row className="align-items-center">
        <CCol md="3">
          <CLabel htmlFor="email-input" className=" mb-0">
            <strong>
              <Translation>Agenda</Translation>
            </strong>
          </CLabel>
        </CCol>
        <CCol md={9}>
          <CSelect
            value={this.state.agenda_code }
            onChange={this.handleChangeInputs.bind(this)}
            name={"agenda_code"}
          >
            <option key={"0-1"} value={null} selected={!this.state.agenda_code}>Vyberte agendu</option>
            {AgendaStores.data.map((item, index) => {
              return <option key={index} value={item.code}>{item.title}</option>
            })}
          </CSelect>
        </CCol>
      </CFormGroup>
    </React.Fragment>
  }

  renderImage() {
    let images = []

    for (let i in this.state.documents) {
      images.push(<div className={'mb-3'} key={i}>
        <img className="w-100" src={encodeURI(this.state.documents[i].base64)} alt=""/>
        {(this.state.documents.length > 1)?
          <div className="btn btn-link text-danger cursor-pointer" onClick={() => {
            let newDocuments = this.state.documents.filter((item, index) => parseInt(index) !== parseInt(i));
            if (newDocuments.length === 0) {
              this.props.close()
              return null;
            }
            this.setState({
              documents: newDocuments
            })
          }}>
            <span className="pr-1"> Odebrat obrázek </span><CIcon name="cil-trash"/>
          </div>
          : null}
      </div>)
    }

    return <div>
      {images}
    </div>
  }

  renderReceipt() {

    if (this.state.documents.length > 0) {
      return <CRow>
        <CCol>
          <div>

            <div>
              {(this.state.documents[0].name.toLowerCase().indexOf('pdf') > -1)?
                <PDF2
                  document={{
                    document: this.state.documents[0].base64.replace("data:application/pdf;base64,", '')
                  }}
                />
                :
                this.renderImage()
              }
            </div>
          </div>
          {/*<div>*/}
          {/*  {( this.state.documents.length > 0 && this.state.documents[0] && this.state.documents[0].name.toLowerCase().indexOf('pdf') === -1)?*/}
          {/*      <div className="btn btn-outline-info" onClick={()=> {*/}
          {/*        if (this.state.documents[0] && this.state.documents[0].name.toLowerCase().indexOf('pdf') === -1) {*/}
          {/*          document.getElementById("button_take_photo").getElementsByTagName("input")[0].setAttribute('accept', 'image/*')*/}
          {/*        }*/}
          {/*        document.getElementById("button_take_photo").getElementsByTagName("input")[0].click()*/}
          {/*      }}>*/}
          {/*        Přidat další obrázek jako součást hlavního dokumentu*/}
          {/*      </div>*/}
          {/*    :*/}
          {/*    null*/}
          {/*  }*/}
          {/*</div>*/}
        </CCol>
        <CCol>
          <CRow className="mb-3">
            <CCol className="border-bottom">
              <h5><Translation>Informace o dokladu</Translation></h5>
            </CCol>
          </CRow>
          <CRow className="mb-3">
            <CCol md={`3`}>
              <CLabel htmlFor="email-input">
                <strong>
                  <Translation>Název dokumentu</Translation>
                </strong>
              </CLabel>
            </CCol>
            <CCol md={`9`}>

              <CInputGroup>
                <CInput
                  type="text"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleChangeInputs.bind(this)}
                />
                {/*<CInputGroupAppend>*/}
                {/*  <CInputGroupText>{this.state.extend}</CInputGroupText>*/}
                {/*</CInputGroupAppend>*/}
              </CInputGroup>
            </CCol>

          </CRow>
          <CRow className="mb-3">
            <CCol md={`3`}>
              <CLabel htmlFor="email-input">
                <strong>
                  <Translation>Štítky</Translation>
                </strong>
              </CLabel>
            </CCol>
            <CCol md={`9`}>
              {this.renderLabels()}
            </CCol>

          </CRow>
          {this.renderAgenda()}
          {/*{this.renderType()}*/}
          {this.renderDocumentDescription()}
          <CRow className="mb-3">
            <CCol md={`3`}>
              <CLabel htmlFor="email-input">
                <strong>
                  <Translation>Částka</Translation>
                </strong>
              </CLabel>
            </CCol>
            <CCol md={`9`}>
              <CInput
                type="text"
                name="total_amount"
                value={this.state.total_amount}
                onChange={this.handleChangeInputs.bind(this)}
              />
              {this.state.found_price?
                <div><small><Translation>Nalezená částka</Translation>:</small> <strong className="cursor-pointer"><span onClick={this.usedFoundPrice.bind(this, this.state.found_price)}>{this.state.found_price}</span></strong></div>
                : null}
            </CCol>

          </CRow>
        </CCol>
      </CRow>
    }
  }

  render() {
    return <CModal
      show={true}
      centered={true}
      onClose={() => this.props.close()}
      size={'xl'}
    >
      <CModalHeader closeButton>
        <CModalTitle>Dokumenty k nahrání</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {(!this.state.type)?
          <>
            <CRow className="mb-3">
              <CCol className="text-center">
                <h4><Translation>Vyberte kategorii dokladu</Translation></h4>
              </CCol>
            </CRow>
            <CRow>
              <CCol className="justify-content-center align-items-center text-center">


                <ButtonDefault
                  loading={this.state.loading}
                  CButtonSettings={{
                    className: ` mr-3 `,
                    color: "info",
                    // disabled: this.isDisableFinishButton(),
                    // variant: this.state.selectedFileId === data.id? "" :"outline",
                    onClick: this.handleSetType.bind(this, 'receipt-cash'),
                  }}>
                  <Translation>Účtenka hotově</Translation>
                </ButtonDefault>

                <ButtonDefault
                  loading={this.state.loading}
                  CButtonSettings={{
                    className: ` mr-3`,
                    color: "info",
                    // disabled: this.isDisableFinishButton(),
                    // variant: this.state.selectedFileId === data.id? "" :"outline",
                    onClick: this.handleSetType.bind(this, 'receipt-card'),
                  }}>
                  <Translation>Účtenka kartou</Translation>
                </ButtonDefault>

                <ButtonDefault
                  loading={this.state.loading}
                  CButtonSettings={{
                    color: "info",
                    // disabled: this.isDisableFinishButton(),
                    // variant: this.state.selectedFileId === data.id? "" :"outline",
                    onClick: this.handleSetType.bind(this, 'document'),
                  }}>
                  <Translation>Ostatní doklady</Translation>
                </ButtonDefault>
              </CCol>
            </CRow>
          </>
          : null}

        {this.renderReceipt()}

        <div className="d-none" id={"button_take_photo"}>
          <FileBase64
            accept="image/*"
            multiple={ false }
            onDone={ this.getFiles.bind(this) } >
          </FileBase64>
        </div>
      </CModalBody>
      <CModalFooter>
        {(this.state.type)?
          <>
            {(this.state.documents.length > 0)?
              <ButtonDefault
                loading={DocumentStore.uploading}
                CButtonSettings={{
                  disabled: DocumentStore.uploading,
                  color: "primary",
                  onClick: this.handleSend.bind(this),
                }}
              >
                <Translation>Nahrát dokumenty</Translation>
              </ButtonDefault>
              : null}{' '}
            <CButton
              color="secondary"
              onClick={() => this.props.close()}
            ><Translation>Zavřít</Translation></CButton>
          </>
          : null}
      </CModalFooter>
    </CModal>
  }
}

export default observer(UploadModal)

import React, { useState } from 'react';
import { Document, Page,pdfjs } from 'react-pdf';
import {observer} from "mobx-react";
import DocumentStore from "../../stores/Document";

export default observer(function PDF2(props) {

  pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  let url = props.document.url;

  if (props.document.url && props.document.url.indexOf('https') === -1) {
    url = `data:application/pdf;base64,${encodeURI(props.document.url)}`
  }

  function renderPages() {
    let html = []
    for(let i = 1; i <= numPages; i++) {
      html.push(<Page pageNumber={i} scale={1.5} />)
    }

    return html
  }

  url = (props.document.url)? url : `data:application/pdf;base64,${encodeURI(props.document.document)}`
  return (
    <>
      <div className="main scrollHorizontal overflow-hidden">
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          onClick={() => {
            if (props.useModal) {
              DocumentStore.showModal = true;
            }
          }}
        >
          {renderPages()}
        </Document>
      </div>
    </>
  );
})

import CompanyStore from "../../stores/Company";
import {TEST_IC_COMPANIES} from "../../constans/base";
import {AiOutlineFileText, AiOutlineFileUnknown} from "react-icons/ai";
import {SiMicrosoftexcel} from "react-icons/si";
import React from "react";

export const LIST_OF_CURRENCY = {
  "kc,czk": {
    symbol: "Kč",
  },
  eur: {
    symbol: "EUR",
  },

}

export function daysWord(number) {
  if (number >= 2 && number <= 4) {
    return 'dny'
  } else if (number >= 5 && number <= 11) {
    return 'dní'
  }

  return 'den'

}

export function codeBase64(data) {
  let bufferData = new Buffer(data);
  return bufferData.toString('base64');
}

export function  base64toBlob(data) {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

  const bytes = atob(base64WithoutPrefix);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: 'application/pdf' });
};

export function base64ToArrayBuffer (base64) {
  base64 = base64.replace(/^data\:([^\;]+)\;base64,/gmi, '');
  var binaryString = atob(base64);
  var len = binaryString.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}


export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1)
    n -= 1 // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime })
}

export function isTestCompany() {
  if (CompanyStore.detail) {
    return TEST_IC_COMPANIES.includes(CompanyStore.detail.ic)
  }
  return false;
}

export function getSynonymsByType(type) {
  if (type === "total_price") {
    return ['celkem', 'cena celkem', 'částka k úhradě']
  }
  return null;
}


export function getValueFromOCR(data, type) {
  const new_data = data.toLowerCase().split("\n")
  const synonyms = getSynonymsByType(type);
  let found_lines = [];
  for(let i in new_data) {
    const line = new_data[i];
    if (synonyms) {
      for(let j in synonyms) {
        const synonym = synonyms[j];
        if (line.indexOf(synonym) > -1) {
          found_lines.push(i);
        }
      }
    }
  }

  if (found_lines.length > 0) {

    // for(let i in found_lines) {
      const found_line = found_lines[found_lines.length - 1];
      let words = new_data[found_line].split(' ');

      for(let j in words) {
        const word = words[j];
        if (type === 'total_price') {
          if (!isNaN(parseFloat(word))) {
            return parseFloat(word);
          }
        }
      }
    // }
  }

}


export function renderDocumentIcon(filename) {
  let icon = <AiOutlineFileUnknown size={150} />;

  if (filename.toLowerCase().indexOf('.xl') > -1) {
    icon = <SiMicrosoftexcel size={150}/>
  } else if (filename.toLowerCase().indexOf('.doc') > -1 || filename.toLowerCase().indexOf('.txt') > -1) {
    icon = <AiOutlineFileText size={150}/>
  }


  return <div className="w-100 d-flex align-items-center justify-content-center">
    {icon}
  </div>
}


export function MoneyFormat(num, currency = "Kč") {
  if (!num) {
    return ' - '
  }
  let p = null
  num = num.toString().replace(',','.')
  if (num.indexOf('.') === -1 && num.indexOf(',') === -1) {
    p = [num,'00']
  } else {
    num = Number(num)
    p = num.toFixed(2).split(".");
  }

  return p[0].split("").reverse().reduce(function(acc, num, i, orig) {
    return  num==="-" ? acc : num + (i && !(i % 3) ? " " : "") + acc;
  }, "") + "," + p[1] + " "+ currency;
}

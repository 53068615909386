import React, {useEffect, useState} from "react";
import CompanyStore from "../../../../stores/Admin/Company";
import Translation from "../../../../components/translation";
import {CBadge, CButton, CDataTable, CTooltip} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {toJS} from "mobx";
import LoadingComponent from "../../../../components/LoadingComponent";
import {observer} from "mobx-react";

const AdminCompanies = observer((props) => {
  // const [companies, setCompanies] = useState([])
  useEffect(() => {
    async function getAll() {
      await CompanyStore.getAll()
    }

    getAll()
  }, [])

  const fields = [
    {
      label: <Translation>Název</Translation>,
      key: 'name'
    },
    {
      label: <Translation>Přijatých dokumentů</Translation>,
      key: 'complete_documents',
      _style: { width: '20%'}
    },
    {
      label: <Translation>Uživatelů</Translation>,
      key: 'users',
      _style: { width: '20%'}
    },

    // {
    //   label: <Translation>Akce</Translation>,
    //   key: 'action',
    //   _style: { width: '20%'}
    // },
  ]

  const handleEdit = (e) => {

  }

  const handleRemove = (e) => {

  }

  const handleCreate = () => {
    props.history.push('/admin/zakaznici/novy')
  }

  const handleShowDetail = (id) => {

  }

  if (CompanyStore.loading) {
    return <LoadingComponent/>
  }

  return <React.Fragment>
    <div className="card border-radius-t0 border-top-0">
      <div className="card-body">
        <div className="body-content">

          <div className="d-inline-flex justify-content-between w-100 mb-2 align-items-center">
            <div>
              <h4>
                <Translation>Společnosti</Translation>
              </h4>
            </div>
            <div className="button-area">

            </div>
          </div>
          <div>
            <CDataTable
              key={toJS(CompanyStore.data).length}
              items={toJS(CompanyStore.data)}
              fields={fields}
              loading={CompanyStore.loading}
              hover
              striped
              // bordered
              size="sm"
              itemsPerPage={10}
              pagination={{
                align: 'end'
              }}
              scopedSlots = {{
                "name": (item) => (
                  <td className="cursor-pointer" onClick={()=> handleShowDetail(item.id)}>
                    <div>
                      <strong>
                        {item.name}
                      </strong>
                    </div>
                    <div>
                      <small>IČ: {item.ic}</small>
                    </div>
                  </td>
                ),
                'complete_documents': (item) => (
                  <td>
                    <CBadge color="primary" shape={['rounded-pill']}>
                      <Translation>{item.statistic.length}</Translation>
                    </CBadge>

                  </td>
                ),
                'users': (item) => (
                  <td>
                    <CBadge color="primary" shape={['rounded-pill']}>
                      <Translation>{item.user_company_data.length}</Translation>
                    </CBadge>

                  </td>
                ),
                'action':
                  (item)=>(
                    <React.Fragment>

                      <td>
                        {/*<CTooltip*/}
                        {/*  content={<Translation>Upravit uživatele</Translation>}*/}
                        {/*  placement={"top"}*/}
                        {/*>*/}
                        {/*  <CButton*/}
                        {/*    key={'0'}*/}
                        {/*    color={'warning'}*/}
                        {/*    variant={'ghost'}*/}
                        {/*    onClick={()=> handleEdit(item.id)}*/}
                        {/*  >*/}
                        {/*    <CIcon name="cil-pencil"/>*/}
                        {/*  </CButton>*/}
                        {/*</CTooltip>*/}

                        {/*<CTooltip*/}
                        {/*  content={<Translation>Odebrat uživatele ze společnosti</Translation>}*/}
                        {/*  placement={"top"}*/}
                        {/*>*/}
                        {/*  <CButton*/}
                        {/*    key={'1'}*/}
                        {/*    color={'danger'}*/}
                        {/*    variant={'ghost'}*/}
                        {/*    className={"ml-2"}*/}
                        {/*    onClick={() => handleRemove(item)}*/}
                        {/*  >*/}
                        {/*    <CIcon name="cil-trash"/>*/}
                        {/*  </CButton>*/}
                        {/*</CTooltip>*/}

                      </td>

                    </React.Fragment>
                  )
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
})

export default AdminCompanies

import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthStore from './../../stores/Auth';
import { observer } from 'mobx-react';
import LoadingPage from '../LoadingPage';
import { toJS } from 'mobx';
import { getLocalStorageValue } from '../../helpers/localStorage';
import UserStore from './../../stores/User';
import ListOfCompanies from './../../components/ListOfCompanies';
import CompanyStores from './../../stores/Company'
import {hasUserReadRole, hasUserWriteRole} from "../../helpers/roles";
import PrivateLayout from "../../helpers/privateLayout";
import {MODULES} from "../../constans/modules";
import AgendaStore from "../../stores/Agenda";
import {companyHasModule} from "../../helpers/modules";


class PrivateRoute extends Component {

  async componentDidMount() {
    const companyId = getLocalStorageValue('companyId');
    if (companyId) {
      await CompanyStores.getCompany(companyId)
    }
  }


  setLocalStorate() {
    if (!localStorage.getItem('redirect') && (window.location.href.indexOf('/login') === -1) && !AuthStore.wasLogout) {
      localStorage.setItem('redirect',  window.location.href.replace( window.location.origin, ''));
    }
  }

  checkAuthorization(Component, props) {
    if (AuthStore.isLogin()) {
      UserStore.getUserData()
      return (
        <Component {...props} data={this.props.data}/>
      );
    } else {

      if (AuthStore.verifying) {
        return <LoadingPage />
      } else {

        this.setLocalStorate()
        return <LoadingPage />
      }
    }
  }
  render() {
    const {component: Component, ...rest} = this.props;
    const token = getLocalStorageValue('token');
    const companyId = getLocalStorageValue('companyId');

    if (!token) {
      this.setLocalStorate()
      console.log("redirect test")
      return (
        <Redirect
          to={{
            pathname: "/login"
          }}
        />
      );
    }

    if (!companyId) {
      return <ListOfCompanies />
    } else if (CompanyStores.loadingCompany) {
      return <LoadingPage />
    }

    if (this.props.module && CompanyStores.detail !== null) {
      let showPage = false
      if (companyHasModule(this.props.module)){

        showPage = hasUserReadRole(this.props.module) || hasUserWriteRole(this.props.module);

        if (this.props.module === MODULES.documents) {
          if (CompanyStores.detail && CompanyStores.detail.hasApprovals) {
            if (hasUserReadRole(MODULES.approvals) || hasUserWriteRole(MODULES.approvals)) {
              showPage = true;
            }
          }
        }

        if (this.props.module === MODULES.document_history) {
          showPage = hasUserWriteRole(MODULES.documents) || hasUserReadRole(MODULES.documents);
        }

        if (this.props.module === MODULES.archive) {
          showPage = true;
        }

      }


      if (!showPage) {
        return (<PrivateLayout  parent_key={AuthStore.isLogin()+"_"+companyId+"_"+CompanyStores.loadingCompany}>
          Bohužel nemáte opráveni pro zobrazení stránky
        </PrivateLayout>)
      }
    }

    return ( <PrivateLayout  parent_key={AuthStore.isLogin()+"_"+companyId+"_"+CompanyStores.loadingCompany}>
              <Route
                key={toJS(AuthStore.isLogin())}
                {...rest}
                render={(props) => this.checkAuthorization(Component, props)}
              />
        </PrivateLayout>);
  }
}

export default observer(PrivateRoute)

import {action, makeAutoObservable} from "mobx";
import {apiRequest, getHeaders} from "../../../helpers/request";
import {REQ_TYPE} from "../../../constans/request";

class CompanyStore {
  loading = false;
  loadingCreating = false;
  data = []

  constructor() {
    makeAutoObservable(this, {
      getAll: action,
      set: action
    })
  }

  set(key, value){
    this[key] = value
  }

  async getAll() {
    this.set("loading",true);
    let res = await apiRequest("/company/getAll", REQ_TYPE.get, null, getHeaders());
    this.set('data', res.data)
    this.set("loading",false)
    return res.status === 200;
  }
}

export default new CompanyStore()

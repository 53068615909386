import {action, makeAutoObservable} from "mobx";
import {apiRequest, getHeaders} from "../../../helpers/request";
import {REQ_TYPE} from "../../../constans/request";

class CustomerStore {
  loading = false;
  loadingCreating = false;
  data = []

  constructor() {
    makeAutoObservable(this, {
      getAll: action,
      set: action
    })
  }

  set(key, value){
    this[key] = value
  }


  async getAll() {
    this.set("loading",true);
    let res = await apiRequest("/customer/getAll", REQ_TYPE.get, null, getHeaders());
    this.set("loading",false)
    this.set('data', res.data)
    return res.status === 200;
  }

  async create(data) {
    this.set("loading",true);
    let res = await apiRequest("/customer/create", REQ_TYPE.post, data, getHeaders());
    this.set("loading",false)
    return res.status === 200;
  }

}

export default new CustomerStore()

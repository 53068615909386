export const logoNegative = ['300 134', `
<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd" [
\t<!ENTITY ns_extend "http://ns.adobe.com/Extensibility/1.0/">
\t<!ENTITY ns_ai "http://ns.adobe.com/AdobeIllustrator/10.0/">
\t<!ENTITY ns_graphs "http://ns.adobe.com/Graphs/1.0/">
\t<!ENTITY ns_vars "http://ns.adobe.com/Variables/1.0/">
\t<!ENTITY ns_imrep "http://ns.adobe.com/ImageReplacement/1.0/">
\t<!ENTITY ns_sfw "http://ns.adobe.com/SaveForWeb/1.0/">
\t<!ENTITY ns_custom "http://ns.adobe.com/GenericCustomNamespace/1.0/">
\t<!ENTITY ns_adobe_xpath "http://ns.adobe.com/XPath/1.0/">
]>
<svg version="1.1" id="Vrstva_1" xmlns:x="&ns_extend;" xmlns:i="&ns_ai;" xmlns:graph="&ns_graphs;"
\t xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="250 200 580 580"
\t style="enable-background:new 0 0 580 580;" xml:space="preserve">
<style type="text/css">
\t.st0{fill:#ffffff;}
</style>
<switch>
\t<foreignObject requiredExtensions="&ns_ai;" x="0" y="0" width="1" height="1">
\t\t<i:aipgfRef  xlink:href="#adobe_illustrator_pgf">
\t\t</i:aipgfRef>
\t</foreignObject>
\t<g i:extraneous="self">
\t\t<g>
\t\t\t<g>
\t\t\t\t<rect x="429.4" y="392.47" class="st0" width="28.62" height="151.88"/>
\t\t\t\t<g>
\t\t\t\t\t<polygon class="st0" points="813.41,479.46 813.41,544.35 784.78,544.35 784.78,450.83 \t\t\t\t\t"/>
\t\t\t\t\t<polygon class="st0" points="922.26,529.49 922.26,544.35 896.65,544.35 784.78,432.49 784.78,392.61 785.39,392.61
\t\t\t\t\t\t813.41,420.64 893.65,500.87 \t\t\t\t\t"/>
\t\t\t\t\t<polygon class="st0" points="922.26,392.47 922.26,509.19 893.65,480.58 893.65,392.47 \t\t\t\t\t"/>
\t\t\t\t</g>
\t\t\t\t<g>
\t\t\t\t\t<rect x="153.15" y="392.4" class="st0" width="112.02" height="28.62"/>
\t\t\t\t\t<rect x="153.15" y="454.1" class="st0" width="112.02" height="28.62"/>
\t\t\t\t\t<rect x="153.15" y="515.8" class="st0" width="112.02" height="28.62"/>
\t\t\t\t</g>
\t\t\t\t<path class="st0" d="M554.97,392.4v0.14c-1.48-0.1-2.97-0.14-4.46-0.14c-41.99,0-76.01,34.04-76.01,76.01
\t\t\t\t\tc0,41.99,34.02,76.01,76.01,76.01c1.49,0,2.98-0.04,4.46-0.14v0.14h22.16v-4.79v-23.83h-22.16h-4.46
\t\t\t\t\tc-4.85,0-9.5-0.73-13.91-2.07c-19.38-5.95-33.49-23.99-33.49-45.32c0-21.33,14.11-39.36,33.49-45.31
\t\t\t\t\tc4.41-1.34,9.06-2.07,13.91-2.07h4.46h22.16V397.2v-4.8H554.97z"/>
\t\t\t\t<path class="st0" d="M696.24,544.32c40.15-2.06,72.06-35.26,72.06-75.91c0-40.66-31.91-73.85-72.06-75.91l0,0l-22.15-0.1l0,0
\t\t\t\t\th-4.42c-0.02,0-0.03,0-0.05,0c-0.01,0-0.02,0-0.03,0l-3.93,0.1l0,0c-40.15,2.06-72.05,35.26-72.05,75.91
\t\t\t\t\tc0,40.66,31.91,73.85,72.05,75.91 M706.2,513.72c-4.41,1.34-9.07,2.07-13.91,2.07h-4.46h-18.55c-4.72-0.03-9.26-0.75-13.56-2.06
\t\t\t\t\tc-19.39-5.95-33.49-23.99-33.49-45.32c0-21.33,14.1-39.36,33.49-45.31c4.41-1.34,9.06-2.07,13.91-2.07h4.46h18.55
\t\t\t\t\tc4.72,0.03,9.26,0.75,13.56,2.06c19.38,5.95,33.49,23.99,33.49,45.32C739.68,489.74,725.58,507.77,706.2,513.72z"/>
\t\t\t\t<path class="st0" d="M336.9,392.4c-1.49,0-2.98,0.04-4.46,0.14v-0.14h-22.16h-28.63v23.82v4.8v94.77v4.8v23.83h28.63h22.16
\t\t\t\t\tv-0.14c1.48,0.1,2.97,0.14,4.46,0.14c41.99,0,76.01-34.04,76.01-76.01C412.91,426.42,378.89,392.4,336.9,392.4z M350.81,513.72
\t\t\t\t\tc-4.41,1.34-9.07,2.07-13.91,2.07h-4.46h-22.16v-8.18V429.2v-8.18h22.16h4.46c4.84,0,9.5,0.73,13.91,2.07
\t\t\t\t\tc19.38,5.95,33.49,23.98,33.49,45.32C384.3,489.74,370.19,507.77,350.81,513.72z"/>
\t\t\t</g>
\t\t\t<g>
\t\t\t\t<path class="st0" d="M958.36,515.65c3.96,0,7.35,1.4,10.15,4.2c2.8,2.8,4.2,6.18,4.2,10.15c0,4.01-1.4,7.41-4.2,10.21
\t\t\t\t\tc-2.8,2.8-6.18,4.2-10.15,4.2c-4.01,0-7.41-1.4-10.21-4.2c-2.8-2.8-4.2-6.21-4.2-10.21c0-3.96,1.4-7.35,4.2-10.15
\t\t\t\t\tC950.95,517.05,954.35,515.65,958.36,515.65z M958.36,541.12c2.97,0,5.55-1.09,7.72-3.26c2.18-2.18,3.26-4.79,3.26-7.85
\t\t\t\t\tc0-3.06-1.09-5.68-3.26-7.85c-2.18-2.18-4.75-3.26-7.72-3.26c-3.06,0-5.67,1.09-7.82,3.26c-2.16,2.18-3.23,4.79-3.23,7.85
\t\t\t\t\tc0,3.06,1.08,5.68,3.23,7.85C952.69,540.03,955.3,541.12,958.36,541.12z M962.1,530.07v0.13c1.38,0.09,2.24,0.84,2.59,2.26
\t\t\t\t\tl1.29,4.33h-5.11l-0.97-4.14c-0.26-0.65-0.75-0.97-1.49-0.97h-1.68v5.11h-4.78v-14.16c1.38-0.13,3.62-0.19,6.72-0.19
\t\t\t\t\tc2.41,0,4.14,0.31,5.17,0.94c1.03,0.63,1.55,1.73,1.55,3.33C965.4,528.73,964.3,529.85,962.1,530.07z M956.74,528.71h1.81
\t\t\t\t\tc0.77-0.04,1.29-0.16,1.55-0.35c0.26-0.19,0.39-0.57,0.39-1.13c0-0.56-0.13-0.93-0.39-1.1c-0.26-0.17-0.78-0.26-1.55-0.26h-1.81
\t\t\t\t\tV528.71z"/>
\t\t\t</g>
\t\t</g>
\t\t<g>
\t\t\t<g>
\t\t\t\t<path class="st0" d="M158.84,579.89c1.71,0,3.47,0.01,5.26,0.02c1.8,0.02,3.56,0.06,5.29,0.15c1.73,0.08,3.34,0.2,4.83,0.36
\t\t\t\t\tl-0.39,5.34H161.7c-0.87,0-1.54,0.21-1.99,0.63c-0.45,0.42-0.68,1.04-0.68,1.84v25.28h-7.42v-26.78c0-2.07,0.66-3.73,1.99-4.97
\t\t\t\t\tC154.93,580.52,156.67,579.89,158.84,579.89z M152.49,594.06h19.55v5.24h-19.55V594.06z"/>
\t\t\t\t<path class="st0" d="M194.74,580.04c0.61,0,1.16,0.18,1.63,0.53c0.47,0.36,0.78,0.83,0.95,1.41l9.7,31.53h-7.57l-6.84-24.6
\t\t\t\t\tc-0.13-0.55-0.26-1.11-0.39-1.67c-0.13-0.57-0.24-1.11-0.34-1.62h-1.31c-0.1,0.52-0.2,1.06-0.32,1.62
\t\t\t\t\tc-0.11,0.57-0.25,1.12-0.41,1.67l-6.84,24.6h-7.62l9.75-31.53c0.16-0.58,0.48-1.05,0.95-1.41c0.47-0.36,1.01-0.53,1.62-0.53
\t\t\t\t\tH194.74z M199.88,599.1v5.58h-17.37v-5.58H199.88z"/>
\t\t\t\t<path class="st0" d="M217.83,580.04v9.46c0,1.1-0.08,2.19-0.24,3.27c-0.16,1.08-0.42,2.14-0.78,3.18c0.26,1,0.49,2.03,0.7,3.08
\t\t\t\t\tc0.21,1.05,0.32,2.03,0.32,2.94v11.55h-7.37v-33.47H217.83z M221.62,593.53v5.14h-6.02v-5.14H221.62z M236.99,580.04
\t\t\t\t\tl-8.73,13.49c-0.36,0.58-0.74,1.08-1.16,1.5c-0.42,0.42-0.84,0.78-1.26,1.07v0.15c0.45,0.26,0.94,0.65,1.46,1.16
\t\t\t\t\tc0.52,0.52,0.94,1.07,1.26,1.65l9.02,14.46h-8.3L218.95,596l10.04-15.96H236.99z"/>
\t\t\t\t<path class="st0" d="M266.2,580.04v5.82h-27.56v-5.82H266.2z M256.11,580.04v33.47h-7.42v-33.47H256.11z"/>
\t\t\t\t<path class="st0" d="M297.15,580.04v20.13c0,3.4-0.42,6.12-1.26,8.17c-0.84,2.05-2.22,3.54-4.15,4.46
\t\t\t\t\tc-1.92,0.92-4.49,1.38-7.69,1.38c-3.4,0-6.11-0.46-8.15-1.38s-3.51-2.41-4.41-4.46c-0.91-2.05-1.36-4.78-1.36-8.17v-20.13h7.42
\t\t\t\t\tv20.13c0,2.1,0.19,3.74,0.56,4.92c0.37,1.18,1.01,2.01,1.92,2.5c0.91,0.49,2.12,0.73,3.64,0.73c1.52,0,2.72-0.24,3.61-0.73
\t\t\t\t\tc0.89-0.48,1.52-1.32,1.89-2.5c0.37-1.18,0.56-2.82,0.56-4.92v-20.13H297.15z"/>
\t\t\t\t<path class="st0" d="M316.55,579.55c2.94,0,5.33,0.3,7.16,0.9c1.83,0.6,3.17,1.62,4.03,3.06c0.86,1.44,1.29,3.44,1.29,5.99
\t\t\t\t\tc0,1.55-0.2,2.92-0.61,4.1c-0.4,1.18-1.08,2.13-2.01,2.84c-0.94,0.71-2.22,1.16-3.83,1.36v0.24c0.58,0.1,1.19,0.32,1.82,0.68
\t\t\t\t\tc0.63,0.36,1.22,0.88,1.77,1.58c0.55,0.7,0.95,1.62,1.21,2.79l2.91,10.43h-7.81l-2.57-10.19c-0.29-1.1-0.72-1.86-1.29-2.28
\t\t\t\t\tc-0.57-0.42-1.35-0.63-2.35-0.63c-2.2,0-4.05-0.01-5.56-0.02c-1.5-0.02-2.77-0.04-3.78-0.07c-1.02-0.03-1.93-0.06-2.74-0.1
\t\t\t\t\tl0.15-5.38h12.08c1.29,0,2.32-0.14,3.08-0.41c0.76-0.27,1.29-0.77,1.6-1.48c0.31-0.71,0.46-1.7,0.46-2.96
\t\t\t\t\tc0-1.29-0.15-2.29-0.46-2.98c-0.31-0.69-0.84-1.18-1.6-1.46c-0.76-0.27-1.79-0.41-3.08-0.41c-3.04,0-5.61,0.02-7.71,0.05
\t\t\t\t\tc-2.1,0.03-3.54,0.08-4.32,0.15l-0.73-5.29c1.36-0.16,2.64-0.27,3.83-0.34c1.2-0.06,2.51-0.1,3.93-0.12
\t\t\t\t\tC312.83,579.56,314.55,579.55,316.55,579.55z M310.97,580.04v33.47h-7.33v-33.47H310.97z"/>
\t\t\t\t<path class="st0" d="M361.33,580.04l-11.3,22.85h-6.99l-11.35-22.85h7.71l5.63,12.32c0.29,0.65,0.53,1.34,0.73,2.09
\t\t\t\t\tc0.19,0.74,0.37,1.46,0.53,2.13h0.48c0.19-0.68,0.39-1.39,0.58-2.13c0.19-0.74,0.42-1.44,0.68-2.09l5.58-12.32H361.33z
\t\t\t\t\t M350.22,598.38v15.14h-7.42v-15.14H350.22z"/>
\t\t\t\t<path class="st0" d="M388.11,579.55c2.94,0,5.33,0.3,7.16,0.9c1.83,0.6,3.17,1.62,4.03,3.06c0.86,1.44,1.29,3.44,1.29,5.99
\t\t\t\t\tc0,1.55-0.2,2.92-0.61,4.1c-0.4,1.18-1.08,2.13-2.01,2.84c-0.94,0.71-2.22,1.16-3.83,1.36v0.24c0.58,0.1,1.19,0.32,1.82,0.68
\t\t\t\t\tc0.63,0.36,1.22,0.88,1.77,1.58c0.55,0.7,0.95,1.62,1.21,2.79l2.91,10.43h-7.81l-2.57-10.19c-0.29-1.1-0.72-1.86-1.29-2.28
\t\t\t\t\tc-0.57-0.42-1.35-0.63-2.35-0.63c-2.2,0-4.05-0.01-5.56-0.02c-1.5-0.02-2.77-0.04-3.78-0.07c-1.02-0.03-1.93-0.06-2.74-0.1
\t\t\t\t\tl0.15-5.38h12.08c1.29,0,2.32-0.14,3.08-0.41c0.76-0.27,1.29-0.77,1.6-1.48c0.31-0.71,0.46-1.7,0.46-2.96
\t\t\t\t\tc0-1.29-0.15-2.29-0.46-2.98c-0.31-0.69-0.84-1.18-1.6-1.46c-0.76-0.27-1.79-0.41-3.08-0.41c-3.04,0-5.61,0.02-7.71,0.05
\t\t\t\t\tc-2.1,0.03-3.54,0.08-4.32,0.15l-0.73-5.29c1.36-0.16,2.64-0.27,3.83-0.34c1.2-0.06,2.51-0.1,3.93-0.12
\t\t\t\t\tC384.39,579.56,386.1,579.55,388.11,579.55z M382.53,580.04v33.47h-7.33v-33.47H382.53z"/>
\t\t\t\t<path class="st0" d="M419.93,579.36c2.65,0,4.9,0.28,6.74,0.85c1.84,0.57,3.32,1.5,4.44,2.81s1.93,3.09,2.45,5.34
\t\t\t\t\tc0.52,2.25,0.78,5.05,0.78,8.42c0,3.36-0.26,6.17-0.78,8.42c-0.52,2.25-1.33,4.03-2.45,5.34s-2.6,2.25-4.44,2.81
\t\t\t\t\tc-1.84,0.57-4.09,0.85-6.74,0.85c-2.65,0-4.9-0.28-6.74-0.85c-1.84-0.57-3.33-1.5-4.46-2.81c-1.13-1.31-1.96-3.09-2.47-5.34
\t\t\t\t\tc-0.52-2.25-0.78-5.05-0.78-8.42c0-3.36,0.26-6.17,0.78-8.42c0.52-2.25,1.34-4.03,2.47-5.34c1.13-1.31,2.62-2.25,4.46-2.81
\t\t\t\t\tC415.03,579.64,417.28,579.36,419.93,579.36z M419.93,585.23c-1.71,0-3.05,0.34-4,1.02c-0.95,0.68-1.63,1.85-2.04,3.52
\t\t\t\t\tc-0.4,1.67-0.61,4-0.61,7.01c0,2.98,0.2,5.3,0.61,6.99c0.4,1.68,1.08,2.86,2.04,3.54c0.95,0.68,2.29,1.02,4,1.02
\t\t\t\t\tc1.68,0,3-0.34,3.95-1.02c0.95-0.68,1.63-1.86,2.04-3.54c0.4-1.68,0.61-4.01,0.61-6.99c0-3.01-0.2-5.34-0.61-7.01
\t\t\t\t\tc-0.4-1.67-1.08-2.84-2.04-3.52C422.93,585.57,421.61,585.23,419.93,585.23z"/>
\t\t\t\t<path class="st0" d="M467.28,580.04l-9.12,31.44c-0.16,0.58-0.48,1.07-0.95,1.46c-0.47,0.39-1.03,0.58-1.67,0.58h-6.79
\t\t\t\t\tc-0.68,0-1.25-0.19-1.72-0.58c-0.47-0.39-0.77-0.87-0.9-1.46l-9.17-31.44h7.67l6.11,24.16c0.13,0.61,0.27,1.24,0.41,1.87
\t\t\t\t\tc0.15,0.63,0.27,1.25,0.36,1.87h1.26c0.1-0.61,0.22-1.24,0.36-1.89c0.15-0.65,0.3-1.28,0.46-1.89l6.06-24.11H467.28z"/>
\t\t\t\t<path class="st0" d="M499.35,580.04v30.81c0,1.78-0.89,2.67-2.67,2.67h-4.08c-0.81,0-1.42-0.19-1.84-0.56
\t\t\t\t\tc-0.42-0.37-0.81-0.93-1.16-1.67l-9.27-17.56c-0.39-0.74-0.77-1.57-1.14-2.47c-0.37-0.91-0.69-1.78-0.95-2.62h-0.53
\t\t\t\t\tc0.1,0.87,0.18,1.75,0.24,2.64c0.06,0.89,0.1,1.77,0.1,2.64v19.6h-7.03v-30.81c0-1.78,0.91-2.67,2.72-2.67h4.03
\t\t\t\t\tc0.78,0,1.37,0.19,1.77,0.56c0.4,0.37,0.8,0.93,1.19,1.67l8.98,17.03c0.39,0.74,0.79,1.57,1.21,2.47
\t\t\t\t\tc0.42,0.91,0.82,1.84,1.21,2.81h0.58c-0.1-0.97-0.17-1.92-0.22-2.84c-0.05-0.92-0.07-1.83-0.07-2.74l-0.05-18.97H499.35z"/>
\t\t\t\t<path class="st0" d="M519.38,579.36c2.65,0,4.9,0.28,6.74,0.85c1.84,0.57,3.32,1.5,4.44,2.81s1.93,3.09,2.45,5.34
\t\t\t\t\tc0.52,2.25,0.78,5.05,0.78,8.42c0,3.36-0.26,6.17-0.78,8.42c-0.52,2.25-1.33,4.03-2.45,5.34s-2.6,2.25-4.44,2.81
\t\t\t\t\tc-1.84,0.57-4.09,0.85-6.74,0.85c-2.65,0-4.9-0.28-6.74-0.85c-1.84-0.57-3.33-1.5-4.46-2.81c-1.13-1.31-1.96-3.09-2.47-5.34
\t\t\t\t\tc-0.52-2.25-0.78-5.05-0.78-8.42c0-3.36,0.26-6.17,0.78-8.42c0.52-2.25,1.34-4.03,2.47-5.34c1.13-1.31,2.62-2.25,4.46-2.81
\t\t\t\t\tC514.48,579.64,516.73,579.36,519.38,579.36z M519.38,585.23c-1.71,0-3.05,0.34-4,1.02c-0.95,0.68-1.63,1.85-2.04,3.52
\t\t\t\t\tc-0.4,1.67-0.61,4-0.61,7.01c0,2.98,0.2,5.3,0.61,6.99c0.4,1.68,1.08,2.86,2.04,3.54c0.95,0.68,2.29,1.02,4,1.02
\t\t\t\t\tc1.68,0,3-0.34,3.95-1.02c0.95-0.68,1.63-1.86,2.04-3.54c0.4-1.68,0.61-4.01,0.61-6.99c0-3.01-0.2-5.34-0.61-7.01
\t\t\t\t\tc-0.4-1.67-1.08-2.84-2.04-3.52C522.38,585.57,521.06,585.23,519.38,585.23z"/>
\t\t\t\t<path class="st0" d="M566.2,580.04v20.13c0,3.4-0.42,6.12-1.26,8.17c-0.84,2.05-2.22,3.54-4.15,4.46
\t\t\t\t\tc-1.92,0.92-4.49,1.38-7.69,1.38c-3.4,0-6.11-0.46-8.15-1.38c-2.04-0.92-3.51-2.41-4.41-4.46c-0.91-2.05-1.36-4.78-1.36-8.17
\t\t\t\t\tv-20.13h7.42v20.13c0,2.1,0.19,3.74,0.56,4.92s1.01,2.01,1.92,2.5c0.91,0.49,2.12,0.73,3.64,0.73c1.52,0,2.72-0.24,3.61-0.73
\t\t\t\t\tc0.89-0.48,1.52-1.32,1.89-2.5c0.37-1.18,0.56-2.82,0.56-4.92v-20.13H566.2z"/>
\t\t\t\t<path class="st0" d="M596.32,579.55c2.65,0,4.89,0.28,6.72,0.85c1.83,0.57,3.3,1.5,4.41,2.79c1.12,1.29,1.93,3.05,2.45,5.26
\t\t\t\t\tc0.52,2.22,0.78,4.99,0.78,8.32c0,3.33-0.26,6.11-0.78,8.32c-0.52,2.22-1.33,3.97-2.45,5.26c-1.12,1.29-2.59,2.22-4.41,2.79
\t\t\t\t\tc-1.83,0.57-4.07,0.85-6.72,0.85c-2.56,0-4.9-0.05-7.03-0.15c-2.13-0.1-4.17-0.21-6.11-0.34l3.11-5.48
\t\t\t\t\tc1.23,0.07,2.69,0.12,4.39,0.17c1.7,0.05,3.58,0.07,5.65,0.07c1.65,0,2.95-0.33,3.91-1c0.95-0.66,1.63-1.83,2.04-3.52
\t\t\t\t\tc0.4-1.68,0.61-4.01,0.61-6.99c0-2.97-0.2-5.3-0.61-6.99c-0.41-1.68-1.08-2.86-2.04-3.54c-0.95-0.68-2.26-1.02-3.91-1.02
\t\t\t\t\tc-1.97,0-3.74,0.01-5.29,0.02c-1.55,0.02-3.07,0.02-4.56,0.02l-3.3-5.24c1.94-0.13,3.97-0.24,6.09-0.34
\t\t\t\t\tC591.38,579.6,593.73,579.55,596.32,579.55z M590.6,580.04v33.47h-7.42v-33.47H590.6z"/>
\t\t\t\t<path class="st0" d="M629.65,579.36c2.65,0,4.9,0.28,6.74,0.85s3.32,1.5,4.44,2.81c1.12,1.31,1.93,3.09,2.45,5.34
\t\t\t\t\tc0.52,2.25,0.78,5.05,0.78,8.42c0,3.36-0.26,6.17-0.78,8.42c-0.52,2.25-1.33,4.03-2.45,5.34c-1.12,1.31-2.6,2.25-4.44,2.81
\t\t\t\t\ts-4.09,0.85-6.74,0.85c-2.65,0-4.9-0.28-6.74-0.85s-3.33-1.5-4.46-2.81c-1.13-1.31-1.96-3.09-2.47-5.34
\t\t\t\t\tc-0.52-2.25-0.78-5.05-0.78-8.42c0-3.36,0.26-6.17,0.78-8.42c0.52-2.25,1.34-4.03,2.47-5.34c1.13-1.31,2.62-2.25,4.46-2.81
\t\t\t\t\tS627,579.36,629.65,579.36z M629.65,585.23c-1.71,0-3.05,0.34-4,1.02c-0.96,0.68-1.63,1.85-2.04,3.52
\t\t\t\t\tc-0.4,1.67-0.61,4-0.61,7.01c0,2.98,0.2,5.3,0.61,6.99s1.08,2.86,2.04,3.54c0.95,0.68,2.29,1.02,4,1.02c1.68,0,3-0.34,3.95-1.02
\t\t\t\t\tc0.95-0.68,1.63-1.86,2.04-3.54s0.61-4.01,0.61-6.99c0-3.01-0.2-5.34-0.61-7.01c-0.4-1.67-1.08-2.84-2.04-3.52
\t\t\t\t\tC632.65,585.57,631.33,585.23,629.65,585.23z"/>
\t\t\t\t<path class="st0" d="M686.94,580.04v20.13c0,3.4-0.42,6.12-1.26,8.17c-0.84,2.05-2.22,3.54-4.15,4.46
\t\t\t\t\tc-1.92,0.92-4.49,1.38-7.69,1.38c-3.4,0-6.11-0.46-8.15-1.38c-2.04-0.92-3.51-2.41-4.42-4.46c-0.91-2.05-1.36-4.78-1.36-8.17
\t\t\t\t\tv-20.13h7.42v20.13c0,2.1,0.19,3.74,0.56,4.92s1.01,2.01,1.92,2.5c0.91,0.49,2.12,0.73,3.64,0.73s2.72-0.24,3.61-0.73
\t\t\t\t\tc0.89-0.48,1.52-1.32,1.89-2.5c0.37-1.18,0.56-2.82,0.56-4.92v-20.13H686.94z M679.13,567.96l3.11,4.85l-10.82,5.38l-2.13-3.06
\t\t\t\t\tL679.13,567.96z"/>
\t\t\t\t<path class="st0" d="M706.54,579.46c1.58,0,2.93,0.04,4.05,0.12c1.12,0.08,2.14,0.2,3.08,0.36c0.94,0.16,1.91,0.39,2.91,0.68
\t\t\t\t\tl-0.58,5.43c-1.07-0.06-2.05-0.11-2.94-0.15s-1.84-0.06-2.86-0.07c-1.02-0.02-2.24-0.02-3.66-0.02c-1.65,0-2.94,0.32-3.86,0.97
\t\t\t\t\tc-0.92,0.65-1.58,1.77-1.96,3.37c-0.39,1.6-0.58,3.81-0.58,6.62c0,2.81,0.19,5.02,0.58,6.62c0.39,1.6,1.04,2.73,1.96,3.37
\t\t\t\t\tc0.92,0.65,2.21,0.97,3.86,0.97c2.23,0,4.11-0.03,5.63-0.1c1.52-0.06,3.02-0.18,4.51-0.34l0.63,5.43
\t\t\t\t\tc-1.55,0.49-3.14,0.83-4.75,1.04c-1.62,0.21-3.62,0.32-6.02,0.32c-3.46,0-6.23-0.56-8.32-1.67c-2.09-1.12-3.59-2.93-4.51-5.46
\t\t\t\t\tc-0.92-2.52-1.38-5.92-1.38-10.19c0-4.27,0.46-7.67,1.38-10.19s2.43-4.34,4.51-5.46C700.31,580.01,703.08,579.46,706.54,579.46z
\t\t\t\t\t M713.24,568.49l1.89,2.76l-7.42,6.26h-2.96l-7.47-6.26l1.94-2.76l6.84,3.93h0.39L713.24,568.49z"/>
\t\t\t\t<path class="st0" d="M728.86,579.89c1.81,0,3.64,0.01,5.48,0.02c1.84,0.02,3.65,0.06,5.43,0.12c1.78,0.07,3.44,0.18,5,0.34
\t\t\t\t\tl-0.34,5.38h-12.86c-0.81,0-1.42,0.21-1.82,0.63c-0.41,0.42-0.61,1.04-0.61,1.84v17.08c0,0.81,0.2,1.42,0.61,1.84
\t\t\t\t\tc0.4,0.42,1.01,0.63,1.82,0.63h12.86l0.34,5.38c-1.55,0.13-3.22,0.24-5,0.32c-1.78,0.08-3.59,0.13-5.43,0.15
\t\t\t\t\tc-1.84,0.02-3.67,0.02-5.48,0.02c-2.13,0-3.86-0.62-5.17-1.87c-1.31-1.24-1.96-2.87-1.96-4.88v-20.28c0-2.04,0.66-3.67,1.96-4.9
\t\t\t\t\tC725,580.51,726.72,579.89,728.86,579.89z M722.6,593.48h20.04v5.24H722.6V593.48z"/>
\t\t\t\t<path class="st0" d="M775.04,580.04v5.82h-27.55v-5.82H775.04z M764.95,580.04v33.47h-7.42v-33.47H764.95z"/>
\t\t\t\t<path class="st0" d="M807.54,580.04v30.81c0,1.78-0.89,2.67-2.67,2.67h-4.08c-0.81,0-1.42-0.19-1.84-0.56s-0.81-0.93-1.16-1.67
\t\t\t\t\tl-9.27-17.56c-0.39-0.74-0.77-1.57-1.14-2.47c-0.37-0.91-0.69-1.78-0.95-2.62h-0.53c0.1,0.87,0.18,1.75,0.24,2.64
\t\t\t\t\tc0.06,0.89,0.1,1.77,0.1,2.64v19.6h-7.03v-30.81c0-1.78,0.91-2.67,2.72-2.67h4.03c0.78,0,1.37,0.19,1.77,0.56
\t\t\t\t\tc0.4,0.37,0.8,0.93,1.19,1.67l8.97,17.03c0.39,0.74,0.79,1.57,1.21,2.47c0.42,0.91,0.83,1.84,1.21,2.81h0.58
\t\t\t\t\tc-0.1-0.97-0.17-1.92-0.22-2.84s-0.07-1.83-0.07-2.74l-0.05-18.97H807.54z"/>
\t\t\t\t<path class="st0" d="M821.66,580.04v33.47h-7.37v-33.47H821.66z"/>
\t\t\t\t<path class="st0" d="M841.5,579.46c1.58,0,2.93,0.04,4.05,0.12c1.12,0.08,2.14,0.2,3.08,0.36c0.94,0.16,1.91,0.39,2.91,0.68
\t\t\t\t\tl-0.58,5.43c-1.07-0.06-2.05-0.11-2.94-0.15s-1.84-0.06-2.86-0.07c-1.02-0.02-2.24-0.02-3.66-0.02c-1.65,0-2.94,0.32-3.86,0.97
\t\t\t\t\tc-0.92,0.65-1.58,1.77-1.96,3.37c-0.39,1.6-0.58,3.81-0.58,6.62c0,2.81,0.19,5.02,0.58,6.62c0.39,1.6,1.04,2.73,1.96,3.37
\t\t\t\t\tc0.92,0.65,2.21,0.97,3.86,0.97c2.23,0,4.11-0.03,5.63-0.1c1.52-0.06,3.02-0.18,4.51-0.34l0.63,5.43
\t\t\t\t\tc-1.55,0.49-3.14,0.83-4.75,1.04c-1.62,0.21-3.62,0.32-6.02,0.32c-3.46,0-6.23-0.56-8.32-1.67c-2.09-1.12-3.59-2.93-4.51-5.46
\t\t\t\t\tc-0.92-2.52-1.38-5.92-1.38-10.19c0-4.27,0.46-7.67,1.38-10.19s2.43-4.34,4.51-5.46C835.27,580.01,838.04,579.46,841.5,579.46z"
\t\t\t\t\t/>
\t\t\t\t<path class="st0" d="M881.96,580.04v5.82h-27.55v-5.82H881.96z M871.87,580.04v33.47h-7.42v-33.47H871.87z"/>
\t\t\t\t<path class="st0" d="M913.45,580.04l-9.12,31.44c-0.16,0.58-0.48,1.07-0.95,1.46c-0.47,0.39-1.03,0.58-1.67,0.58h-6.79
\t\t\t\t\tc-0.68,0-1.25-0.19-1.72-0.58c-0.47-0.39-0.77-0.87-0.9-1.46l-9.17-31.44h7.67l6.11,24.16c0.13,0.61,0.27,1.24,0.41,1.87
\t\t\t\t\tc0.15,0.63,0.27,1.25,0.36,1.87h1.26c0.1-0.61,0.22-1.24,0.36-1.89c0.15-0.65,0.3-1.28,0.46-1.89l6.06-24.11H913.45z"/>
\t\t\t\t<path class="st0" d="M926.54,567.96l3.11,4.85l-10.82,5.38l-2.13-3.06L926.54,567.96z M924.6,580.04v33.47h-7.37v-33.47H924.6z"
\t\t\t\t\t/>
\t\t\t</g>
\t\t</g>
\t</g>
</switch>
</svg>

`]

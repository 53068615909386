import http from './../http';
import AuthStore from './../../stores/Auth';
import { showNotification } from '../notification'
import React from 'react'
import { NOTIFICATION_TYPE } from '../../constans/notification'
import Translation from '../../components/translation'
import { deleteLocalStorageValue, getLocalStorageValue } from '../localStorage'

export const getHeaders = (token = null, addition = null, config = null) => {

  if (!token) {
    token = AuthStore.token;
  }


  let headers = {
    'authorization': 'Bearer ' + token,
    // "Access-Control-Allow-Credentials": "true"
  }

  if (addition) {
    for(let i in addition) {
      headers[i] = addition[i];
    }
  }

  let request = {
    headers: headers
  }

  if (config) {
    for(let i in config) {
      request[i] = config[i];
    }
  }

  return request
}

// export function getAuthHeaderToken() {
//     return 'Bearer '+getLocalStorageValue('token');
// }

export async function apiRequest(url, type, data = null, settings = null) {

    let req = {
      status: null,
      data: null,
    };

    try {
        // url = '/api/v1/' + url;
        switch (type) {
            case 'get':
                req = await http.get(url, settings);
                break;
            case 'put':
                req = await http.put(url, data, settings);
            break;
            case 'post':
                req = await http.post(url, data, settings);
                break;
            case 'delete':
                req = await http.delete(url, settings);
                break;
            default:
              showNotification(NOTIFICATION_TYPE.warning, <Translation>Bohužel se něco nepodařilo. Prosím opakujte akci později znovu.</Translation>)
              break;
        }

      return {
        status: req.status,
        data: req.data.data? req.data.data : req.data,
        messages: req.data.message
      };

    } catch (e) {
        console.log("Error requests", e.response)
        return {
          status: e.response.status,
          data: (e.response.data)? e.response.data.data : {},
          messages: (e.response.data)? e.response.data.message : {}
        };
    }
}


export function redirectByLocalStorage() {
  const redirect_value = getLocalStorageValue('redirect');
  if (redirect_value) {
    if (redirect_value.indexOf('login') === -1) {
      // history.push(getLocalStorageValue('redirect'))
    }
    deleteLocalStorageValue('redirect');
    return true;
  }
  return false;
}

import React, {Component} from "react";
import {
  CButton,
  CDropdown,
  CDropdownDivider,
  CDropdownMenu,
  CDropdownToggle,
  CInput,
  CLabel,
  CTooltip
} from "@coreui/react";
import DocumentLabel from "../DocumentLabel";
import Translation from "../translation";
import {renderCompanyLabels} from "../DocumentLabels";
import {CirclePicker} from "react-color";
import ButtonDefault from "../Button";
import {observer} from "mobx-react";
import CIcon from "@coreui/icons-react";
import './style.scss';

class DocumentLabelsInlineSelect extends Component{

  render() {

    if (this.props.select_label) {
      return <div className='d-inline-flex mb-2 document-label align-items-center'>
        <DocumentLabel color={this.props.select_label.color} name={this.props.select_label.name} />
        <div>
          {(!this.props.disabled) ?

            <CTooltip
              content={<Translation>Odebrat štítek</Translation>}
              placement={"top"}
            >
              <CButton
                key={'2'}
                size={"sm"}
                color={'danger'}
                variant={'ghost'}
                className={"mr-2"}
                onClick={this.props.handleSetLabel.bind(this, this.props.select_label)}
              >
                <CIcon name="cil-trash"/>
              </CButton>
            </CTooltip>
          : null}
        </div>
      </div>
    }

    if (this.props.disabled) {
      return null
    }

    return <>
      <CDropdown>
        <CDropdownToggle color="secondary">
          {(this.props.select_label)?
            <DocumentLabel color={this.props.select_label.color} name={this.props.select_label.name} />
            :
            <Translation>Vyberte štítek</Translation>
          }
        </CDropdownToggle>
        <CDropdownMenu>
          {renderCompanyLabels(this.props.handleSetLabel, this.props.active_labels)}
          <CDropdownDivider />
          <div onClick={this.props.handleCreateNewLabel}>
            {(!this.props.create_new_label)?
              <div className="text-center cursor-pointer">
                <Translation>Vytvořit nový štítek</Translation>
              </div>
              :
              <div className="px-3">
                <div className="mb-3">
                  <CLabel htmlFor="label_name"><strong><Translation>Název nového štítku</Translation></strong></CLabel>
                  <CInput
                    type="text"
                    id="label_name"
                    placeholder="Název štítku"
                    value={this.props.label_name_new}
                    onChange={this.props.handleChangeLabelName}
                  />
                </div>
                <div className="mb-3">
                  <CLabel htmlFor="label_name"><strong><Translation>Barva štítku</Translation></strong></CLabel>
                  <CirclePicker onChange={this.props.handleChangeColor} />
                </div>
                {(this.props.label_name_new && this.props.label_color_new)?
                  <>
                    <div className="mb-4">
                      <CLabel htmlFor="label_name"><strong><Translation>Ukázka štítku</Translation></strong></CLabel>
                      <div>
                        <DocumentLabel color={this.props.label_color_new} name={this.props.label_name_new} />
                      </div>
                    </div>
                    <div className="text-center">
                      <ButtonDefault
                        CButtonSettings={{
                          key:'1',
                          color:'success',
                          variant:'',
                          onClick: this.props.handleStoreNewLabel
                        }}
                      >
                        <Translation>Uložit nový štítek</Translation>
                      </ButtonDefault>
                    </div>
                  </>
                  : null}
              </div>
            }
          </div>
        </CDropdownMenu>
      </CDropdown>
    </>
  }
}

export default observer(DocumentLabelsInlineSelect)

import React from 'react'
import {
  // CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import Translation from '../components/translation'
import AuthStore from '../stores/Auth'
import UserStores from '../stores/User'
import { VscAccount } from "react-icons/vsc";
import {observer} from "mobx-react";

function handleLogout(props) {
  AuthStore.logout();
}

const TheHeaderDropdown = observer((props) => {
  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <VscAccount size={40} />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {/*<CDropdownItem*/}
        {/*  header*/}
        {/*  tag="div"*/}
        {/*  color="light"*/}
        {/*  className="text-center"*/}
        {/*>*/}
        {/*  <strong>Account</strong>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-bell" className="mfe-2" />*/}
        {/*  Updates*/}
        {/*  <CBadge color="info" className="mfs-auto">42</CBadge>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-envelope-open" className="mfe-2" />*/}
        {/*  Messages*/}
        {/*  <CBadge color="success" className="mfs-auto">42</CBadge>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-task" className="mfe-2" />*/}
        {/*  Tasks*/}
        {/*  <CBadge color="danger" className="mfs-auto">42</CBadge>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-comment-square" className="mfe-2" />*/}
        {/*  Comments*/}
        {/*  <CBadge color="warning" className="mfs-auto">42</CBadge>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem*/}
        {/*  header*/}
        {/*  tag="div"*/}
        {/*  color="light"*/}
        {/*  className="text-center"*/}
        {/*>*/}
        {/*  <strong>Settings</strong>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-user" className="mfe-2" />Profile*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-settings" className="mfe-2" />*/}
        {/*  Settings*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-credit-card" className="mfe-2" />*/}
        {/*  Payments*/}
        {/*  <CBadge color="secondary" className="mfs-auto">42</CBadge>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem>*/}
        {/*  <CIcon name="cil-file" className="mfe-2" />*/}
        {/*  Projects*/}
        {/*  <CBadge color="primary" className="mfs-auto">42</CBadge>*/}
        {/*</CDropdownItem>*/}
        {/*<CDropdownItem divider />*/}
        <CDropdownItem>
          Verze 20240427
        </CDropdownItem>
        <CDropdownItem>
          <div>
            <div>
              <Translation>Přihlášený uživatel</Translation>
            </div>
            <div>
              <strong>{UserStores.actualUser.fullName? UserStores.actualUser.fullName : UserStores.actualUser.email}</strong>
            </div>
          </div>
        </CDropdownItem>
        <CDropdownItem onClick={handleLogout.bind(this, props)}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          <Translation>Odhlásit</Translation>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
})

export default TheHeaderDropdown

import React from "react";

const AdminInvoices = () => {

  return <div>
    Faktury
  </div>
}

export default AdminInvoices

export function setLocalStorageValue(name, value) {
  window.localStorage.setItem(name,value);
}


export function getLocalStorageValue(name) {
  const value = window.localStorage.getItem(name);
  if (value) {
    return value;
  }
  return null;
}

export function deleteLocalStorageValue(name) {
  window.localStorage.removeItem(name);
}

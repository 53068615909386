import { makeAutoObservable, action } from 'mobx'
import { apiRequest, getHeaders } from '../../helpers/request'
import { REQ_TYPE } from '../../constans/request'
import { showBulkNotification } from '../../helpers/notification'
import { NOTIFICATION_TYPE } from '../../constans/notification'
import AgendaStore from "../Agenda";

class CompanyStore {
  isLoading = false;
  gettingEmailList = false;
  emailList = [];
  detail = null;
  companies = [];
  companyUsers = [];
  loadingUsers = false;
  loadingCreating = false;
  loadingDocuments = false;
  loadingApprovals = false;
  loadingCompany = false;
  loadingUpdating = false;
  creatingLabel = false;
  loadingLabel = false;
  removingLabel = false;
  updatingLabel = false;
  loadingAres = false;
  actualDocumentPage = 0;
  total_documents = 0;
  documents_pages = 0;
  company_documents = [];
  org_company_documents = [];
  companyApprovals = [];
  histories = [];
  actualHistoryPage = 1;
  total_histories = 0;
  history_pages = 0;
  labels = [];
  search = [];


  constructor() {
    makeAutoObservable(this, {
      setCompanies: action,
      getCompanyUsers: action,
      setLoadingUsers: action,
      resetCompanyUsers: action,
      setCompanyUsers: action,
      setLoadingCreating: action,
      getDocuments: action,
      setCompany_documents: action,
      setLoadingApprovals: action,
      getCompanyApprovals: action,
      getCompany: action,
      setLoadingCompany: action,
      setActualDocumentPage: action,
      update: action,
      setLoadingUpdating: action,
      setCompany_history: action,
      setActualHistoryPage: action,
      setCompanyDetail: action,
      set: action,
      removeLabel: action,
      updateLabel: action,
    })
  }

  setLoadingUpdating(value) {
    this.loadingUpdating = value;
  }

  setCompanyApprovals(value) {
    this.companyApprovals = value;
  }

  set(name, value,) {
    this[name] = value;
  }

  setCompany_documents(value) {
    this.total_documents = value.total;
    this.documents_pages = value.pages;
    this.company_documents = value.documents;
    this.org_company_documents = value.documents;
  }

  setCompany_history(value) {
    this.histories = value.histories;
    this.total_histories = value.total;
    this.history_pages = value.pages;
  }

  setLoadingApprovals(value) {
    this.loadingApprovals = value;
  }

  setLoadingCreating(value) {
    this.loadingCreating = value;
  }

  setLoadingUsers(value) {
    this.loadingUsers = value;
  }

  setCompanies(value) {
    this.companies = value;
  }

  setCompanyUsers(value) {
    this.companyUsers = value;
  }

  setLoadingCompany(value) {
    this.loadingCompany = value;
  }

  setCompanyDetail(value) {
    this.detail = value;
    this.labels = value.labels || [];
  }

  async getCompanyUpdate(id) {

    let res = await apiRequest("/company/"+id, REQ_TYPE.get, null, getHeaders());

    if (res.data) {
      this.setCompanyDetail(res.data);
    }

  }

  async getCompany(id) {
    if (!this.loadingCompany) {
      this.setLoadingCompany(true)
      let res = await apiRequest("/company/"+id, REQ_TYPE.get, null, getHeaders());

      if (res.data) {
        this.setCompanyDetail(res.data);
        AgendaStore.getAll()
      }
      this.setLoadingCompany(false)
    }
  }

  setActualDocumentPage(value) {
    this.actualDocumentPage = value;
  }

  setActualHistoryPage(value) {
    this.actualHistoryPage = value;
  }

  async createCompany(data) {
    this.set('loadingCreating', true)
    let res = await apiRequest("/company", REQ_TYPE.post, data, getHeaders());
    showBulkNotification(res.messages.content, (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger)
    this.set('loadingCreating', false)
    return (res.status === 200)
  }

  async findAresCompany(ic) {
    this.set('loadingAres', true);
    let res = await apiRequest("/company/ares/"+ic, REQ_TYPE.get, null, getHeaders());
    this.set('loadingAres', false);
    return res.data;
  }


  async getDocuments(id, query = '') {
    this.set('loadingDocuments', true);
    let filter_sort_query = ''

    if (query.length > 0) {
      filter_sort_query += "?"+query
    }

    let res = await apiRequest("/company/"+id+"/documents/"+this.actualDocumentPage + filter_sort_query, REQ_TYPE.get, null, getHeaders());
    if (res.data) {
      this.setCompany_documents(res.data)
    }
    this.set('loadingDocuments', false);
  }

  async getSortDocuments(id, filter = '', sort = '') {
    // this.set('loadingDocuments', true);
    if (sort !== '') {

      if (filter === '') {
        sort = "?"+sort;
      } else if (filter !== '') {
        sort = "&"+sort;
      }
    }

    let res = await apiRequest("/company/"+id+"/documents/"+this.actualDocumentPage + filter + sort, REQ_TYPE.get, null, getHeaders());
    if (res.data) {
      this.setCompany_documents(res.data)
    }
    // this.set('loadingDocuments', false);
  }

  async getSearchDocuments(id, filter = '', sort = '', returnValues = false) {
    // this.set('loadingDocuments', true);
    let res = await apiRequest("/company/"+id+"/documents/search/"+sort, REQ_TYPE.get, null, getHeaders());
    if (!returnValues) {
      this.search = res.data
    } else {
      return res.data
    }

    // this.set('loadingDocuments', false);
  }

  async getCompanyApprovals(id) {
    this.setLoadingApprovals(true);
    let res = await apiRequest("/approvals/getAll/"+id, REQ_TYPE.get, null, getHeaders());
    this.setCompanyApprovals(res.data);
    this.setLoadingApprovals(false);
  }


  async getCompanyUsers(id) {
    this.setLoadingUsers(true);
    let res = await apiRequest("/company/"+id+"/users", REQ_TYPE.get, null, getHeaders());
    this.setCompanyUsers(res.data);
    this.setLoadingUsers(false);
  }

  async getCompanyUserDetail(user_id, company_id) {
    let res = await apiRequest("/company/"+company_id+"/user/"+user_id, REQ_TYPE.get, null, getHeaders());
    if (res.data) {
      return res.data
    }
  }


  async removeCompanyUser(company_id, user_id) {
    this.setLoadingUsers(true);
    let res = await apiRequest("/company/"+company_id+"/user/"+user_id, REQ_TYPE.delete, null, getHeaders());
    this.setLoadingUsers(false);
    showBulkNotification(res.messages.content, (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger)
    return (res.status === 200);
  }

  resetCompanyUsers() {
    this.companyUsers = [];
  }

  async companyAddUser(data) {
    this.setLoadingCreating(true);
    let res = await apiRequest("/company/user/invite", REQ_TYPE.post, data, getHeaders());
    showBulkNotification(res.messages.content, (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger)
    this.setLoadingCreating(false);
    return (res.status === 200);
  }


  async companyUpdateUser(data, id, company_id) {
    this.setLoadingCreating(true);
    let res = await apiRequest("/company/"+company_id+"/user/"+id, REQ_TYPE.post, data, getHeaders());
    let message = res.messages.content;
    showBulkNotification(message, (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger)
    this.setLoadingCreating(false);
    return (res.status === 200);
  }

  async companyCreateUser(data) {
    this.setLoadingCreating(true);
    let res = await apiRequest("/company/user", REQ_TYPE.post, data, getHeaders());
    let message = res.messages.content;
    showBulkNotification(message, (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger)
    this.setLoadingCreating(false);
    return (res.status === 200);
  }

  async update(data) {
    this.setLoadingUpdating(true);
    let res = await apiRequest("/company/"+this.detail.id, REQ_TYPE.put, data, getHeaders());
    let message = res.messages.content;
    showBulkNotification(message, (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger)
    this.setLoadingUpdating(false);
    return (res.status === 200);
  }

  async getHistories() {
    this.setLoadingUpdating(true);
    let page = this.actualHistoryPage;
    if (page <= 0) {
      page = 1;
    }
    let res = await apiRequest("/company/"+this.detail.id+"/history/"+page, REQ_TYPE.get, {}, getHeaders());
    this.setCompany_history(res.data);
    this.setLoadingUpdating(false);
    return (res.status === 200);
  }

  async checkInvite(token) {
    await apiRequest("/company/user/invite/check/v2/"+token , REQ_TYPE.get, null, getHeaders());
  }

  async creatingNewLabel (data) {
    this.set('creatingLabel', true);
    const res = await apiRequest('/company/'+this.detail.id+'/label', REQ_TYPE.post, data, getHeaders() );
    let message = res.messages.content;
    showBulkNotification(message, (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger)
    this.set('creatingLabel', false);
    return res.status === 200
  }

  async getAllLabels () {
    this.set('loadingLabel', true);
    const res = await apiRequest('/company/'+this.detail.id+'/labels', REQ_TYPE.get, {}, getHeaders() );
    this.set('labels', res.data);
    this.set('loadingLabel', false);
  }

  async removeLabel(id) {
    this.set('removingLabel', true);
    const res = await apiRequest('/company/'+this.detail.id+'/label/'+id, REQ_TYPE.delete, {}, getHeaders() );
    let message = res.messages.content;
    showBulkNotification(message, (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger)
    this.set('removingLabel', false);
    return res.status === 200
  }

  async updateLabel(id, data) {
    this.set('updatingLabel', true);
    const res = await apiRequest('/company/'+this.detail.id+'/label/'+id, REQ_TYPE.put, data, getHeaders() );
    let message = res.messages.content;
    showBulkNotification(message, (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger)
    this.set('updatingLabel', false);
    return res.status === 200
  }

  async getEmailList(isArchive = false) {
    this.set('gettingEmailList', true);
    const res = await apiRequest('/company/'+this.detail.id+'/email/list?archive='+isArchive, REQ_TYPE.get, null, getHeaders() );
    this.set('gettingEmailList', false);
    res.data.push('Eduard');
    res.data.push('Web')
    this.set('emailList', res.data)
    return res.status === 200
  }
}

export default new CompanyStore()

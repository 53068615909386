import React, {Component} from 'react';
import {
  CCardBody, CTooltip, CNav, CNavItem,
  CNavLink
} from '@coreui/react-pro'
import Translation from '../../../components/translation'
import TableTitle from '../../../components/TableTitle'
import CompanyStore from '../../../stores/Company';
import DocumentStore from '../../../stores/Document';
import { observer } from 'mobx-react'
import UploadModal from "./UploadModal";
import { AiOutlineReload } from "react-icons/ai";
import { HiDocumentAdd } from "react-icons/hi";
import {MODULES} from "../../../constans/modules";
import {hasUserWriteRole} from "../../../helpers/roles";
import {toJS} from "mobx";
import DocumentLabel from "../../../components/DocumentLabel";

import {
  createFilterRequest,
  getDocumentFilter,
  saveDocumentFilter
} from "../../../helpers/form";
import CompanyStores from "../../../stores/Company";
import {companyHasModule} from "../../../helpers/modules";
import DocumentTable3 from "../../../components/DocumentTable3";
import {setLocalStorageValue} from "../../../helpers/localStorage";

class DocumentsInLine extends Component {

  constructor (props) {
    super(props)
    // UserStore.getDocuments();
    let filters = getDocumentFilter()


    this.state = {
      filters: filters,
      show_detail: [],
      showModal: false,
      load_sort: false,
      upload: [],
      filter_labels: [],
      approval: 'all',
      sort: {
        column: 'createdAt',
        asc: 'desc',
      },
      sortBy: 'createdAt',
      sortType: 'desc',
      page: 1,
    }

    CompanyStore.setCompany_documents({documents: [], total: 0, pages: 0})
  }

  async componentDidMount() {
    if (CompanyStore.detail && CompanyStore.detail.id) {
      let { page } = this.props.match.params;
      if (page) {
        CompanyStore.setActualDocumentPage(parseInt(page))
      }
      // this.props.history.push('/dokumenty/'+CompanyStore.actualDocumentPage)
      let query = createFilterRequest(this.state.filters,['period'])
      await CompanyStore.getDocuments(CompanyStore.detail.id, query)

    }
  }


  closeModal() {
    this.setState({
      showModal: false,
      upload: []
    })
  }

  handleDocumentDetail(id, returnLink = false, current_page = 1) {
    setLocalStorageValue('table_page', current_page)
    if (returnLink) {
      return '/dokumenty/detail/'+id;
    }
    DocumentStore.setVisit(id);
    this.props.history.push('/dokumenty/detail/'+id);

  }

  handleAddDocument() {
    // document.getElementById("button_take_photo").getElementsByTagName("input")[0].click();
    this.setState({
      showModal: true
    })
  }

  getFiles(documents) {
    this.setState({
      showModal: true,
      upload: documents
    })
  }

  handleDownload(id) {
    DocumentStore.download(id)
  }

  refreshList() {
    let query = createFilterRequest(this.state.filters,['period'])
    CompanyStore.getDocuments(CompanyStore.detail.id, query)
  }

  pageChange(number) {
      this.props.history.push('/dokumenty/'+number)
  }


  async handleChangeTab(type, e) {
    e.preventDefault()
    e.stopPropagation()
    let filters = this.state.filters;
    filters['tab'] = type;
    this.setState({
      filters: filters
    })
    saveDocumentFilter(filters)
    CompanyStore.setActualDocumentPage(1)
    let query = createFilterRequest(filters,['period'])
    await CompanyStore.getDocuments(CompanyStore.detail.id, query)
  }

  render() {
    let { page } = this.props.match.params;

    if (!CompanyStore.detail || !CompanyStore.detail.id) {
      return null
    }

    return <div key={toJS(CompanyStore.actualDocumentPage)+"_a"}>
      <div className="card">
        <div className="card-body">
          <TableTitle
            key={0}
            text={<React.Fragment>
              <Translation>Dokumenty</Translation>
                <CTooltip
                  content={<Translation>Obnovit seznam</Translation>}
                  placement={"top"}
                >
                  <AiOutlineReload size={'0.8em'} className="text-info cursor-pointer ml-1" onClick={this.refreshList.bind(this)}  />
                </CTooltip>

            </React.Fragment>}
            description={<div>
              <div>
                {/*<small><Translation>Aktuální stránka </Translation><strong>{page}</strong></small>*/}
                {/*<div className="btn btn-link" onClick={this.handleFirstPage.bind(this)}><small>zpět na první stránku</small></div>*/}
              </div>
            </div>}
            buttons={[
              {
                visibility: hasUserWriteRole(MODULES.documents),
                color: 'success',
                variant: '',
                tooltip: <Translation>Nahrát nový dokument</Translation>,
                action: this.handleAddDocument.bind(this),
                icon: <HiDocumentAdd className="mr-2" size={"1.1em"} />,
                text: <Translation>Nahrát dokument</Translation>,
              }
            ]}
          />
          <CCardBody className="px-0">

              <div className="w-100 mb-3">
                <CNav variant="tabs">
                  <CNavItem>
                    <CNavLink href="#" active={this.state.filters.tab === 'all'} onClick={(e)=> this.handleChangeTab('all', e)}>
                      <Translation>Vše</Translation>
                    </CNavLink>
                  </CNavItem>
                  {(CompanyStores.detail && CompanyStore.detail.hasApprovals && companyHasModule(MODULES.approvals))?
                    <CNavItem>
                      <CNavLink href="#" active={this.state.filters.tab === 'my'} onClick={(e)=> this.handleChangeTab('my', e)}>
                        <Translation>Ke schválení</Translation>
                      </CNavLink>
                    </CNavItem>
                  : null}
                  <CNavItem>
                    <CNavLink href="#" active={this.state.filters.tab === 'problem_documents'} onClick={(e)=> this.handleChangeTab('problem_documents', e)}>
                      <Translation>K řešení</Translation>
                    </CNavLink>
                  </CNavItem>
                </CNav>
              </div>


            <DocumentTable3
              //key={toJS(CompanyStore.company_documents).length + "_a"}
              changePagination={this.pageChange.bind(this)}
              name={'documents'}
              data={toJS(CompanyStore.company_documents)}
              orgData={toJS(CompanyStore.company_documents)}
              parent={this}
              loading={CompanyStore.loadingDocuments}
              history={this.props.history}
              refreshTable={this.refreshList.bind(this)}
              actionDetail={this.handleDocumentDetail}
            />

          </CCardBody>
        </div>
      </div>
      {(this.state.showModal)?
        <UploadModal close={this.closeModal.bind(this)} />
      : null }
    </div>
  }
}

export default observer(DocumentsInLine)

import { action, makeAutoObservable } from 'mobx'
import { apiRequest, getHeaders } from '../../helpers/request'
import { REQ_TYPE } from '../../constans/request';
import DocumentStore from "../Document";
import {showBulkNotification, showResponseNotificationMessage} from "../../helpers/notification";
import {NOTIFICATION_TYPE} from "../../constans/notification";

class ApprovalsStore {
  loading = false;
  detail = null;

  constructor() {
    makeAutoObservable(this, {
      get: action,
      setLoading: action
    })
  }

  setLoading(value) {
    this.loading = value;
  }

  async get(id) {
    this.setLoading(true);
    let res = await apiRequest("/approvals/rule/"+id, REQ_TYPE.get, null, getHeaders());
    if (res.data) {
      this.detail = res.data;
    }
    this.setLoading(false)
  }

  async create(data) {
    this.setLoading(true);
    let res = await apiRequest("/approvals/rule", REQ_TYPE.post, data, getHeaders());
    this.setLoading(false)
    showResponseNotificationMessage(res);
    return res.status === 200;
  }

  async edit(data) {
    this.setLoading(true);
    let res = await apiRequest("/approvals/rule/"+data.id, REQ_TYPE.put, data, getHeaders());
    this.setLoading(false)
    showResponseNotificationMessage(res);
    return res.status === 200;
  }

  async remove(id) {
    this.setLoading(true);
    let res = await apiRequest("/approvals/rule/"+id, REQ_TYPE.delete, null, getHeaders());
    this.setLoading(false)
    showResponseNotificationMessage(res);
    return res.status === 200;
  }

  async documentChangeApprovals(id, data) {
    const documentId = data.documentId || DocumentStore.detail.id;
    this.setLoading(true);
    let res = await apiRequest("/document/"+documentId+"/approvals/"+id, REQ_TYPE.put, data, getHeaders());
    this.setLoading(false)
    if (res.data && res.data.miss_require) {
      showBulkNotification(res.messages, NOTIFICATION_TYPE.warning);
      return false;
    }
    showResponseNotificationMessage(res);
    return res.status === 200;
  }

  async reset(documentId) {
    this.setLoading(true);
    let res = await apiRequest("/document/"+documentId+"/approvals/reset", REQ_TYPE.put, {}, getHeaders());
    this.setLoading(false)
    showResponseNotificationMessage(res);
    return res.status === 200;
  }

  async changeStrategy(documentId, newApprovalsId, isUserApproval = false) {
    this.setLoading(true);
    let res = await apiRequest("/document/"+documentId+"/approvals/change", REQ_TYPE.post, {approvalsRuleId: newApprovalsId, isUserApproval: isUserApproval}, getHeaders());
    this.setLoading(false)
    showResponseNotificationMessage(res);
    return res.status === 200;
  }

}

export default new ApprovalsStore()

// import CIcon from '@coreui/icons-react'
import React from 'react';
import { ImFilesEmpty } from 'react-icons/im'
import { FiUsers, FiTrash2, FiSettings } from 'react-icons/fi'
import { VscTasklist } from 'react-icons/vsc'
import { AiOutlineHistory, AiOutlineBank } from 'react-icons/ai';
import {MODULES} from "../constans/modules";

const _nav =  [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dokumenty',
    to: '/dokumenty',
    module: MODULES.documents,
    icon: <ImFilesEmpty size={'1.5em'} className="mr-4" />,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Uživatelé',
    module: MODULES.users,
    to: '/uzivatele',
    icon: <FiUsers size={'1.5em'} className="mr-4" />,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Pravidla schvalování',
    to: '/schvalovani',
    module: MODULES.approvals,
    icon: <VscTasklist size={'1.5em'} className="mr-4" />,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Avíza',
    to: '/aviza',
    module: MODULES.bank_notice,
    icon: <AiOutlineBank size={'1.5em'} className="mr-4" />,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Archiv dokumentů',
    module: MODULES.archive,
    to: '/archiv',
    icon: <AiOutlineHistory size={'1.5em'} className="mr-4" />,
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Nastavení',
    module: MODULES.documents,
    // to: '/nastaveni',
    icon: <FiSettings size={'1.5em'} className="mr-4" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Společnost',
        module: MODULES.documents,
        to: '/nastaveni',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Agenda',
        module: MODULES.documents,
        to: '/nastaveni/agenda',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Dokumenty',
        module: MODULES.documents,
        to: '/nastaveni/dokumenty',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Štítky',
        module: MODULES.documents,
        to: '/nastaveni/stitky',
      },
    ]
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Koš',
    module: MODULES.documents,
    to: '/kos',
    icon: <FiTrash2 size={'1.5em'} className="mr-4" />,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Log',
    module: MODULES.documents,
    to: '/log',
    icon: <AiOutlineHistory size={'1.5em'} className="mr-4" />,
  }
]

export default _nav

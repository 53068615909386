import React, {Component} from 'react';
import PaginationDefault from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {observer} from "mobx-react";
import './style.scss';

class Pagination extends Component {
  render() {

    if (this.props.pages <= 1) {
      return null;
    }

    return <div>
      <PaginationDefault
        className="pagination justify-content-end"
        count={this.props.pages}
        page={this.props.activePage}
        variant="outlined"
        shape="rounded"
        renderItem={(item) => <PaginationItem className="pagination-item" {...item} /> }
        onChange={this.props.pageChange}
      />
      {/*<CPagination*/}
      {/*  align="end"*/}
      {/*  limit={25}*/}
      {/*  activePage={this.props.activePage}*/}
      {/*  pages={this.props.pages}*/}
      {/*  key={this.props.activePage+"_"+this.props.pages}*/}
      {/*  onActivePageChange={this.props.pageChange}*/}
      {/*>*/}
      {/*</CPagination>*/}
    </div>
  }
}

export default observer(Pagination)

import React, {useEffect, useState, useRef} from 'react';
import MaterialReactTable, {
  MRT_ShowHideColumnsButton, // import the built-in show/hide columns button
  MRT_FullScreenToggleButton, // import the built-in full screen toggle button
  MRT_ToggleFiltersButton
} from 'material-react-table';
import { MRT_Localization_CS } from 'material-react-table/locales/cs';
import CompanyStores from "../../stores/Company";
import {toJS} from "mobx";
import {MoneyFormat} from "../../helpers/base";
import {getAgendaFromCode} from "../../helpers/company";
import {DateTime} from "luxon";
import {GLOBAL_DATE_FORMAT, GLOBAL_DATE_TIME_FORMAT} from "../../constans/base";
import {CBadge, CButton, CTooltip} from "@coreui/react";
import Translation from "../translation";
import {Link} from "react-router-dom";
import DocumentStore from "../../stores/Document";
import CIcon from "@coreui/icons-react";
import {hasUserWriteRole} from "../../helpers/roles";
import {MODULES} from "../../constans/modules";
import {createFilterRequest, getDocumentFilter} from "../../helpers/form";
import CompanyStore from "../../stores/Company";
import {companyHasModule} from "../../helpers/modules";
import UserStore from "../../stores/User";
import {AiOutlineCheck, AiOutlineHistory} from "react-icons/ai";
import {BiCommentAdd} from "react-icons/bi";
import {RiArrowGoBackLine} from "react-icons/ri";
import {checkApprovalDocumentFields, getIsActualUserApproval} from "../DocumentApproval";
import ApprovalsStore from "../../stores/Approvals";
import _ from "lodash";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


import './style.scss';
import ReactToPrint from "react-to-print";
import {getVisitClass} from "../../helpers/documentTable/indes";
import ApprovalsModals from "../DocumentApproval/Modals";
import DocumentLabel from "../DocumentLabel";
import {observer} from "mobx-react";

const DEFAULT_DOCUMENT_TABLE = {
  columnsSize: {
    filename: null,
    total_amount: null,
    agenda: null,
    company: null,
    incoming_date: null,
    pay_date: null,
    tax_supply: null,
    create_date: null,
    approval: null,
  },
  columnVisibility: {
    document_name: true,
    filename: true,
    total_amount: true,
    agenda: true,
    company: true,
    incoming_date: true,
    pay_date: true,
    tax_supply: true,
    create_date: true,
    approval: true,
  },
  columnsOrder: [
    'action',
    'filename',
    'total_amount',
    'internal_number',
    'agenda',
    'approval',
    'company',
    'incoming_date',
    'pay_date',
    'tax_supply',
    'create_date',
    'mrt-select-row'
  ]
}


function get_email_from(item) {
  let text = item.email_from;
  if (item.document_data && item.document_data.company_name) {
    text = item.document_data.company_name + ', ' + text;
  } else if (!item.email_from && item.enter_from) {
    if (item.enter_from === 'upload') {
      text = 'Přidán přes web'
    } else {
      text = 'Přidán přes Eduarda'
    }
  }

  return text
}

async function  handleRemoveDocument(item) {
  confirmAlert({
    title: 'Odstranění dokumentu',
    message: "Opravdu si přejete odstranit dokument?",
    buttons: [
      {
        label: 'ANO',
        onClick: async () => {
          let filters = getDocumentFilter()
          let query = createFilterRequest(filters)
          if (item.removed) {
            if (await DocumentStore.removePermanent(item)) {
              CompanyStore.getDocuments(CompanyStore.detail.id, query)
            }

          } else {
            if (await DocumentStore.remove(item)) {
              CompanyStore.getDocuments(CompanyStore.detail.id, query)
            }
          }
        }
      },
      {
        label: 'NE',
        onClick: () => {}
      }
    ]
  });
}

function handleDownload(id) {
  DocumentStore.download(id)
}

function get_approve_data(item) {
  if (CompanyStores.detail && CompanyStore.detail.hasApprovals) {

    let approvals = {
      done: 0,
      labelText: '',
      text: null,
      label: null,
      userId: null,
      date: null,
      total: 0
    }

    if (item.document_approval){
      approvals.total = item.document_approval.length
    }

    if (item.document_approval && item.document_approval.length > 0) {
      const sortApprovals = item.document_approval.sort((a, b) => a.order - b.order )
      for(let i in sortApprovals) {
        const approval_user = item.document_approval[i];

        if (approval_user.status === 'approve') {
          approvals.done++;
        }

        if (!approvals.text) {
          if (approval_user.status === 'reject') {
            if (approval_user.order === 0 ) {
              approvals.label = <CBadge color={'danger'}><Translation>Odmítnuto a čeká</Translation></CBadge>
              approvals.labelText = 'Odmítnuto a čeká'
              approvals.text = (approval_user.user.email)
              approvals.userId = approval_user.user.id
            } else {
              if (sortApprovals.length <= (parseInt(i) - 1) && sortApprovals[parseInt(i) - 1]) {
                approvals.label = <CBadge color={'danger'}><Translation>Odmítnuto a čeká</Translation></CBadge>
                approvals.labelText = 'Odmítnuto a čeká'
                approvals.text = sortApprovals[parseInt(i) - 1].user.email
                approvals.userId = sortApprovals[parseInt(i) +1].user.id
              }
            }
          } else if (approval_user.status === 'wait') {

            if (sortApprovals.length <= (parseInt(i) +1) && sortApprovals[parseInt(i) +1]) {
              if (sortApprovals[parseInt(i) +1].status === 'reject') {
                approvals.label = <CBadge color={'danger'}><Translation>Odmítnuto a čeká</Translation></CBadge>
                approvals.labelText = 'Odmítnuto a čeká'
                approvals.text = approval_user.user.email
                approvals.userId = approval_user.user.id
              }
            } else {

              if (sortApprovals.length <= (parseInt(i) +1) && sortApprovals[parseInt(i) +1]) {
                if (sortApprovals[parseInt(i) +1].status === 'reject') {
                  approvals.label = <CBadge color={'danger'}><Translation>Odmítnuto a čeká</Translation></CBadge>
                  approvals.labelText = 'Odmítnuto a čeká'
                  approvals.text = sortApprovals[parseInt(i) +1].user.email
                  approvals.userId = sortApprovals[parseInt(i) +1].user.id
                }
              } else {
                approvals.label =<CBadge color={'info'}><Translation>čeká</Translation></CBadge>
                approvals.labelText = 'čeká'
                approvals.text = approval_user.user.email
                approvals.userId = approval_user.user.id
              }
            }

          }
        }
      }
    }

    if (item.approved) {
      const sortApprovals = item.document_approval.sort((a, b) => a.order - b.order )
      const approvedDate = DateTime.fromJSDate(new Date(sortApprovals[sortApprovals.length-1].approve_date)).toFormat(GLOBAL_DATE_TIME_FORMAT)
      approvals.label = <><CBadge color={'success'}><Translation>schváleno</Translation></CBadge> <div><small>{approvedDate}</small></div></>
      approvals.labelText = 'schváleno'
    }

    return approvals
  } else {
    return <>
      -
    </>
  }
}

const DocumentTable3 = observer((props) => {
  const tableInstanceRef2 = useRef(null);
  const thismyref = useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  let onPage = urlParams.get('onPage');
  let { page } = props.parent.props.match.params;
  if (page) {
   page = page -1;
  } else {
    page = 0
  }

  if (!onPage) {
    onPage = 25;
  }
  const [pagination, setPagination] = useState({
    pageIndex: parseInt(page),
    pageSize: parseInt(onPage),
  });
  const [settings, setSettings] = useState({
    key: 0,
    modal: {
      rejectModal: false,
      commentModal: false,
      text: '',
      modalId: null
    },
    tableSettings: DEFAULT_DOCUMENT_TABLE
  })

  const [data, setData] = useState(_.cloneDeep(toJS(props.data)));
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    let hg = props.data;
    if (searchText.length > 0) {
      hg = props.orgData.filter((item) => {
        let bbbd = columns_array.filter((column) => {

          if (column.accessorFn && column.accessorFn(item).toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
            return true
          }
        })
        return bbbd.length > 0
      })
    }
    setData(_.cloneDeep(hg))
  }, [props.data])

  useEffect(() => {
    async function b () {
      await UserStore.getUserData();
      let ten = toJS(UserStore.actualUser.tableSettings);
      if (ten && ten[CompanyStore.detail.id] && ten[CompanyStore.detail.id]['documents']) {
        let pomSettings = _.cloneDeep(settings)
        pomSettings.tableSettings = ten[CompanyStore.detail.id]['documents'];
        setSettings(pomSettings)
      }
      CompanyStore.getEmailList()
    }

    b()
  }, [])

  const handleArchiveDocument = (item) => {
    confirmAlert({
      title: 'Archivování dokumentu',
      message: "Opravdu si přejete archivovat dokument?",
      buttons: [
        {
          label: 'ANO',
          onClick: async () => {
            let filters = getDocumentFilter()
            let query = createFilterRequest(filters)
            if (await DocumentStore.archive(item.id)) {
              CompanyStore.getDocuments(CompanyStore.detail.id, query)
            }
          }
        },
        {
          label: 'NE',
          onClick: () => {}
        }
      ]
    });
  }


  let columns_array = [
    {
      enableSorting: false,
      enableColumnFilterModes: false,
      enableHiding: false,
      enableColumnOrdering: false,
      enableGlobalFilter: false,
      enableColumnFilter: false,
      enableColumnActions: false,
      accessorKey: 'action',
      header: 'Akce',
      size: ((hasUserWriteRole(MODULES.documents) && props.name !== 'archive' && companyHasModule(MODULES.archive))? 200 : 180),
      key: 'action',
      Cell: ({ cell, row }) => {
        const item = row.original;

        return <div className={getVisitClass(item)}>
          <CTooltip
            content={<Translation>Prohlédnout dokument</Translation>}
            placement={"top"}
          >
            <Link to={props.actionDetail(item.id, true, pagination.pageIndex+1)} className="text-dark text-primary">
              <CButton
                key={'0'}
                color={'info'}
                variant={'ghost'}
                disabled={DocumentStore.removing}
              >
                <CIcon name="cil-magnifying-glass"/>
              </CButton>
            </Link>
          </CTooltip>
          {(item.filename !== 'E-mail bez dokumentu')?
            <CTooltip
              content={<Translation>Stáhnout dokument</Translation>}
              placement={"top"}
            >
              <CButton
                key={'1'}
                color={'success'}
                variant={'ghost'}
                className={"ml-2"}
                disabled={DocumentStore.removing}
                onClick={() => handleDownload(item.id)}
              >
                {/*<a href={process.env.REACT_APP_API_URL+"/document/" + CompanyStore.detail.id + "/download/" + item.id} download={true}  target={"_blank"}>*/}
                <CIcon name="cil-cloud-download"/>
                {/*</a>*/}
              </CButton>
            </CTooltip>
            : null}

          {(hasUserWriteRole(MODULES.documents) && !item.archive)?
            <CTooltip
              content={<Translation>Smazat dokument</Translation>}
              placement={"top"}
            >
              <CButton
                key={'2'}
                color={'danger'}
                variant={'ghost'}
                className={"ml-2"}
                disabled={DocumentStore.removing}
                onClick={() => handleRemoveDocument(item)}
              >
                <CIcon name="cil-trash"/>
              </CButton>
            </CTooltip>
            : null}

          {(hasUserWriteRole(MODULES.documents) && !item.archive && companyHasModule(MODULES.cloud_archive))?
            <CTooltip
              content={<Translation>Archivovat dokument</Translation>}
              placement={"top"}
            >
              <CButton
                key={'3'}
                color={'warning'}
                variant={'ghost'}
                className={"ml-2"}
                disabled={DocumentStore.archiveLoading}
                onClick={() => handleArchiveDocument(item)}
              >
                <AiOutlineHistory size={'1.5em'} />
              </CButton>
            </CTooltip>
            : null}
        </div>
      }
    },
    {
      id: 'filename',
      header: 'Název dokladu',
      key: 'filename',
      size: settings.tableSettings.columnsSize.filename,
      Cell: ({ cell, row }) => {
        const item = row.original;

        const labels = [];
        if (item.document_data && item.document_data.hold_payment && item.document_data.hold_payment === 'on') {
          labels.push(<DocumentLabel color={'#e55353'} name={"Pozdržená platba"} key={'0_0'} />)
        }

        if (item.document_data && item.document_data.payed) {
          labels.push(<DocumentLabel color={'#0f9405'} name={"Zaplaceno"} key={'0_0'} />)
        }

        if (item.duplicates && item.duplicates.length > 0) {
          labels.push(<DocumentLabel color={'#e55353'} name={"Doklad je duplikovaný"} key={'0_0'} />)
        }

        if (item.labels && item.labels.length > 0) {

          for(let i in item.labels) {
            const label = item.labels[i];
            labels.push(<DocumentLabel color={label.color} name={label.name} key={i} />)
          }
        }

        return <div className={getVisitClass(item)}>
          <div>
            {(labels.length > 0)?
                <div>
                  <div>
                    {labels}
                  </div>
                </div>
            : null}
            <Link to={props.actionDetail(item.id, true, pagination.pageIndex+1)} className="text-dark text-primary">
              <strong>
                {row.original.document_name}
              </strong>
              {row.original.accountingText!=null? <><br />{row.original.accountingText}</> : null }
            </Link>
          </div>
        </div>
      },
      accessorFn: (item) => {
        const labels = [];

        if (item.document_data && item.document_data.hold_payment && item.document_data.hold_payment === 'on') {
          labels.push("Pozdržená platba")
        }

        if (item.document_data && item.document_data.payed) {
          labels.push("Zaplaceno")
        }

        if (item.duplicates && item.duplicates.length > 0) {
          labels.push("Duplicitní")
        }

        if (item.labels && item.labels.length > 0) {
          for (let i in item.labels) {
            const label = item.labels[i];
            labels.push(label.name)
          }
        } else {
          labels.push('!štítky')
        }


        return item.filename + ', '+item.document_name + ", " + labels.join(', ')
      }
    },
    {
      id: 'total_amount',
      accessorFn: (item) => {
        if (item.document_data && item.document_data.total_amount) {
          return MoneyFormat(item.document_data.total_amount, item.document_data.currency? item.document_data.currency : 'Kč' )
        }

        return '-'
      },
      header: 'Částka',
      minSize: 150,
      size: settings.tableSettings.columnsSize.total_amount,
      key: 'total_amount',
      Cell: ({ cell, row }) => {
        let price = null;
        let item = row.original
        if (item.document_data && item.document_data.total_amount) {
          price = MoneyFormat(item.document_data.total_amount, item.document_data.currency? item.document_data.currency : 'Kč' )
        }
        return <div className={getVisitClass(item) + ' pl-2'}>
          {price ?? '-'}
        </div>
      }
    },
    {
      id: 'agenda',
      header: 'Agenda',
      size: settings.tableSettings.columnsSize.agenda,
      // size: 30,
      minSize: 120,
      accessorFn: (item) => {
        if (item.agenda_code) {
          return getAgendaFromCode(item.agenda_code)
        }
        return '-'
      },
      key: 'agenda',
      Cell: ({cell, row}) => {
        let item = row.original
        return <div className={getVisitClass(item)}>
          {getAgendaFromCode(item.agenda_code)}
        </div>
      }
    },
    {
      id: 'company',
      header: 'Dodavatel',
      size: settings.tableSettings.columnsSize.company,
      // size: 30,
      accessorFn: (item) => {
        return get_email_from(item)
      },
      key: 'company',
      Cell: ({cell, row}) => {
        let item = row.original
        return <div className={getVisitClass(item) + ' pl-2'}>{get_email_from(item)}</div>
      },
      filterFn: 'fuzzy',
      filterVariant: 'select',
      filterSelectOptions: toJS(CompanyStore.emailList).map((item) => ({
        text: item,
        value: item,
      })),
    },
    {
      id: 'incoming_date',
      header: 'Datum přijeti',
      size: settings.tableSettings.columnsSize.incoming_date,
      accessorFn: (item) => {
        if (item.createdAt) {
          return DateTime.fromJSDate(new Date(item.createdAt)).toFormat(GLOBAL_DATE_TIME_FORMAT)
        }
        return '-'
      },
      key: 'incoming_date',
      Cell: ({cell, row}) => {
        let item = row.original
        return <div className={getVisitClass(item)}>
          {DateTime.fromJSDate(new Date(item.createdAt)).toFormat(GLOBAL_DATE_TIME_FORMAT)}
        </div>
      }
    },
    {
      id: 'pay_date',
      header: 'Datum splatnosti',
      size: settings.tableSettings.columnsSize.pay_date,
      accessorFn: (item) => {
        if (item.document_data && item.document_data.mining_data && item.document_data.mining_data.final_dates && item.document_data.mining_data.final_dates.pay_date) {
          return DateTime.fromFormat(item.document_data.mining_data.final_dates.pay_date, 'd/M/y').toFormat(GLOBAL_DATE_FORMAT);
        }
        return '-'
      },
      key: 'pay_date',
      Cell: ({cell, row}) => {
        let item = row.original
        let value = '-';
        // if (item.pay_date) {
        //   value = DateTime.fromJSDate(new Date(item.pay_date)).toFormat(GLOBAL_DATE_FORMAT);
        // }
        if (item.document_data && item.document_data.mining_data && item.document_data.mining_data.final_dates && item.document_data.mining_data.final_dates.pay_date) {
          value = DateTime.fromFormat(item.document_data.mining_data.final_dates.pay_date, 'd/M/y').toFormat(GLOBAL_DATE_FORMAT);
        }
        return <div className={getVisitClass(item)}>
          {value}
        </div>
      }
    },
    {
      id: 'tax_supply',
      header: 'Datum plněni',
      size: settings.tableSettings.columnsSize.tax_supply,
      accessorFn: (item) => {
        if (item.document_data && item.document_data.mining_data && item.document_data.mining_data.final_dates && item.document_data.mining_data.final_dates.tax_supply) {
          return DateTime.fromFormat(item.document_data.mining_data.final_dates.tax_supply, 'd/M/y').toFormat(GLOBAL_DATE_FORMAT);
        }
        return '-'
      },
      key: 'tax_supply',
      Cell: ({cell, row}) => {
        let item = row.original
        let value = '-';
        if (item.document_data && item.document_data.mining_data && item.document_data.mining_data.final_dates && item.document_data.mining_data.final_dates.tax_supply) {
          value = DateTime.fromFormat(item.document_data.mining_data.final_dates.tax_supply, 'd/M/y').toFormat(GLOBAL_DATE_FORMAT);
        }
        return <div className={getVisitClass(item)}>{value}</div>
      }
    },

    {
      id: 'create_date',
      header: 'Datum vystavení',
      size: settings.tableSettings.columnsSize.create_date,
      accessorFn: (item) => {
        if (item.document_data && item.document_data.mining_data && item.document_data.mining_data.final_dates && item.document_data.mining_data.final_dates.create) {
          return DateTime.fromFormat(item.document_data.mining_data.final_dates.create, 'd/M/y').toFormat(GLOBAL_DATE_FORMAT);
        }
        return '-'
      },
      key: 'create_date',
      Cell: ({cell, row}) => {
        let item = row.original
        let value = '-';
        if (item.document_data && item.document_data.mining_data && item.document_data.mining_data.final_dates && item.document_data.mining_data.final_dates.create) {
          value = DateTime.fromFormat(item.document_data.mining_data.final_dates.create, 'd/M/y').toFormat(GLOBAL_DATE_FORMAT);
        }
        return <div className={getVisitClass(item)}>{value}</div>
      }
    },

  ]



  const storeTableSettings = async (settingsData) => {
    let tableData = UserStore.actualUser.tableSettings ?? {};
    if (!tableData[CompanyStore.detail.id] || (tableData[CompanyStore.detail.id] && !tableData[CompanyStore.detail.id][props.name])) {
      tableData[CompanyStore.detail.id] = {};
    }

    tableData[CompanyStore.detail.id][props.name] = {...settingsData, companyId: toJS(CompanyStore.detail.id)}
    await UserStore.setTableSetting(tableData);
  }

  const showRejectModal = (item) => {
    let modal = settings;
    modal['modal']['rejectModal'] = true;
    modal['modal']['documentId'] = item.id;

    setSettings({...modal})
  }

  const showCommentModal = (item) => {
    let modal = settings;
    modal['modal']['commentModal'] = true;
    DocumentStore.set('detail', item)

    setSettings({...modal})
  }

  const changeModalValue = (e) => {
    let modal = settings;

    modal['modal']["text"] = e.target.value;

    setSettings({...modal})
  }

  const closeModal = () => {
    let oldState = settings;
    oldState['modal'] = {
      rejectModal: false,
      commentModal: false,
      text: '',
      modalId: null
    }
    setSettings({...oldState})
  }

  const handleApprove = async (item) => {
    const result = getIsActualUserApproval(item);
    if (checkApprovalDocumentFields(item) && result.row) {
      if (await ApprovalsStore.documentChangeApprovals( result.row.id, { status: 'approve', documentId: item.id })) {
        await props.refreshTable();
      }
    }
  }

  const handleRejectModal = async (id, documentId) => {
    confirmAlert({
      title: 'Odmítnutí dokumentu',
      message: "Opravdu si přejete doklad odmítnout?",
      buttons: [
        {
          label: 'ANO',
          onClick: async () => {
            if (await ApprovalsStore.documentChangeApprovals(id, { status: 'reject', text: settings.modal.text, documentId: documentId  })) {
              await props.refreshTable();
              closeModal();
            }
          }
        },
        {
          label: 'NE',
          onClick: () => {}
        }
      ]
    });
  }

  const handleCommentModal = async () => {
    const result = getIsActualUserApproval(DocumentStore.detail);
    if (result.row && await ApprovalsStore.documentChangeApprovals(result.row.id, { status: 'comment', text: settings.modal.text  })) {
      await props.refreshTable();
      closeModal();
    }
  }

  if (CompanyStore.detail && CompanyStore.detail.hasApprovals && companyHasModule(MODULES.approvals)) {
    columns_array.splice(2, 0, {
      id: 'approval',
      header: 'Schvalování',
      size: settings.tableSettings.columnsSize.approval,
      columnFilterModeOptions: ['fuzzy', 'contains', 'startsWith'],
      accessorFn: (item) => {
        let approvals = get_approve_data(item);
        if (item.document_data && item.document_data.mining_data && item.document_data.mining_data.final_dates && item.document_data.mining_data.final_dates.create) {
          return approvals.text + " - " +DateTime.fromFormat(item.document_data.mining_data.final_dates.create, 'd/M/y').toFormat(GLOBAL_DATE_FORMAT);
        }

        if (approvals.text) {
          let text = approvals.text
          if (approvals.labelText) {
            text = text + ', ' + approvals.labelText
          }
          return (text).toLowerCase()
        } else if (approvals.labelText) {
          return approvals.labelText
        }
        return '-'
      },
      key: 'approval',
      Cell: ({cell, row}) => {
        let item = row.original
        let approvals = get_approve_data(item);
        return <div className={getVisitClass(item)}>
          <div>

            {(approvals.userId !== UserStore.actualUser.id)?
              <>
                {approvals.label}
                <div>
                  {/*<CPopover*/}
                  {/*  trigger={'hover'}*/}
                  {/*  title="Status"*/}
                  {/*  content={approvals.label}*/}
                  {/*  placement="top"*/}
                  {/*>*/}
                  {approvals.text}
                  {/*</CPopover>*/}
                </div>
              </>
            : null}

            {(approvals.userId === UserStore.actualUser.id)?
              <div className="d-inline-flex">

                <div>
                  <CTooltip
                    content={<Translation>Schválit</Translation>}
                    placement={"top"}
                  >
                    <CButton
                      key={'0'}
                      color={'success'}

                      onClick={() => handleApprove(item)}
                    >
                      <AiOutlineCheck size={12}/>
                    </CButton>
                  </CTooltip>
                </div>

                <div className="ml-1">

                  <CTooltip
                    content={<Translation>Přidat komentář</Translation>}
                    placement={"top"}
                  >
                    <CButton
                      key={'0'}
                      color={'info'}

                      onClick={() => showCommentModal(item)}
                    >
                      <BiCommentAdd size={12}/>
                    </CButton>
                  </CTooltip>
                </div>

                <div className="ml-1">
                  <CTooltip
                    content={<Translation>Zamítnout</Translation>}
                    placement={"top"}
                  >
                    <CButton
                      key={'0'}
                      color={'danger'}

                      onClick={() => showRejectModal(item)}
                    >
                      <RiArrowGoBackLine size={12}/>
                    </CButton>
                  </CTooltip>
                </div>

              </div>
              : null}
          </div>

        </div>
      }
    });
  }

  if (props.name === 'archive') {
    columns_array.splice(2, 0, {
      accessorKey: 'internal_number',
      header: 'Číslo zaúčtování',
      size: settings.tableSettings.columnsSize.incoming_date,
      visible: false,
      accessorFn: (item) => {
        if (item.internal_number) {
          return item.internal_number
        }
        return '-'
      },
      key: 'internal_number',
      Cell: ({cell, row}) => {
        let item = row.original
        return <div className={getVisitClass(item)}>
          {item.internal_number}
        </div>
      }
    }, );
  }

  return <div ref={thismyref}>
    {/*style={{display: 'none'}}*/}
    <input type="text" style={{border: "1px solid #d8dbe0", 'height': "55px"}} className={"form-control w-25"} placeholder={"Vyhledat v tabulce"}  onChange={(e) => {
      let hg = props.orgData;
      if (e.target.value.length > 0) {
        hg = props.orgData.filter((item) => {
          let bbbd = columns_array.filter((column) => {

            if (column.accessorFn && column.accessorFn(item).toLowerCase().indexOf(e.target.value.toLowerCase()) > -1) {
              return true
            }
          })
          return bbbd.length > 0
        })
      }
      setData(_.cloneDeep(hg))
      setSearchText(e.target.value)

    }}/>
    <MaterialReactTable
    key={"-"+toJS(UserStore.loadingUser) + "-" + data.length + "-" + CompanyStore.loadingDocuments.toString()}
    enableGlobalFilterRankedResults={true}
    enableDensityToggle={false}
    columns={columns_array}
    enableColumnResizing={true}
    columnResizeMode={'onChange'}
    // enableGlobalFilterModes={true}
    onColumnSizingChange={async (info) => {
      let orgStat = settings;
      let sizeChanges = info();
      const allKeys = _.keys(sizeChanges);
      for(let i in allKeys) {
        const key = allKeys[i];
        orgStat.tableSettings.columnsSize[key] = sizeChanges[key];
      }
      setSettings(_.cloneDeep(orgStat))
      await storeTableSettings(_.cloneDeep(orgStat.tableSettings));
    }}
    positionGlobalFilter="left"
    enableColumnOrdering={true}
    enableColumnDragging={false}
    // globalFilterModeOptions={['equals']}
    defaultColumn={{
      minSize: 170,
    }}
    onColumnVisibilityChange={async (visibility) => {
      let orgStat = settings;
      let sizeChanges = visibility();
      const allKeys = _.keys(sizeChanges);
      for(let i in allKeys) {
        const key = allKeys[i];
        orgStat.tableSettings.columnVisibility[key] = sizeChanges[key];
      }
      setSettings(_.cloneDeep(orgStat))
      await storeTableSettings(_.cloneDeep(orgStat.tableSettings));
    }}
    onColumnOrderChange={async (changeOrder) => {
      let orgStat = settings;
      orgStat.tableSettings.columnsOrder = changeOrder;
      setSettings(_.cloneDeep(orgStat))
      await storeTableSettings(_.cloneDeep(orgStat.tableSettings));
    }}
    initialState={{
      // showGlobalFilter: true,
      columnOrder: settings.tableSettings.columnsOrder,
      columnVisibility: settings.tableSettings.columnVisibility,
      pagination: pagination,
    }}
    localization={MRT_Localization_CS}
    data={toJS(data)}
    muiSearchTextFieldProps={{
      placeholder: 'Vyhledat v tabulce',
      sx: { minWidth: '18rem' },
      variant: 'outlined',
    }}
    state={{
      // showGlobalFilter: true,
      isLoading: CompanyStore.loadingDocuments,
      pagination: pagination,
      columnVisibility: settings.tableSettings.columnVisibility,
      columnOrder: settings.tableSettings.columnsOrder,
    }}
    // onPaginationChange={setPagination}
    tableInstanceRef={tableInstanceRef2}
    muiTablePaginationProps={{
      rowsPerPageOptions: [5, 25, 50],
    }}
    // onFilterFnsChange={(key, time) => {
    //
    // }}
    manualPagination={false}
    onPaginationChange={(item) => {
      let newPagination = item(pagination);
      let url = 'dokumenty';
      if (props.name === 'archive') {
        url = 'archiv'
      }
      window.history.replaceState("", "",`/${url}/${newPagination.pageIndex+1}?onPage=${newPagination.pageSize}`);
      setPagination(newPagination)
    }}
    renderToolbarInternalActions={({ table }) => (

        <div className={"d-inline-flex"}>
          <ReactToPrint
            trigger={() => <div className={'btn btn-sm-info table-export d-print-none'}><CIcon name="cil-print"/></div>}
            onBeforePrint={() => {}}
            onBeforeGetContent={async () => {

              await setPagination({
                pageIndex: 0,
                pageSize: 100000,
              })

            }}
            onPrintError={() => {
              setPagination({
                pageIndex: 0,
                pageSize: onPage,
              })
            }}
            onAfterPrint={() => {
              setPagination({
                pageIndex: 0,
                pageSize: onPage,
              })
            }}
            content={() => thismyref.current}
          />
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_FullScreenToggleButton table={table} />
        </div>

    )}
  />

    <ApprovalsModals
      closeModal={() => closeModal()}
      states={settings.modal}
      approvalUserId={UserStore.actualUser.id}
      changeValue={(e) => changeModalValue(e)}
      handleRejectModal={(e, b) => handleRejectModal(e,b)}
      handleCommentModal={() => handleCommentModal()}
      handleApprove={() => handleApprove()}
    />
  </div>;
});

export default DocumentTable3;

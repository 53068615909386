import React, {Component} from "react";
import {observer} from "mobx-react";
import CompanyStore from "../../../stores/Company";
import DocumentsTable from "../../../components/DocumentTable";
import {toJS} from "mobx";
import Translation from "../../../components/translation";
import {CCardBody} from "@coreui/react";
import TableTitle from "../../../components/TableTitle";
import DocumentStore from "../../../stores/Document";
import TableFilter from "../../../components/TableFilter";
import {
  createFilterRequest,
  getDocumentFilter,
  isActiveFilter,
  resetDocumentFilter,
  saveDocumentFilter
} from "../../../helpers/form";
import DocumentTable3 from "../../../components/DocumentTable3";

class Archive extends Component {
  constructor (props) {
    super(props);
    let filters = getDocumentFilter()
    this.state = {
      period: null,
      filters: {...filters, archive: true},
      filter_labels: [],
      approval: 'all',
    }
  }

  async componentDidMount() {
    await CompanyStore.getDocuments(CompanyStore.detail.id, 'archive=true')
  }

  pageChange(event, number) {
    this.props.history.push('/dokumenty/'+number)
  }

  handleDocumentDetail(id, returnLink = false) {
    if (returnLink) {
      return '/dokumenty/detail/'+id;
    }
    DocumentStore.setVisit(id);
    this.props.history.push('/dokumenty/detail/'+id);
  }

  refreshList() {
    let query = createFilterRequest(this.state.filters,['period'])
    CompanyStore.getDocuments(CompanyStore.detail.id, query)
  }

  handleSearch(a) {
    if (a.target.value) {
      CompanyStore.getSearchDocuments(CompanyStore.detail.id,'', a.target.value+"?archive=true")
    }
  }


  handleChangeFilter(e) {
    let filters = this.state.filters;
    filters[e.target.name] = e.target.value;

    if (e.target.name === 'asc') {
      if (e.target.value !== 'null') {
        filters['column'] = 'filename';
      } else {
        filters['column'] = null;
      }
    }

    this.setState({
      filters: filters
    })
    let query = createFilterRequest(filters,['tab'])
    saveDocumentFilter(filters);

    query += "&archive=true"

    CompanyStore.getDocuments(CompanyStore.detail.id, query)

  }

  handleResetFilters() {
    resetDocumentFilter()
    let filters = getDocumentFilter()
    this.setState({
      filters: filters
    })

    let query = createFilterRequest(filters,['tab'])
    query += "&archive=true"
    CompanyStore.getDocuments(CompanyStore.detail.id, query)
  }

  render() {
    let is_active_filter = isActiveFilter(this.state.filters,['tab', 'period=all'])
    return <>
      <div key={toJS(CompanyStore.actualDocumentPage)+"_a"}>
        <div className="card">
          {/*<header className="card-header">*/}
          {/*  Headings*/}
          {/*</header>*/}
          <div className="card-body">
            <TableTitle
              key={0}
              text={<React.Fragment>
                <Translation>Archiv dokumentů</Translation>
              </React.Fragment>}
            />


            <CCardBody className="px-0">
              <DocumentTable3
                key={toJS(CompanyStore.company_documents).length + "_a"}
                changePagination={this.pageChange.bind(this)}
                name={'archive'}
                data={toJS(CompanyStore.company_documents)}
                orgData={toJS(CompanyStore.company_documents)}
                parent={this}
                loading={CompanyStore.loadingDocuments}
                history={this.props.history}
                refreshTable={this.refreshList.bind(this)}
                actionDetail={this.handleDocumentDetail}
              />
            </CCardBody>
          </div>
        </div>
      </div>
    </>
  }
}
export default observer(Archive);

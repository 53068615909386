import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import { Editor } from "react-draft-wysiwyg";
import {convertFromHTML, convertFromRaw, EditorState , ContentState} from "draft-js";
import {stateToHTML} from 'draft-js-export-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './style.scss'

const TextEditor = (props) => {
  const [editorState, setEditorState] = useState(() => {
    EditorState.createEmpty()

    if (props.value) {
      return EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(props.value)))
    } else {

    }
  });

  const handleChange = (e) => {
    props.action(stateToHTML(e.getCurrentContent()))
    setEditorState(e);
  }

  if (!props.isEdit) {
    return <div className={"p-4"}>
      <div dangerouslySetInnerHTML={{ __html: props.value }}></div>
    </div>
  }

  return (
    <div>
        <Editor
          initialContentState={props.value}
          value={props.value}
          editorState={editorState}
          onEditorStateChange={handleChange}
        />
    </div>
  );
}

export default observer(TextEditor)

import React, { Component, Suspense } from 'react';
import {
  CButton,
  CContainer, CFade, CModal, CModalBody
} from '@coreui/react';
import {TheHeader, TheSidebar} from "../../containers";
import LoadingPage from "../../components/LoadingPage";
import {observer} from "mobx-react";
import PDF2 from "../../components/PDF";
import DocumentStore from "../../stores/Document";
import Translation from "../../components/translation";

class PrivateLayout extends Component {


  renderViewDocument() {
    let html = null;
    if (DocumentStore.detail.filename.toLowerCase().indexOf('.pdf') > -1 ) {
      html = <PDF2 document={DocumentStore.detail} type={DocumentStore.detail.type} />
    } else if (DocumentStore.detail.filename.toLowerCase().indexOf('.jpg') > -1 ||
      DocumentStore.detail.filename.toLowerCase().indexOf('.jpeg') > -1 ||
      DocumentStore.detail.filename.toLowerCase().indexOf('.png') > -1 ){
      html = <div className="w-100 text-center">
        <img src={(DocumentStore.detail.document.url)? DocumentStore.detail.document.url :`DocumentStore.detail:image/jpeg;base64,${DocumentStore.detail.document}`} alt={DocumentStore.detail.filename} className="imaged img-fluid"/>
      </div>
    } else {
      html = <div className="w-100 text-center">Formát není podporovaný</div>
    }

    return html;
  }

  render() {
    return <div className="c-app c-default-layout" key={this.props.parent_key}>
      <TheSidebar/>
      <div className="c-wrapper">
        <TheHeader/>
        <div className="c-body">
          <main className="c-main">
            <CContainer fluid>
              <Suspense fallback={LoadingPage}>
                <CFade>
                  {this.props.children}
                </CFade>
              </Suspense>
            </CContainer>
          </main>
        </div>
      </div>
      {(DocumentStore.showModal)?

        <CModal
          className="show d-block w-100"
          style={{zIndex: 99999}}
          visible={true}
          size={'xl'}
          onClose={() => {
            DocumentStore.showModal = false;
          }}
        >
          {/*<CModalHeader>*/}
          {/*  <CModalTitle>Modal title</CModalTitle>*/}
          {/*</CModalHeader>*/}
          <CModalBody>
            <div>
              <CButton color="danger" onClick={() => {
                DocumentStore.showModal = false;
              }}>
                <Translation>Zavřít</Translation>
              </CButton>
            </div>
            {this.renderViewDocument()}
          </CModalBody>
          {/*<CModalFooter>*/}
          {/*  <CButton color="secondary" onClick={() => {*/}
          {/*      */}
          {/*  }}>*/}
          {/*    Close*/}
          {/*  </CButton>*/}
          {/*</CModalFooter>*/}
        </CModal>
      : null}
    </div>
  }
}

export default observer(PrivateLayout);

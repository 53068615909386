import React, { Component } from 'react';
import { observer } from 'mobx-react'
import './style.scss';

class NoTableItems extends Component {
  render() {
    return <div className="w-100 h-100 justify-content-center align-content-center d-flex min-empty-table">
      <h4>{this.props.text}</h4>
    </div>
  }
}

export default observer(NoTableItems)

export const MODULES = {
  documents: 'documents',
  users: 'users',
  base_info: 'base_info',
  approvals: 'approvals',
  document_history: 'document_history',
  archive: 'archive',
  bank_notice: 'bank_notice',
  cloud_archive: 'cloud_archive'
}

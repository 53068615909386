import React, { Component } from 'react';
import { observer } from 'mobx-react'
import { CSpinner } from '@coreui/react';

class LoadingPage extends Component {
  render() {
    return <div className="w-100 h-100 d-flex justify-content-center align-items-center position-absolute">
      <CSpinner color="info" variant="grow" />
    </div>
  }
}

export default observer(LoadingPage)

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CTooltip,
  CHeaderNav
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import CompanyStore from './../stores/Company'
// routes config
// import routes from '../routes'

import {
  TheHeaderDropdown,
  // TheHeaderDropdownMssg,
  // TheHeaderDropdownNotif,
  // TheHeaderDropdownTasks
}  from './index'

import { GiCardExchange } from 'react-icons/gi'

import Translation from "../components/translation";
import {deleteLocalStorageValue, getLocalStorageValue} from "../helpers/localStorage";

function handleChangeCompany() {
  deleteLocalStorageValue('companyId');
  deleteLocalStorageValue('filter');
  deleteLocalStorageValue('document_page');
  deleteLocalStorageValue('redirect');
  window.location = '/login'
}

const TheHeader = (props) => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector(state => state.sidebarShow)

  // const toggleSidebar = () => {
  //   const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
  //   dispatch({type: 'set', sidebarShow: val})
  // }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    dispatch({type: 'set', sidebarShow: val})
  }

  const companyId = getLocalStorageValue('companyId')

  return (
    <CHeader withSubheader>
      {(companyId)?
        <CToggler
          inHeader
          className="ml-md-3 d-lg-none"
          onClick={toggleSidebarMobile}
        />
      : null }
      {/*<CToggler*/}
      {/*  inHeader*/}
      {/*  className="ml-3 d-md-down-none"*/}
      {/*  onClick={toggleSidebar}*/}
      {/*/>*/}
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        {/*<CIcon name="logo" height="48" alt="Logo"/>*/}
          <CIcon
            className="c-sidebar-brand-full"
            name="logo-negative"
            height={60}
            width={140}
          />
      </CHeaderBrand>
      <CHeaderNav className=" mr-auto">
        {(companyId && CompanyStore.detail)?
          <div className="d-inline-flex align-items-center">
            <div className="ml-3">
              <CTooltip
                content={<Translation>Přejít do jiné společnosti</Translation>}
                placement={"top"}
              >
                <GiCardExchange size={'1.5em'} onClick={handleChangeCompany.bind(this)} className="cursor-pointer" />
              </CTooltip>
            </div>
            <div className="px-3">
              <div>
                <h6 className="p-0 m-0">{CompanyStore.detail.name}</h6>
              </div>
              <div>
                <small>
                  <Translation>IČ</Translation>: {CompanyStore.detail.ic}
                </small>
              </div>
            </div>
          </div>
        : <CIcon
            className="c-sidebar-brand-full"
            name="logo-negative"
            height={60}
            width={140}
          /> }
        {/*<CHeaderNavItem className="px-3" >*/}
        {/*  <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>*/}
        {/*</CHeaderNavItem>*/}
        {/*<CHeaderNavItem  className="px-3">*/}
        {/*  <CHeaderNavLink to="/users">Users</CHeaderNavLink>*/}
        {/*</CHeaderNavItem>*/}
        {/*<CHeaderNavItem className="px-3">*/}
        {/*  <CHeaderNavLink>Settings</CHeaderNavLink>*/}
        {/*</CHeaderNavItem>*/}
      </CHeaderNav>

      <CHeaderNav className="px-3">
        {/*<TheHeaderDropdownNotif/>*/}
        {/*<TheHeaderDropdownTasks/>*/}
        {/*<TheHeaderDropdownMssg/>*/}
        <TheHeaderDropdown/>
      </CHeaderNav>

      {/*<CSubheader className="px-3 justify-content-between">*/}
      {/*  <CBreadcrumbRouter*/}
      {/*    className="border-0 c-subheader-nav m-0 px-0 px-md-3"*/}
      {/*    routes={routes}*/}
      {/*  />*/}
      {/*    <div className="d-md-down-none mfe-2 c-subheader-nav">*/}
      {/*      <CLink className="c-subheader-nav-link"href="#">*/}
      {/*        <CIcon name="cil-speech" alt="Settings" />*/}
      {/*      </CLink>*/}
      {/*      <CLink*/}
      {/*        className="c-subheader-nav-link"*/}
      {/*        aria-current="page"*/}
      {/*        to="/dashboard"*/}
      {/*      >*/}
      {/*        <CIcon name="cil-graph" alt="Dashboard" />&nbsp;Dashboard*/}
      {/*      </CLink>*/}
      {/*      <CLink className="c-subheader-nav-link" href="#">*/}
      {/*        <CIcon name="cil-settings" alt="Settings" />&nbsp;Settings*/}
      {/*      </CLink>*/}
      {/*    </div>*/}
      {/*</CSubheader>*/}
    </CHeader>
  )
}

export default TheHeader

// import CIcon from '@coreui/icons-react'
import React from 'react';
import { ImFilesEmpty } from 'react-icons/im'
import { FiUsers} from 'react-icons/fi'
import { VscTasklist } from 'react-icons/vsc'

const _nav =  [
  {
    _tag: 'CSidebarNavItem',
    name: 'Hlavní stránka',
    to: '/admin',
    module: null,
    icon: <ImFilesEmpty size={'1.5em'} className="mr-4" />,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Zákazníci',
    module: null,
    to: '/admin/zakaznici',
    icon: <FiUsers size={'1.5em'} className="mr-4" />,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Společnosti',
    module: null,
    to: '/admin/spolecnosti',
    icon: <FiUsers size={'1.5em'} className="mr-4" />,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Faktury',
    to: '/admin/faktury',
    module: null,
    icon: <VscTasklist size={'1.5em'} className="mr-4" />,
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Archív dokumentů',
  //   module: null,
  //   to: '/archiv',
  //   icon: <AiOutlineHistory size={'1.5em'} className="mr-4" />,
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Nastavení',
  //   module: null,
  //   // to: '/nastaveni',
  //   icon: <FiSettings size={'1.5em'} className="mr-4" />,
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Společnost',
  //       module: null,
  //       to: '/nastaveni',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Agenda',
  //       module: null,
  //       to: '/nastaveni/agenda',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Dokumenty',
  //       module: null,
  //       to: '/nastaveni/dokumenty',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Štítky',
  //       module: null,
  //       to: '/nastaveni/stitky',
  //     },
  //   ]
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Koš',
  //   module: null,
  //   to: '/kos',
  //   icon: <FiTrash2 size={'1.5em'} className="mr-4" />,
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Log',
  //   module: null,
  //   to: '/log',
  //   icon: <AiOutlineHistory size={'1.5em'} className="mr-4" />,
  // }
]

export default _nav

import React, {Component} from 'react';
import {Redirect, Route, withRouter} from 'react-router-dom';
import AuthStore from './../../stores/Auth';
import { deleteLocalStorageValue, getLocalStorageValue } from '../../helpers/localStorage'
import LoadingPage from '../LoadingPage'


class PublicRoute extends Component {
  checkAuthorization(Component, props) {
    if (AuthStore.verifying) {
      return (<LoadingPage/>);
    } else {
      if (!AuthStore.isLogin()) {
        return (
          <Component {...props} />
        );
      } else {
        let redirect_value = getLocalStorageValue('redirect')
        if (redirect_value) {
          deleteLocalStorageValue('redirect');
        } else {
          redirect_value = '/dokumenty'
        }
        return (
          <Redirect
            to={redirect_value}
          />
        );
      }
    }
  }

  render() {
    const {component: Component, ...rest} = this.props;
    return (
      <Route
        {...rest}
        render={(props) => this.checkAuthorization(Component, props)}
      />
    );
  }
}

export default withRouter(PublicRoute)

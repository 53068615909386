import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI:
// https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

/**
 * TODO - toto řešení je pouze rozhraní. Při použití více portálů budeme konfiguraci nějakým způsobem získávat.
 */
const resources = {
  cz: {
    translation: {
      "Společnosti": "Společnosti",
      "Opravdu si přejete odebrat uživatele ze společnosti?": 'Opravdu si přejete odebrat uživatele {{fullName}} ze společnosti?'

    }
  },
  fr: {
    translation: {
      "Společnosti": "Bienvenue à React et react-i18next"
    }
  }

};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "cz",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;

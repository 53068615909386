export const DEFAULT_AGENDAS = [
  {
    default: false,
    active: true,
    code: "invoice-in",
    title: "Přijaté faktury",
    category: "default"
  },
  {
    default: false,
    active: true,
    code: "invoice-out",
    title: "Vydané faktury",
    category: "default"
  },
  {
    default: false,
    active: true,
    code: "receipt-card",
    title: "Účtenka kartou",
    category: "default"
  },
  {
    default: false,
    active: true,
    code: 'receipt-cash',
    title: "Účtenka hotově",
    category: "default"
  },
  {
    default: false,
    active: true,
    code: 'other',
    title: "Ostatní",
    category: "default"
  },
]

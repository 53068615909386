import { action, makeAutoObservable } from 'mobx'
import { codeBase64 } from '../../helpers/base'
import { apiRequest, getHeaders } from '../../helpers/request'
import { REQ_TYPE } from '../../constans/request'
import {deleteLocalStorageValue, getLocalStorageValue, setLocalStorageValue} from '../../helpers/localStorage'
import {showBulkNotification, showNotification} from '../../helpers/notification'
import { NOTIFICATION_TYPE } from '../../constans/notification'
import UserStores from './../User';
import Translation from "../../components/translation";
import React from "react";

class AuthStore {
  isLoading = false;
  userLogin = false;
  verifying = false;
  wasLogout = false;
  token = null;

  constructor() {
    makeAutoObservable(this, {
      isLogin: action,
      setLogin: action,
      setToken: action,
      logout: action,
      checkLoginToken: action,
      setVerifying: action,
      setLoading: action,
      setWasLogout: action,
      removeCompanyId: action
    })
  }

  setWasLogout(value) {
    this.wasLogout = value;
  }

  logout() {
    this.setLogin(false);
    this.removeToken();
    this.setWasLogout(true)
    this.removeCompanyId()
    // window.location.href = '/login'
  }

  setLoading(value) {
    this.isLoading = value;
  }

  setVerifying(value) {
    this.verifying = value;
  }

  setLogin (value) {
    this.userLogin = value
  }

  isLogin() {
    return this.userLogin;
  }

  setToken(token) {
    setLocalStorageValue("token", token);
    this.token = token
  }

  removeToken() {
    deleteLocalStorageValue("token");
    this.token = null
  }

  removeCompanyId() {
    deleteLocalStorageValue("companyId");
  }

  async login(email) {

    this.setLoading(true);
    let res = await apiRequest("/auth/login", REQ_TYPE.post, {
      email: codeBase64(email)
    },
    getHeaders(process.env.REACT_APP_LOGIN_TOKEN)
    );
    this.setLoading(false);

    if (res.status !== 200) {
      showBulkNotification(res.messages, NOTIFICATION_TYPE.danger)
    } else {
      showNotification(NOTIFICATION_TYPE.success, <Translation>{res.messages.content[0]}</Translation>)
    }

    return res.status === 200;

  }

  async checkLoginCode(code, email) {

      this.setLoading(true);

      let res = await apiRequest("/auth/code/check", REQ_TYPE.post, {
          code: code,
          email: codeBase64(email)
        },
        getHeaders(process.env.REACT_APP_LOGIN_TOKEN)
      );

    if (res.status === 200) {
      this.setToken(res.data.jwt);
      this.setLogin(true)
      await UserStores.getCompanies()
      this.setLoading(false);
      showBulkNotification(res.messages.content, NOTIFICATION_TYPE.success)
      return true;
    } else {
      if (res.messages) {
        showBulkNotification(res.messages.content, NOTIFICATION_TYPE.danger)
      }
    }
    this.setLoading(false);
    return false;

  }

  async checkLoginToken() {
    this.setLoading(true);
    this.setVerifying(true);
    let res = await apiRequest("/auth/check", REQ_TYPE.get, null, getHeaders());

    if (res.status === 200) {
      this.setLogin(true);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      if (urlParams.get('company')) {
        setLocalStorageValue('companyId', urlParams.get('company'));
      }

      if (window.location.href.indexOf('/login') > -1 && getLocalStorageValue('companyId')) {
        // history.push('/dokumenty');
      }
      // redirectByLocalStorage();
    } else {
      deleteLocalStorageValue('token');
      deleteLocalStorageValue('companyId');
      if (window.location.href.indexOf('/login') === -1) {
        // setLocalStorageValue('redirect' , window.location.href)
      }
    }

    this.setLoading(false);
    this.setVerifying(false);

  }

}

export default new AuthStore()

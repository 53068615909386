import React from 'react';
import { CAlert } from '@coreui/react';
import toast from 'react-hot-toast';
import "./style.scss";
import { NOTIFICATION_SETTINGS, NOTIFICATION_TYPE } from '../../constans/notification'
import Translation from '../../components/translation'

export function removeAllNotifications() {
  toast.remove()
}


export function showNotification(type = NOTIFICATION_TYPE.info, content, settings = {}) {
  settings = {...NOTIFICATION_SETTINGS, ...settings}

  return toast(
    (t) => (
      <CAlert color={type} className="m-0 cursor-pointer" onClick={() => toast.dismiss(t.id)} >
        {content}
      </CAlert>
    ), settings);
}


export function showBulkNotification(data, type = 'info') {
  for(let i in data) {
    const message = data[i];
    if (message) {
      showNotification(type, <Translation>{message}</Translation>)
    }
  }
}


export function showResponseNotificationMessage(res) {
  const type = (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger;
  showBulkNotification(res.messages, type);
}

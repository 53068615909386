import React, {Component} from "react";
import {observer} from "mobx-react";
import DocumentStore from "../../../stores/Document";
import TableTitle from "../../../components/TableTitle";
import Translation from "../../../components/translation";
import {CCol, CFormGroup, CRow} from "@coreui/react";
import TextEditor from "../../../helpers/textEditor";
import ButtonDefault from "../../../components/Button";

class ReportError extends Component {

  constructor (props) {
    super(props)

    this.state = {
      new_message: `
        <p>
          Dobrý den,</br>během zpracování dokumentu se vyskytl problém:
        </p>
        <small><i>*****Popis problému*****</i></small>
        <p>
            Prosím o prověření dokladu.
        </p>
      `
    }
  }

  handleChangeMessage(text) {
    this.setState({
      new_message: text
    })
  }

  async componentDidMount() {
    if (this.props.getDocument === undefined) {
      await DocumentStore.get(this.props.match.params.id, this.props)
    }
  }

  async handleStoreMessage() {
    if (await DocumentStore.reportBug(this.state.new_message)) {
      this.setState({
        new_message: ''
      })

      if (this.props.root !== undefined) {
        this.props.root.closeErrorModal(this.props.root)
      }
    }
  }

  render() {
    if (!DocumentStore.detail) {
      return null
    }
    return <div className="card">
      <div className="card-body">
        {(this.props.title === undefined || this.props.title === true)?

          <TableTitle
            text={DocumentStore.detail.filename}
            description={<Translation>Hlášení problému k dokumentu </Translation>}
          />
        :
          null
        }

        <div className={"mt-4"}>
          <CRow>
            <CCol lg={12} md={12} sm={12} xs={12} xl={12} xxl={12}>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="justify-content-between align-items-center d-inline-flex">
                  <h4>
                    <Translation>Popis problému</Translation>
                  </h4>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                  <TextEditor isEdit={true} value={this.state.new_message} action={this.handleChangeMessage.bind(this)} />
                  <div className="w-100 d-inline-flex justify-content-end mt-3">
                    <ButtonDefault
                      key={3}
                      loading={DocumentStore.reporting}
                      CButtonSettings={{
                        color: "success",
                        className: "",
                        onClick: this.handleStoreMessage.bind(this),
                      }}
                    >
                      <Translation>Odeslat</Translation>
                    </ButtonDefault>{' '}
                  </div>
                </CCol>
              </CFormGroup>
            </CCol>
          </CRow>

        </div>

      </div>
    </div>
  }
}

export default observer(ReportError)

import { Component } from 'react';
import i18n from './../../helpers/translation';

export default class Translation extends Component {
  render() {

    if (this.props.keys) {
      return i18n.t(this.props.children, this.props.keys);
    }
    return i18n.t(this.props.children);
  }
}

import React, {Component} from 'react';
import {observer} from "mobx-react";
import {CBadge} from "@coreui/react";
import Translation from "../translation";

class DocumentLabel extends Component{
  render() {
    return (
      <CBadge
        style={{ 'backgroundColor': this.props.color, 'color': '#ffffff'}}
        className="px-2 py-1 mr-2"
        shape="pill"
      >
        <Translation>
          {this.props.name}
        </Translation>
      </CBadge>
    )
  }
}

export default observer(DocumentLabel)

export const NOTIFICATION_TYPE = {
  success: 'success',
  warning: 'warning',
  info: 'info',
  danger: 'danger'
}

export const NOTIFICATION_SETTINGS = {
  duration: 5000,
  position: 'top-center',
  className: 'notification-area cursor-pointer'
}

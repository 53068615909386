import React, { Component } from 'react';
import { observer } from 'mobx-react'
import {CButton, CTooltip} from "@coreui/react";

class TableTitle extends Component{

  renderButtons() {
    const html = [];
    if (this.props.buttons) {
      for(let i in this.props.buttons) {
        const button = this.props.buttons[i];
        if (button.visibility === undefined || button.visibility) {
          if (button.tooltip) {
            html.push(<CTooltip
              content={button.tooltip}
              placement={"top"}
              key={i}
            >
              <CButton
                key={i}
                color={button.color}
                variant={button.variant}
                className="align-items-center d-inline-flex"
                onClick={button.action}
                >
                {button.icon}
                {button.text}
              </CButton>
            </CTooltip>)
          } else {

            html.push(<CButton
              key={i}
              color={button.color}
              variant={button.variant}
              className="align-items-center d-inline-flex"
              onClick={button.action}
            >
              {button.icon}
              {button.text}
            </CButton>)
          }
        }
      }
    }

    if (html.length > 0) {
      return <div className="button-area">
        {html}
      </div>
    }

    return html;
  }

  render() {
    const titleClass = this.props.titleClass || ''

    return (<div className={`d-inline-flex justify-content-between align-items-center w-100 ${this.props.className}`}>
        <div>
        <h4 className={`${titleClass}`}>{this.props.text}</h4>
          {(this.props.description)? this.props.description : null}
      </div>
        {this.renderButtons()}
    </div>
  )
  }
}

export default observer(TableTitle)

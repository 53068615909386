import React, { Component } from 'react';
import PageError from "../../views/pages/PageError/PageError";

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI

    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <PageError />
    }
    return <div>{this.props.children}</div>;
  }
}

export default ErrorHandler;

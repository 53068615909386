import CompanyStore from "../../stores/Company";
import {CDropdownItem} from "@coreui/react";
import DocumentLabel from "../DocumentLabel";
import Translation from "../translation";
import React from "react";

export function renderCompanyLabels(actionClickLabel, selected_label ) {
  const html = [];

  for(let i in CompanyStore.labels) {
    const label = CompanyStore.labels[i]
    if (selected_label) {
      if (selected_label.find(item =>  item.id === label.id)) {
        continue;
      }
    }
    html.push(
      <CDropdownItem key={i} href="#" onClick={actionClickLabel.bind(this, label)}>
        <DocumentLabel color={label.color} name={label.name} />
      </CDropdownItem>
    )
  }

  if (html.length === 0) {
    html.push(<div className="opacity-75 text-center">
      <Translation>Žádné štítky</Translation>
    </div>)
  }

  return html;
}

export function getLabelById(id) {
  return CompanyStore.labels.find(item => item.id === id);
  // for(let i in CompanyStore.labels) {
  //   const label = CompanyStore.labels[i]
  //   if (selected_label) {
  //     if (selected_label.find(item =>  item.id === label.id)) {
  //       continue;
  //     }
  //   }
  //   html.push(
  //     <CDropdownItem key={i} href="#" onClick={actionClickLabel.bind(this, label)}>
  //       <DocumentLabel color={label.color} name={label.name} />
  //     </CDropdownItem>
  //   )
  // }
  //
  // if (html.length === 0) {
  //   html.push(<div className="opacity-75 text-center">
  //     <Translation>Žádné štítky</Translation>
  //   </div>)
  // }
  //
  // return html;
}

import {action, makeAutoObservable} from "mobx";
import {apiRequest, getHeaders} from "../../helpers/request";
import {REQ_TYPE} from "../../constans/request";
import {showResponseNotificationMessage} from "../../helpers/notification";
import CompanyStore from "../Company"
class AgendaStore {
  loading = false;
  data = []

  constructor() {
    makeAutoObservable(this, {
      getAll: action,
      set: action
    })
  }

  set(key, value){
    this[key] = value
  }

  async getAll() {
    this.set("loading",true);
    let res = await apiRequest("/company/"+CompanyStore.detail.id+"/agenda", REQ_TYPE.get, null, getHeaders());
    this.set("loading",false)
    showResponseNotificationMessage(res);
    this.set('data', res.data)
    return res.status === 200;
  }

}

export default new AgendaStore()

export const GLOBAL_DATE_FORMAT = 'd. M. y'
export const GLOBAL_DATE_TIME_FORMAT = 'd. M. y H:mm'
export const TEST_IC_COMPANIES = ['09356291', '02104148', '01471422', '06935834', '07249322', '23452345', '04746597']
export const RECEIPT_TYPES = [
  {
    id: 'oil',
    text: 'Pohonné hmoty'
  },
  {
    id: 'other',
    text: 'Ostatní'
  }
]

import { action, makeAutoObservable } from 'mobx'
import { apiRequest, getHeaders } from '../../helpers/request'
import { REQ_TYPE } from '../../constans/request'
import {dataURLtoFile} from "../../helpers/base";
import {showBulkNotification} from "../../helpers/notification";
import {NOTIFICATION_TYPE} from "../../constans/notification";
import CompanyStore from "./../Company";

class DocumentStore {
  loading = false;
  uploading = false;
  getting_doc = false
  loadingAttachments = false;
  updating = false;
  emailLoading = false;
  detail = null;
  emailInformation = null;
  removingAttachment = false;
  creatingLabel = false;
  documentLink = null;
  showModal = false;
  removing = false;
  changeMultipleFiles = false;
  reporting = false;
  downloading = false;
  gettingFile = false;
  archiveLoading = false;


  constructor() {
    makeAutoObservable(this, {
      get: action,
      setLoading: action,
      setUpdating: action,
      setUploading: action,
      setEmailLoading: action,
      getEmail: action,
      setEmailInformation: action,
      set: action,
      archive: action,
    })
  }

  set(name, value) {
    this[name] = value;
  }

  setUpdating(value) {
    this.updating = value;
  }

  setUploading(value) {
    this.uploading = value;
  }

  setLoading(value) {
    this.loading = value;
  }

  setEmailInformation(data) {
    this.emailInformation = data;
  }

  setEmailLoading(value) {
    this.emailLoading = value;
  }

  async getFile(id) {
    this.set('gettingFile', true);
    try {
      let res = await apiRequest("/document/"+id + "/file", REQ_TYPE.get, null, getHeaders());
      this.detail.document = res.data
    } catch (e) {
      console.log(e);
    }
    this.set('gettingFile', false);
  }

  async get(id, parent = null) {
    this.setLoading(true);
    try {
      let res = await apiRequest("/document/"+id + "?getFile=false", REQ_TYPE.get, null, getHeaders());

      if (res.status === 404) {
        if (parent) {
          parent.history.push('/404')
        }
        return false;
      }

      if (res.data) {
        this.detail = res.data;
      }
      this.setLoading(false)
      return true;

    } catch (e) {
      console.log(e);
      this.detail = null;
      return false
    }
  }

  async add(requestData) {
    this.setUploading(true)
    // generate file from base64 string
    const file = dataURLtoFile(requestData.base64)
    const data = new FormData()
    data.append('picture', file, requestData.name)
    data.append('company_id', requestData.company_id)
    const labelIds = requestData.labels.map(item => item.id);
    data.append('labels', labelIds)
    data.append('description', requestData.description || '')
    data.append('total_amount', requestData.total_amount || '')
    data.append('receipt_type', requestData.receipt_type || '')
    data.append('type_receipt', requestData.type || '')
    data.append('agenda_code', requestData.agenda_code || '')
    data.append('originalName', requestData.originalName || '')
    data.append('name', requestData.name || '')

    const config = { 'Content-Type': 'multipart/form-data' };

    const body = {
      company_id: requestData.company_id,
      description: requestData.description || '',
      labelId: requestData.labelId,
      total_amount: requestData.total_amount,
      type_receipt: requestData.type,
      receipt_type: requestData.receipt_type,
      agenda_code: requestData.agenda_code,
      originalName: requestData.originalName,
      name: requestData.name
    }

    let res = await apiRequest('/document', REQ_TYPE.post, data, getHeaders(null, config, body) );
    this.setUploading(false)
    return res.status
  }

  async download(id) {
    this.set('downloading',true);
    try {
      let res = await apiRequest("/document/"+id, REQ_TYPE.get, null, getHeaders());

      const a = document.createElement("a"); //Create <a>
      a.href = "data:"+res.data.type+";base64," + res.data.document; //Image Base64 Goes here
      a.download = res.data.filename; //File name Here
      a.click(); //Downloaded file

    } catch (e) {
      console.log(e);
    }
    this.set('downloading',false);
  }

  async remove(item) {
    this.set('removing', true)
    let res = await apiRequest('/document/'+item.id, REQ_TYPE.delete, null, getHeaders() );
    this.set('removing', false)
    const type = (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger;
    showBulkNotification(res.messages, type);
    return res.status === 200;
  }

  async removePermanent(item) {
    this.set('removing', true)
    let res = await apiRequest('/document/'+item.id+'/permanent', REQ_TYPE.delete, null, getHeaders() );
    this.set('removing', false)
    const type = (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger;
    showBulkNotification(res.messages, type);
    return res.status === 200;
  }

  async update(document_id, data) {
    this.setUpdating(true);
    let res = await apiRequest('/document/'+document_id, REQ_TYPE.put, data, getHeaders() );
    const type = (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger;
    showBulkNotification(res.messages, type);
    this.setUpdating(false);
    return res.status === 200;
  }

  async getEmail(document_id) {
    this.setEmailLoading(true);
    let res = await apiRequest('/document/'+document_id+'/email', REQ_TYPE.get, {}, getHeaders() );
    this.setEmailInformation(res.data);
    this.setEmailLoading(false);
  }

  async setVisit (document_id) {
    await apiRequest('/document/'+document_id+'/visit', REQ_TYPE.post, {}, getHeaders() );
  }

  async uploadAttachments(documents) {

    this.set('loadingAttachments', true);
    const data = new FormData()
    for(let i in documents) {
      const document = documents[i];
      const file = dataURLtoFile(document.base64)
      data.append('files', file, document.name)
    }

    const config = { 'Content-Type': 'multipart/form-data' };
    const res = await apiRequest('/document/'+this.detail.id+'/company/'+CompanyStore.detail.id+'/attachment', REQ_TYPE.post, data, getHeaders(null, {}, config) );
    const type = (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger;
    showBulkNotification(res.messages, type);
    this.set('loadingAttachments', false);
    return (res.status === 200);
  }

  async removeAttachment (attachment_id) {
    this.set('removingAttachment', true);
    const res = await apiRequest('/document/'+this.detail.id+'/company/'+CompanyStore.detail.id+'/attachment/'+attachment_id, REQ_TYPE.delete, {}, getHeaders() );
    const type = (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger;
    showBulkNotification(res.messages, type);
    this.detail.attachments = this.detail.attachments.filter(item => item.id !== attachment_id);

    this.set('removingAttachment', false);
    return (res.status === 200);
  }

  async downloadAttachment (attachment_id) {
    const res = await apiRequest('/document/'+this.detail.id+'/attachment/'+attachment_id, REQ_TYPE.get, {}, getHeaders() );
    const a = document.createElement("a"); //Create <a>
    a.href = res.data.url; //Image Base64 Goes here
    a.download = res.data.filename; //File name Here
    a.click(); //Downloaded file
  }

  async getLinkToDocument(attachment_id) {
    const res = await apiRequest('/document/'+this.detail.id+'/attachment/'+attachment_id, REQ_TYPE.get, {}, getHeaders() );
    return res.data.url;
  }

  async setMainDocument(selected, removed) {
    this.set('changeMultipleFiles', true)
    const res = await apiRequest('/document/'+this.detail.id+'/main_document', REQ_TYPE.post, {
      selected: selected,
      removed: removed
    }, getHeaders() );
    this.set('changeMultipleFiles', false)
    const type = (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger;
    showBulkNotification(res.messages, type);
    if (res.data.all_documents) {
      return 201
    }
    return res.status;
  }

  async aloneAttachment(document_id, new_document_id) {
    const res = await apiRequest('/document/'+this.detail.id+'/alone/'+new_document_id, REQ_TYPE.get, {}, getHeaders() );
    const type = (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger;
    showBulkNotification(res.messages, type);
    return true;
  }

  async getDocumentQueueByDocument(id, table) {
    const res = await apiRequest('/document/'+id+'/document_queue/'+table, REQ_TYPE.get, {}, getHeaders() );
    return res.data
  }

  async ocr(base64, filename) {
    const data = new FormData()
    data.append('picture',  dataURLtoFile(base64), filename)
    data.append('filename', filename)
    const config = { 'Content-Type': 'multipart/form-data' };

    const res = await apiRequest('/document/ocr', REQ_TYPE.post, data, getHeaders(null, config) );
    return res.data
  }

  async getDocumentLink(id) {
    this.set('getting_doc', true)
    const res = await apiRequest('/document/'+id+'/link', REQ_TYPE.get, {}, getHeaders() );
    this.documentLink = res.data.url
    this.set('getting_doc', false)
  }

  async restoreDocument(id) {
    const res = await apiRequest('/document/'+id+'/restore', REQ_TYPE.get, {}, getHeaders() );
    const type = (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger;
    showBulkNotification(res.messages, type);
    return true;
  }

  async changeDocumentCompany(company_id) {
    const res = await apiRequest('/document/'+this.detail.id+'/change/company/'+company_id, REQ_TYPE.post, {}, getHeaders() );
    const type = (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger;
    showBulkNotification(res.messages, type);
    return res.status === 200
  }

  async comment(document_id, data = {}) {
    const res = await apiRequest('/document/'+document_id+'/comment', REQ_TYPE.post, data, getHeaders() );
    const type = (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger;
    showBulkNotification(res.messages, type);
    return true;
  }

  async reportBug(text = '') {
    this.set('reporting', true)
    const res = await apiRequest('/document/'+this.detail.id+'/bug-report', REQ_TYPE.post, {message: text}, getHeaders() );
    const type = (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger;
    showBulkNotification(res.messages, type);
    this.set('reporting', false)
    return true;
  }

  async archive(document_id) {
    this.set('archiveLoading', true)
    const res = await apiRequest('/document/'+document_id+'/archive', REQ_TYPE.post, null, getHeaders() );
    const type = (res.status === 200)? NOTIFICATION_TYPE.success : NOTIFICATION_TYPE.danger;
    showBulkNotification(res.messages, type);
    this.set('archiveLoading', false)
    return true;
  }
}

export default new DocumentStore()

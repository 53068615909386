import React from 'react'
import { CFooter } from '@coreui/react'
import Translation from '../components/translation'
const { DateTime } = require("luxon");

const TheFooter = () => {
  return (
    <CFooter fixed={false} className="d-inline-flex align-items-center">
      <div>
        <a href="https://edicon.cz" target="_blank" rel="noopener noreferrer">Edicon s.r.o</a>
        <span className="ml-1">&copy; {DateTime.now().year}</span>
      </div>
      <div className="mfs-auto">
        <span className="mr-1"><Translation>Vytvořeno</Translation></span>
        <a href="https://edicon.cz" target="_blank" rel="noopener noreferrer">Edicon s.r.o</a>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter)

import {CButton, CCol, CRow, CTooltip} from "@coreui/react";
import Translation from "../translation";
import {AiOutlineCheck} from "react-icons/ai";
import {BiCommentAdd} from "react-icons/bi";
import {RiArrowGoBackLine} from "react-icons/ri";
import React from "react";
import {toJS} from "mobx";
import DocumentStore from "../../stores/Document";
import UserStore from "../../stores/User";
import {showNotification} from "../../helpers/notification";
import {NOTIFICATION_TYPE} from "../../constans/notification";

export const checkApprovalDocumentFields = (data) => {
  let errorFields = [];
  if (data.require_approval_fields) {
    for(let i in data.require_approval_fields) {
      const field = data.require_approval_fields[i];
      if (!data.data[i]) {
        errorFields.push(field.label)
      }
    }
  }

  if (errorFields.length > 0) {
    showNotification(NOTIFICATION_TYPE.danger, "Dokument pro schválení musí obsahovat všechny potřebné údaje: "+errorFields.join(', '))
    return false;
  }
  return true;
}


export const getIsActualUserApproval = (data) => {
    const approvalDocuments = toJS(data.document_approval).sort((item, item2) => item.order - item2.order);
    let actualUserIndex = (data.approvalSettings)? data.approvalSettings.actualApprovalIndex : 0;
    let actualUser = approvalDocuments[actualUserIndex];

    return {
      isActual: (actualUser)? actualUser.user.id === UserStore.actualUser.id : false,
      row: actualUser
    };

}


export const  approvalSnippetButtons = (data, showRejectModal, showCommentModal, handleApprove) => {
  const result = getIsActualUserApproval(DocumentStore.detail);
  if (!result.isActual) {
    return null;
  }

  return <CRow className="mb-3">
    <CCol className="order-sm-1 pb-2">
      <div className="w-100 border-bottom">
        <h4 className="mb-3"><Translation>Schvalování dokumentu</Translation></h4>
      </div>
      <div className="w-100 pt-4">
        <div className="d-inline-flex justify-content-between w-100">

          <div>
            <CTooltip
              content={<Translation>Schválit</Translation>}
              placement={"top"}
            >
              <CButton
                key={'0'}
                color={'success'}

                onClick={()=> handleApprove(result.row)}
              >
                <AiOutlineCheck size={24}/> <Translation>Schválit</Translation>
              </CButton>
            </CTooltip>
          </div>

          <div className="ml-3">

            <CTooltip
              content={<Translation>Přidat komentář</Translation>}
              placement={"top"}
            >
              <CButton
                key={'0'}
                color={'info'}

                onClick={()=> {
                  // handleCommentAction(3)
                  showCommentModal(result.row);
                }}
              >
                <BiCommentAdd size={24}/> <Translation>Přidat komentář</Translation>
              </CButton>
            </CTooltip>
          </div>

          <div className="ml-3">
            <CTooltip
              content={<Translation>Zamítnout</Translation>}
              placement={"top"}
            >
              <CButton
                key={'0'}
                color={'danger'}

                onClick={()=> {
                  showRejectModal(result.row);
                }}
              >
                <RiArrowGoBackLine size={24}/> <Translation>Zamítnout</Translation>
              </CButton>
            </CTooltip>
          </div>

        </div>
      </div>
    </CCol>
  </CRow>
}

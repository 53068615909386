import React,  { useState } from 'react';
import "./style.scss";
import { observer } from 'mobx-react'
// import ButtonDefault from "../Button";
// import DocumentStore from "../../stores/Document";
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import DocumentStore from "../../stores/Document";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.min.js";
import Translation from "../translation";
import {CButton} from "@coreui/react";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


const Pdf = (props) => {

  const [numPages, setNumPages] = useState(null);
  // const [show, setShow] = useState(true);
  // const [pageNumber, setPageNumber] = useState(1);


  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  function onLoadError(e) {
    console.log("onLoadError", e)
  }
  function onSourceError(e) {
    console.log("onSourceError", e)
  }

  let url = props.document.url;

  if (props.document.url && props.document.url.indexOf('https') === -1) {
    url = `data:application/pdf;base64,${encodeURI(props.document.url)}`
  }

  return <><div className="pdf-area"
  >
    <Document
    file={(props.document.url)? url : `data:application/pdf;base64,${encodeURI(props.document.document)}`}
    onLoadSuccess={onDocumentLoadSuccess} options={{cMapPacked: false,}}
    onLoadError={onLoadError}
    onSourceError={onSourceError}
    loading={(e) => {
      return <div><Translation>Načítání PDF</Translation></div>
    }}
    error={(e) => {
        return <>
          <div>
            <Translation>Zobrazení PDF selhalo</Translation>
          </div>
          <div>
            <CButton color="secondary" onClick={() => {
              DocumentStore.showModal = false;
              DocumentStore.get(props.match.params.id, props)
            }}
            >
              <Translation>Načíst znova</Translation>
            </CButton>
          </div>
        </>
    }}
    onClick={() => {
      if (props.useModal) {
        DocumentStore.showModal = true;
      }
    }}
  >
    {/*<Page scale={4} pageNumber={pageNumber} />*/}
    {Array.from(new Array(numPages), (el, index) => (
      <Page key={`page_${index + 1}`} pageNumber={index + 1} />
    ))}
  </Document>

  </div>
  </>

  // return (
  //     <div className="height-40rem">
  //       <Viewer
  //         renderError={error}
  //         plugins={[ defaultLayoutPluginInstance]}
  //         fileUrl={props.url}
  //         defaultScale={SpecialZoomLevel.PageWidth}
  //       />
  //     </div>
  //
  // );
};

export default observer(Pdf);

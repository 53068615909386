import React, { Component } from 'react';
import { CButton, CSpinner } from '@coreui/react'
class ButtonDefault extends Component {
  render() {

    if (this.props.show !== undefined && !this.props.show) {
      return null;
    }

    let spinnerSettings = {
      variant: "grow",
      size: "sm"
    }

    if (this.props.spinnerSettings) {
      spinnerSettings = {...spinnerSettings, ...this.props.spinnerSettings}
    }


    return (
      <CButton
        {...this.props.CButtonSettings}
      >
        {(this.props.loading)?
          <CSpinner {...spinnerSettings} />
        :
          this.props.children
        }
      </CButton>
    )
  }
}

export default ButtonDefault;

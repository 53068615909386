import CompanyStore from "../../stores/Company";
import {DEFAULT_AGENDAS} from "../../constans/agenda";

export default function isFieldVisible (name) {
 return CompanyStore.detail && CompanyStore.detail.settings && CompanyStore.detail.settings['hidden_fields'] && CompanyStore.detail.settings['hidden_fields'].indexOf(name) > -1;
}

export function getAgendaFromCode(code) {
  const agenda = DEFAULT_AGENDAS.find(item => item.code === code)

  if (agenda) {
    return agenda.title
  }

  return "-"
}

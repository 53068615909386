import React, { Component } from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import Translation from './components/translation'
import AuthStore from './stores/Auth';
import {observer} from "mobx-react";
import history from './helpers/history';
import PrivateRoute from './components/PrivateRoute'
import PublicRoute from './components/PublicRoute'
import { Toaster } from 'react-hot-toast'
import { getLocalStorageValue } from './helpers/localStorage'
import LoadingPage from './components/LoadingPage'
import DocumentsInLine from './views/pages/actualDocuments'
import ErrorHandler from "./components/ErrorHandler";
import {MODULES} from "./constans/modules";

import './scss/style.scss';
import Archive from "./views/pages/archive";
import AdminRoute from "./components/AdminRoute";
import AdminCompanies from "./views/pages/admin/companies";
import AdminInvoices from "./views/pages/admin/invoices";
import AdminCustomers from "./views/pages/admin/customers";
import ReportError from "./views/pages/reportError";

const DocumentDetail = React.lazy(() => import('./views/pages/documentDetail'));
const Settings = React.lazy(() => import('./views/pages/settings'));
const Users = React.lazy(() => import('./views/pages/users'));
const ApprovalCreate = React.lazy(() => import('./views/pages/approvals/Create'));
const Approvals = React.lazy(() => import('./views/pages/approvals'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const History = React.lazy(() => import('./views/pages/history'));
const DocumentsTrash = React.lazy(() => import('./views/pages/documentsTrash'))

const SettingsAgenda = React.lazy(() => import('./views/pages/settings/Agenda'))
const SettingsDocument = React.lazy(() => import('./views/pages/settings/Document'))
const SettingsLabel = React.lazy(() => import('./views/pages/settings/Labels'))

const BankNotice = React.lazy(() => import('./views/pages/bankNotice'))
const BankNoticeDetail = React.lazy(() => import('./views/pages/bankNotice/detail'))

const Admin = React.lazy(() => import('./views/pages/admin'))
const AdminCustomersForm = React.lazy(() => import('./views/pages/admin/customers/form'))

class App extends Component {

  constructor (props) {
    super(props);
    const token = getLocalStorageValue('token');
    AuthStore.setToken(token);
    if (window.location.pathname.length === 1 && (window.location.pathname === "/" ||
      window.location.pathname.indexOf('dashboard') > -1)) {
      history.push("/dokumenty")
    }
  }

  async componentDidMount() {
    // const token = getLocalStorageValue('token');
    await AuthStore.checkLoginToken()
  }

  render() {

    if (AuthStore.verifying) {
      return <LoadingPage/>
    }

    return (

      <Router key={AuthStore.userLogin}>
          <React.Suspense fallback={LoadingPage}>
            <Toaster />
            <ErrorHandler>
              <Switch>
                  <PublicRoute exact path="/login" name={<Translation>Přihlášení</Translation>} component={Login} />
                  <PrivateRoute exact path="/logout" name={"Logout"} component={() => (AuthStore.logout())} />
                  <PrivateRoute exact path="/" name="Dokumenty" component={DocumentsInLine} module={MODULES.documents} />
                  <PrivateRoute exact path="/schvalovani" name={<Translation>Schvalování</Translation>} module={MODULES.approvals} component={Approvals}/>
                  <PrivateRoute exact path="/schvalovani/pravidlo/nove" name={<Translation>Schvalování</Translation>} module={MODULES.approvals} component={ApprovalCreate}/>
                  <PrivateRoute exact path="/schvalovani/pravidlo/uprava/:id" name={<Translation>Schvalování</Translation>} module={MODULES.approvals} component={ApprovalCreate}/>
                  <PrivateRoute exact path="/schvalovani/pravidlo/detail/:id" name={<Translation>Schvalování</Translation>} module={MODULES.approvals} component={ApprovalCreate}/>
                  <PrivateRoute exact path="/dokumenty/:page?" name={<Translation>Dokumenty</Translation>} component={DocumentsInLine} module={MODULES.documents} />
                  <PrivateRoute exact path="/dokumenty/detail/:id" name={<Translation>Dokumenty</Translation>} component={DocumentDetail} module={MODULES.documents} />
                  <PrivateRoute exact path="/uzivatele" name={<Translation>Uživatelé</Translation>} component={Users} />
                  <PrivateRoute exact path="/archiv/:page?" name={<Translation>Archiv</Translation>} module={MODULES.archive} component={Archive} />
                  <PrivateRoute exact path="/log/:page?" name={<Translation>Historie</Translation>} module={MODULES.documents} component={History} />
                  <PrivateRoute exact path="/nastaveni" name={<Translation>Nastavení</Translation>} component={Settings} />
                  <PrivateRoute exact path="/nastaveni/agenda" name={<Translation>Nastavení agenda</Translation>} component={SettingsAgenda} />
                  <PrivateRoute exact path="/nastaveni/dokumenty" name={<Translation>Nastavení dokumenty</Translation>} component={SettingsDocument} />
                  <PrivateRoute exact path="/nastaveni/stitky" name={<Translation>Nastavení štítky</Translation>} component={SettingsLabel} />
                  <PrivateRoute exact path="/aviza/:page?" name={<Translation>Aviza</Translation>} component={BankNotice} />
                  <PrivateRoute exact path="/aviza/detail/:id" name={<Translation>Aviza detail</Translation>} component={BankNoticeDetail} />
                  <PrivateRoute exact path="/kos/:page?" name={<Translation>Koš</Translation>} component={DocumentsTrash} />
                  <PrivateRoute exact path="/hlaseni-problemu/:id" name={<Translation>Hlášení problému</Translation>} component={ReportError} />
                  <AdminRoute exact path="/admin" name={<Translation>Admin společností</Translation>} component={Admin} />
                  <AdminRoute exact path="/admin/zakaznici" name={<Translation>Admin zákazníci</Translation>} component={AdminCustomers} />
                  <AdminRoute exact path="/admin/zakaznici/novy" name={<Translation>Admin zákazníci</Translation>} component={AdminCustomersForm} />
                  <AdminRoute exact path="/admin/zakaznici/:id" name={<Translation>Admin zákazníci</Translation>} component={AdminCustomers} />
                  <AdminRoute exact path="/admin/spolecnosti" name={<Translation>Admin společností</Translation>} component={AdminCompanies} />
                  <AdminRoute exact path="/admin/faktury" name={<Translation>Admin společností</Translation>} component={AdminInvoices} />
                  <PrivateRoute path="*" name={<Translation>Stránka nenalezena</Translation>} component={Page404}/>
              </Switch>
            </ErrorHandler>
          </React.Suspense>
      </Router>
    );
  }
}

export default observer(App);

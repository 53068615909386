import React, { Component } from 'react';
import {observer} from "mobx-react";
import {CDropdownItem, CDropdown, CDropdownMenu, CDropdownToggle, CSelect} from '@coreui/react'
import Translation from "../translation";
import DocumentLabel from "../DocumentLabel";
import {getLabelById, renderCompanyLabels} from "../DocumentLabels";
import CompanyStore from '../../stores/Company';
import AgendaStore from '../../stores/Agenda';
import DocumentStore from '../../stores/Document';
import isFieldVisible from "../../helpers/company";
import moment from "moment";
import {companyHasModule} from "../../helpers/modules";
import {MODULES} from "../../constans/modules";
import {toJS} from "mobx";

class TableFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selected: []
    }

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    if (CompanyStore.detail) {
      CompanyStore.getEmailList(this.props.archive)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (event && event.target && event.target.className && event.target.className.indexOf && event.target.className.indexOf('search-element') === -1) {
      CompanyStore.search = []
    }
  }

  handleSetLabel(label) {
    let labels = 'null'
    if (label === null) {
      this.setState({
        selected: []
      })
    } else {
      this.setState({
       selected: [label]
      })
      labels = label.id
    }

    this.props.changeFilter({
      target: {
        name: 'labels',
        value: labels
      }
    });


  }

  async handleSelectDocument(table, id) {
    const data = await DocumentStore.getDocumentQueueByDocument(id, table);
    CompanyStore.search = [];
    this.props.history.push("/dokumenty/detail/"+data.id)
  }

  getFilename(table, data) {
    return  data.originalName;
    if (table.table === 'document_attachment' || table.table === 'document') {
    } else {
      return data.filename;
    }
  }

  renderSearch() {
    const html = [];

    for(let i in CompanyStore.search) {
      const table = CompanyStore.search[i];
      for(let j in table.data) {
        const data = table.data[j];
        if (data) {
          html.push(<CDropdownItem className="search-element" title={'/dokumenty/detail/'+data.id} key={i + "_" + j} href={"#"} onClick={this.handleSelectDocument.bind(this, 'document_queue', data.id)}>
            {this.getFilename(table, data)}
          </CDropdownItem>)
        }
      }
    }

    return html
  }

  handleResetFilters() {

  }

  renderOptionsPeriod() {
    const html = [];
    const actual_year = moment().get('year');
    const start_year = 2022;
    for(let year = start_year; year <= actual_year; year++ ) {
      let months = 12;
      if (year === actual_year) {

        months = moment().get('month') + 1;
      }
      for(let month = 1; month <= months; month++ ) {
        month = month.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        html.unshift(<option key={year + "-" + month} value={year+'/'+month+'/1'}>{year+'/'+month}</option>)
      }
    }
    html.unshift(<option key={"0-1"} value={"all"}>Vše</option>)
    return html;
  }

  render() {
    let activeLabel = getLabelById(this.props.filters.labels)
    return <div className="w-100 mb-3">
      <div className=" align-items-center justify-content-between w-100 row">
        {(this.props.search)?
          // <CRow className={"w-100 d-inline-flex align-items-center justify-content-between mb-3"}>
            <div className="d-inline-flex align-items-center col col-md-6 col-sm-12 mb-3">
              <span className="pr-2">
                <strong><Translation>Vyhledat</Translation>:</strong>
              </span>
              <div className="search-element" ref={this.setWrapperRef}>

                <CDropdown className="search-element">
                  <input type="text" className="search-element border border-radius-t1 border-radius-t0" style={{"lineHeight": 2}} onChange={this.props.changeSearch} />
                  <CDropdownMenu className="search-element" show={CompanyStore.search.length > 0}>
                    {this.renderSearch()}
                  </CDropdownMenu>

                </CDropdown>
              </div>
            </div>
          // </CRow>
        : null }
          <div className="col-md-6 justify-content-end align-items-end text-right col-sm-12">
            {(this.props.archive)?

                <div className="d-inline-flex align-items-center w-auto mr-3 mb-3">
                  <div className="pr-2">
                    <strong><Translation>Období</Translation>:</strong>
                  </div>
                  <div>

                    <CSelect value={this.props.filters.period} onChange={this.props.changeFilter} name={'period'}>
                      {this.renderOptionsPeriod()}
                    </CSelect>
                  </div>

                </div>


            : null}
            {(!isFieldVisible('labels'))?
              <div className="d-inline-flex align-items-center mr-3 mb-3">
                <div className="mr-2"><strong>Štítky: </strong></div>
                <CDropdown>
                  <CDropdownToggle color="secondary" className="border">
                    {(activeLabel)?
                      <DocumentLabel color={activeLabel.color} name={activeLabel.name} />
                      :
                      <Translation>Vyberte štítek</Translation>
                    }
                  </CDropdownToggle>
                  <CDropdownMenu>
                    {renderCompanyLabels(this.handleSetLabel.bind(this))}
                    {activeLabel?
                      <CDropdownItem key={'-1'} href="#" onClick={this.handleSetLabel.bind(this, null)}>
                        <small className="cursor-pointer">Zrušit vybraný štítek</small>
                      </CDropdownItem>
                      : null}
                  </CDropdownMenu>
                </CDropdown>
              </div>
              : null}

              <div className="d-inline-flex align-items-center mr-3 mb-3">
                <div className="mr-2"><strong>Dodavatel: </strong></div>
                <div>
                  <CSelect value={this.props.filters.email_from} onChange={this.props.changeFilter} name={"email_from"}>
                    <option value={"null"}  key={"-1"} selected={this.props.filters.email_from === undefined || this.props.filters.email_from === "null"}>Vše</option>
                    {CompanyStore.emailList.map((item, i) => { return <option value={item} key={i}  selected={(this.props.filters.email_from === item? "selected" : '' )}>{item}</option> })}
                  </CSelect>
                </div>
              </div>
            <div className="d-inline-flex align-items-center mr-3 mb-3">
              <div className="mr-2"><strong>Seřadit: </strong></div>
              <div>
                <CSelect value={this.props.filters.asc} onChange={this.props.changeFilter} name={"asc"}>
                  <option value={"null"}  key={"-1"} selected={this.props.filters.asc === undefined || this.props.filters.asc === "null"}>Výchozí</option>
                  <option value={'asc'} key={0}  selected={(this.props.filters.asc === 'asc'? "selected" : '' )}>Název vzestupně</option>
                  <option value={'desc'} key={1}  selected={(this.props.filters.asc === 'desc'? "selected" : '' )}>Název sestupně</option>

                </CSelect>
              </div>
            </div>

            <div className="d-inline-flex align-items-center mb-3">
              <div className="mr-2"><strong>Agenda: </strong></div>
              <div>
                <CSelect value={this.props.filters.agenda} onChange={this.props.changeFilter} name={"agenda"}>
                  <option value={"null"}  key={"-1"} selected={this.props.filters.agenda === undefined || this.props.filters.agenda === "null"}>Vše</option>
                  {AgendaStore.data.map((item, i) => { return <option value={item.code} key={i}  selected={(this.props.filters.agenda === item? "selected" : '' )}>{item.title}</option> })}
                </CSelect>
              </div>
            </div>

            {(CompanyStore.detail && CompanyStore.detail.hasApprovals && companyHasModule(MODULES.approvals))?
              <div className="d-inline-flex align-items-center w-auto mb-3 ml-3">
                <div className="pr-2">
                  <strong><Translation>Schvalování</Translation>:</strong>
                </div>
                <div>

                  <CSelect value={this.props.filters.approval} onChange={this.props.changeFilter} name={'approval'}>
                    <option value={"null"}>Vše</option>
                    <option value={"wait"}>Čeká</option>
                    {/*<option value={"filename"}>Název souboru</option>*/}
                    <option value={"approve"}>Schváleno</option>
                    <option value={"reject"}>Odmítnuto</option>
                  </CSelect>
                </div>

              </div>
              : null}
              {(this.props.is_active_filter)?
                <div className="d-flex justify-content-end">
                  <div className={"btn-link cursor-pointer"} onClick={this.props.handleResetFilters}>
                    <Translation>Vynulovat filtry</Translation>
                  </div>
                </div>
              : null}
            </div>
        {/*</CRow>*/}
      </div>
    </div>

  }
}

export default observer(TableFilter)

import CompanyStores from './../../stores/Company';
import {MODULES} from "../../constans/modules";
import {companyHasModule} from "../modules";
import UserStore from "../../stores/User";

export function hasUserWriteRole(module) {
  if (CompanyStores.detail !== null && CompanyStores.detail.user_roles && CompanyStores.detail.user_roles[module]) {
    if (companyHasModule(module)) {
      return CompanyStores.detail.user_roles[module].indexOf('w') > -1
    }
  }
  return false;
}

export  function hasUserReadRole(module) {
  if (CompanyStores.detail !== null && CompanyStores.detail.user_roles && CompanyStores.detail.user_roles[module]) {
    if (companyHasModule(module)) {
      return CompanyStores.detail.user_roles[module].indexOf('r') > -1
    }
  }
  return false;
}

export  function canEditApproveDocument(documentCreatedId) {
  if (CompanyStores.detail !== null && CompanyStores.detail.user_roles && CompanyStores.detail.user_roles.only_own_document && (documentCreatedId === UserStore.actualUser.id)) {
    if (companyHasModule(MODULES.documents) ) {
      return CompanyStores.detail.user_roles[MODULES.documents].indexOf('w') > -1
    }
  } else if (CompanyStores.detail !== null && CompanyStores.detail.user_roles && !CompanyStores.detail.user_roles.only_own_document) {
    return CompanyStores.detail.user_roles[MODULES.documents].indexOf('w') > -1
  }
  return false;
}


export function prepareNavBar(navItems) {
  const newNavItems = [];

  for(let i in navItems) {
    const navItem = navItems[i];

    if (navItem.module) {
      let hasRole =  false;

      if (companyHasModule(navItem.module)) {
        hasRole = hasUserWriteRole(navItem.module) || hasUserReadRole(navItem.module);
        if (CompanyStores.detail && (!CompanyStores.detail.hasApprovals && navItem.module === MODULES.approvals)) {
          hasRole = false;
        }

        if (navItem.module === MODULES.documents) {
          if (CompanyStores.detail && CompanyStores.detail.hasApprovals) {
            hasRole = hasUserWriteRole(MODULES.approvals) || hasUserReadRole(MODULES.approvals);
            if (!hasRole) {
              hasRole = hasUserWriteRole(MODULES.documents) || hasUserReadRole(MODULES.documents);
            }
          }
        }


        if (navItem.module === MODULES.document_history) {
          hasRole = hasUserWriteRole(MODULES.documents) || hasUserReadRole(MODULES.documents);
        }

        if (navItem.module === MODULES.archive) {
          hasRole = true
        }
      }



      if (hasRole) {
        newNavItems.push(navItem)
      }
    } else {
      newNavItems.push(navItem)
    }
  }

  return newNavItems;
}

import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTextarea
} from "@coreui/react";
import Translation from "../../translation";
import React, {Component} from "react";
import {observer} from "mobx-react";

class ApprovalsModals extends Component {

  render() {
    return <>

      <CModal
        show={this.props.states.rejectModal}
        onClose={this.props.closeModal.bind(this)}
        color="danger"
      >
        <CModalHeader closeButton>
          <CModalTitle><Translation>Odmítnutí dokumentu</Translation></CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CFormGroup row>
            <CCol>
              <strong><Translation>Komentář</Translation></strong>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol>
              <CTextarea
                name="rejectText"
                id="textarea-input"
                className="w-100"
                value={this.props.states.text}
                onChange={this.props.changeValue}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton color="danger" onClick={this.props.handleRejectModal.bind(this, this.props.approvalUserId, this.props.states.documentId)}><Translation>Odmítnout</Translation></CButton>{' '}
          <CButton color="secondary" onClick={this.props.closeModal.bind(this)}><Translation>Zavřít</Translation></CButton>
        </CModalFooter>
      </CModal>

      <CModal
        show={this.props.states.commentModal}
        onClose={this.props.closeModal.bind(this)}
        color="info"
      >
        <CModalHeader closeButton>
          <CModalTitle><Translation>Komentář</Translation></CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CFormGroup row>
            <CCol>
              <CTextarea
                name="commentText"
                id="textarea-input"
                className="w-100"
                value={this.props.states.text}
                onChange={this.props.changeValue}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton color="success" onClick={this.props.handleCommentModal.bind(this, this.props.approvalUserId)}><Translation>Uložit komentář</Translation></CButton>{' '}
          <CButton color="secondary" onClick={this.props.closeModal.bind(this)}><Translation>Zavřít</Translation></CButton>
        </CModalFooter>
      </CModal>
    </>
  }
}

export default observer(ApprovalsModals);

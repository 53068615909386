import React, {Component} from "react";
import {observer} from "mobx-react";
import {CBadge, CButton, CDataTable, CTooltip} from "@coreui/react";
import CompanyStore from "../../stores/Company";
import NoTableItems from "../NoTableItems";
import Translation from "../translation";
import UserStores from "../../stores/User";
import DocumentLabel from "../DocumentLabel";
import {DateTime} from "luxon";
import {GLOBAL_DATE_FORMAT, GLOBAL_DATE_TIME_FORMAT} from "../../constans/base";
import CIcon from "@coreui/icons-react";
import {hasUserWriteRole} from "../../helpers/roles";
import {MODULES} from "../../constans/modules";
import Pagination from "../Pagination";
import DocumentStore from "../../stores/Document";
import CompanyStores from "../../stores/Company";
import {createFilterRequest, getDocumentFilter} from "../../helpers/form";
import {toJS} from "mobx";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class DocumentsTable extends Component {
  handleDownload(id) {
    DocumentStore.download(id)
  }

  async handleRemoveDocument(item) {
    confirmAlert({
      title: 'Odstranění dokumentu',
      message: "Opravdu si přejete odstranit dokument?",
      buttons: [
        {
          label: 'ANO',
          onClick: async () => {
            let filters = getDocumentFilter()
            let query = createFilterRequest(filters)
            if (item.removed) {
              if (await DocumentStore.removePermanent(item)) {

                CompanyStore.getDocuments(CompanyStore.detail.id, 'removed=true')
              }

            } else {
              if (await DocumentStore.remove(item)) {
                CompanyStore.getDocuments(CompanyStore.detail.id, query)
              }
            }
          }
        },
        {
          label: 'NE',
          onClick: () => {}
        }
      ]
    });
  }

  render() {
    return <>
      <CDataTable
        key={this.props.loading + '_documents'}
        loading={this.props.loading}
        items={toJS(this.props.data)}
        noItemsViewSlot={<NoTableItems  text={<Translation>{(this.props.emptyText)? this.props.emptyText : "Ve frontě nejsou žádné dokumenty"}</Translation>}/>}
        fields={this.props.fields}
        striped
        hover
        itemsPerPage={10}
        pagination={{
          align: 'end'
        }}
        scopedSlots = {{
          'filename': (item, index) => {
            let extraClass = ''
            if (!item.visitors[UserStores.actualUser.id]) {
              extraClass = 'no-visit'
            }
            return <td className={`${extraClass} break_line`}>
              <strong className="cursor-pointer" onClick={this.props.actionDetail.bind(this.props.parent, item.id)}>{item.filename}</strong>
              {(item && item.document_data && item.document_data.RemainingSaldo && item.document_data.RemainingSaldo === 0)?
                <CBadge color={'success'}><Translation>Zaplaceno</Translation></CBadge>
              : null}
              {(item.document_data && item.document_data.description)?
                <div>
                  <small><strong>Poznámka</strong>: {item.document_data.description}</small>
                </div>
                : null}
            </td>
          },
          'label': (item, index) => {
            const labels = [];
            let extraClass = ''
            if (!item.visitors[UserStores.actualUser.id]) {
              extraClass = 'no-visit'
            }

            if (item.document_data && item.document_data.hold_payment && item.document_data.hold_payment === 'on') {
              labels.push(<DocumentLabel color={'#e55353'} name={"Pozdržená platba"} key={'0_0'} />)
            }

            if (item.labels.length > 0) {

              for(let i in item.labels) {
                const label = item.labels[i];
                labels.push(<DocumentLabel color={label.color} name={label.name} key={i} />)
              }
            }

            return <td className={`${extraClass}`}>
              {labels}
            </td>;

          },

          'document_approval': (item) => {
            let extraClass = ''
            if (!item.visitors[UserStores.actualUser.id]) {
              extraClass = 'no-visit'
            }

            if (CompanyStores.detail && CompanyStore.detail.hasApprovals) {

              let approvals = {
                done: 0,
                text: null,
                total: item.document_approval.length
              }

              if (item.document_approval.length > 0) {
                const sortApprovals = item.document_approval.sort((a, b) => a.order - b.order )
                for(let i in sortApprovals) {
                  const approval_user = item.document_approval[i];

                  if (approval_user.status === 'approve') {
                    approvals.done++;
                  }

                  if (!approvals.text) {
                    if (approval_user.status === 'reject') {
                      if (approval_user.order === 0 ) {
                        approvals.text = (<span>
                                <CBadge color={'danger'}><Translation>Odmítnuto a čeká</Translation></CBadge>
                          {approval_user.user.email}
                              </span>)
                      } else {
                        if (sortApprovals.length <= (parseInt(i) - 1) && sortApprovals[parseInt(i) - 1]) {
                          approvals.text = (<span>
                                  <CBadge color={'danger'}><Translation>Odmítnuto a čeká</Translation></CBadge>
                            {sortApprovals[parseInt(i) - 1].user.email}
                                </span>)
                        }
                      }
                    } else if (approval_user.status === 'wait') {

                      if (sortApprovals.length <= (parseInt(i) +1) && sortApprovals[parseInt(i) +1]) {
                        if (sortApprovals[parseInt(i) +1].status === 'reject') {
                          approvals.text = (<span>
                                        <CBadge color={'danger'}><Translation>Odmítnuto a čeká</Translation></CBadge>
                            {approval_user.user.email}
                                  </span>)
                        }
                      } else {

                        if (sortApprovals.length <= (parseInt(i) +1) && sortApprovals[parseInt(i) +1]) {
                          if (sortApprovals[parseInt(i) +1].status === 'reject') {
                            approvals.text = (<span>
                                    <CBadge color={'danger'}><Translation>Odmítnuto a čeká</Translation></CBadge>
                              {sortApprovals[parseInt(i) +1].user.email}
                                  </span>)
                          }
                        } else {

                          approvals.text = (<span>
                                  <CBadge color={'info'}><Translation>čeká</Translation></CBadge>
                            {approval_user.user.email}
                                  </span>)
                        }
                      }

                    }
                  }
                }
              }

              return (<td className={`${extraClass}`}>

                <div><CTooltip
                  content={<React.Fragment><Translation>Schvalování</Translation></React.Fragment>}
                  placement={"top"}
                >
                  {(approvals.done === approvals.total && approvals.total !== 0)?
                    <CBadge color={'success'}>schváleno</CBadge>
                    :
                    <span>{approvals.text}</span>
                  }
                </CTooltip></div>
              </td>)
            } else {
              return <td className={`${extraClass}`}>
                -
              </td>
            }
          },
          'email_from' : (item) => {
            let extraClass = ''
            let text = item.email_from;
            if (!item.visitors[UserStores.actualUser.id]) {
              extraClass = 'no-visit'
            }

            if (item.document_data && item.document_data.company_name) {
              text = item.document_data.company_name;
            } else if (!item.email_from && item.enter_from) {
              if (item.enter_from === 'upload') {
                text = 'Přidán přes web'
              } else {
                text = 'Přidán přes Eduarda'
              }
            }


            return <td className={`${extraClass}`}>
              {text}
            </td>
          },
          'createdAt' : (item) => {
            let extraClass = ''
            if (!item.visitors[UserStores.actualUser.id]) {
              extraClass = 'no-visit'
            }

            return <td className={`${extraClass}`}>
              {DateTime.fromJSDate(new Date(item.createdAt)).toFormat(GLOBAL_DATE_TIME_FORMAT)}
            </td>
          },
          'internal_number' : (item) => {
            let extraClass = ''
            if (!item.visitors[UserStores.actualUser.id]) {
              extraClass = 'no-visit'
            }
            return <td className={`${extraClass}`}>
              {item.internal_number}
            </td>
          },
          'accounting_date': (item) => {
            let extraClass = ''
            if (!item.visitors[UserStores.actualUser.id]) {
              extraClass = 'no-visit'
            }
            return <td className={`${extraClass}`}>
              {(item.accounting_date)?
                DateTime.fromJSDate(new Date(item.accounting_date)).toFormat(GLOBAL_DATE_FORMAT)
              : ' - '}
            </td>
          },

          'paid_date': (item) => {
            let extraClass = ''
            if (!item.visitors[UserStores.actualUser.id]) {
              extraClass = 'no-visit'
            }
            return <td className={`${extraClass}`}>
              {(item.paid_date)?
                DateTime.fromJSDate(new Date(item.paid_date)).toFormat(GLOBAL_DATE_FORMAT)
              : " - "}
            </td>
          },


          'action' : (item) => {
            let extraClass = ''
            if (!item.visitors[UserStores.actualUser.id]) {
              extraClass = 'no-visit'
            }

            return <td className={`${extraClass}`}>
              <CTooltip
                content={<Translation>Prohlédnout dokument</Translation>}
                placement={"top"}
              >
                <CButton
                  key={'0'}
                  color={'info'}
                  variant={'ghost'}
                  onClick={this.props.actionDetail.bind(this.props.parent, item.id)}
                >
                  <CIcon name="cil-magnifying-glass"/>
                </CButton>
              </CTooltip>
              {(item.filename !== 'E-mail bez dokumentu')?
                <CTooltip
                  content={<Translation>Stáhnout dokument</Translation>}
                  placement={"top"}
                >
                  <CButton
                    key={'1'}
                    color={'success'}
                    variant={'ghost'}
                    className={"ml-2"}
                    onClick={this.handleDownload.bind(this, item.id)}
                  >
                    {/*<a href={process.env.REACT_APP_API_URL+"/document/" + CompanyStore.detail.id + "/download/" + item.id} download={true}  target={"_blank"}>*/}
                    <CIcon name="cil-cloud-download"/>
                    {/*</a>*/}
                  </CButton>
                </CTooltip>
                : null}

              {(hasUserWriteRole(MODULES.documents) && !item.archive)?
                <CTooltip
                  content={<Translation>Smazat dokument</Translation>}
                  placement={"top"}
                >
                  <CButton
                    key={'2'}
                    color={'danger'}
                    variant={'ghost'}
                    className={"ml-2"}
                    onClick={this.handleRemoveDocument.bind(this, item)}
                  >
                    <CIcon name="cil-trash"/>
                  </CButton>
                </CTooltip>
                : null}
            </td>
          }
        }}
      />
      <div className={"text-right"}>
        <small><Translation>Celkem dokladů</Translation> {(this.props.data && toJS(this.props.data).length)? toJS(this.props.data).length: 0}</small>
      </div>
      {/*<Pagination*/}
      {/*  activePage={CompanyStore.actualDocumentPage}*/}
      {/*  pages={CompanyStore.documents_pages}*/}
      {/*  pageChange={this.props.pageChange}*/}
      {/*/>*/}
    </>
  }
}
export default observer(DocumentsTable)

import React, {Component, Suspense} from "react";
import AuthStore from "../../stores/Auth";
import {TheFooter, TheHeader} from "../../containers";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CFade,
  CFormGroup,
  CLabel,
  CRow,
  CInput,
  CModal,
  CModalBody,
  CModalHeader,
  CInputGroup, CInputGroupAppend, CInputGroupText
} from "@coreui/react";
import {AiOutlinePlus} from "react-icons/ai"
import LoadingPage from "../LoadingPage";
import {observer} from "mobx-react";
import CompanyStore from './../../stores/Company'
import Translation from "../translation";
import ButtonDefault from "../Button";
import {setLocalStorageValue} from "../../helpers/localStorage";
import UserStores from "../../stores/User";
import AgendaStore from "../../stores/Agenda"
import UserStore from "../../stores/User";
import {toJS} from "mobx";
import ReactTooltip from "react-tooltip";

class ListOfCompanies extends Component{

  constructor(props) {
    super(props);
    this.state = {
      hasCompany: false,
      new_ic: '',
      company_data: null,
      is_new: false,
      show_modal: false,
      filter_name: '',
      companies: []
    }
  }

  async componentDidMount() {
    await UserStores.getCompanies()
    this.setState({
      companies: CompanyStore.companies
    })
  }

  closeModal() {
    this.setState({
      show_modal: !this.state.show_modal
    })
  }

  async getCompanyInformation() {
    let data = await CompanyStore.findAresCompany(this.state.new_ic)
    if (data) {
      this.setState({
        company_data: data.company,
        is_new: data.new
      })
    }
  }

  async createCompany() {
    if (await CompanyStore.createCompany(this.state.company_data)) {
      this.closeModal()
      await UserStores.getCompanies()
    }
  }

  async enterToCompany(companyId) {
    if (AuthStore.isLogin()) {
      CompanyStore.setLoadingCreating(true);
      setLocalStorageValue('companyId', companyId);
      if (companyId) {
        await CompanyStore.getCompany(companyId);
        AgendaStore.getAll()
        CompanyStore.setLoadingCreating(false);
        window.location = '/login'
      }
      this.setState({
        hasCompany: true
      })
    }
  }

  renderListOfCompanies() {
    const companiesHtml = [];
    let companies = this.state.companies;

    companies = toJS(companies).sort(function(a, b){
      if(a.name?.toLowerCase() < b.name?.toLowerCase()) { return -1; }
      if(a.name?.toLowerCase() > b.name?.toLowerCase()) { return 1; }
      return 0;
    })

    for(let i in companies) {
      const company = companies[i];
      companiesHtml.push(<CCol sm="12" xl="6" key={i} className={"company-area"}>
        <CCard>
          <CCardHeader>
            <h3>{company.name}</h3>
          </CCardHeader>
          <CCardBody>
            {/*<FaRegBuilding size={"5rem"}/>*/}
            <div>
              <ul className="list-unstyled">
                <li className=""><strong><Translation>IČ</Translation>:</strong> {company.ic}</li>
              </ul>
            </div>
            <div className="w-100">

              <ButtonDefault
                loading={CompanyStore.loadingCreating}
                CButtonSettings={{
                  color: "success",
                  className: "",
                  variant: '',
                  onClick: this.enterToCompany.bind(this, company.id),
                }}
              >
                <Translation>Vstoupit do společnosti</Translation>
              </ButtonDefault>
            </div>
          </CCardBody>
        </CCard>

      </CCol>);
    }

    if (companiesHtml.length === 0) {
      companiesHtml.push(<CCol sm="12" xl="12" className="empty-company-list text-center" key={1}>
        <h5><Translation>Váš seznam společností je prázdný</Translation></h5>
      </CCol>)
    }

    return companiesHtml
  }

  handleChangeIco(e) {
    this.setState({
      new_ic: e.target.value
    })
  }

  cleanEnterIc() {
    this.setState({
      new_ic: '',
      is_new: null,
      company_data: null
    })
  }

  handleChangeFilename(e) {

    let companies = toJS(CompanyStore.companies).filter(function (company) { return company?.name?.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 || company?.ic?.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1; });

    this.setState({
      filter_name: e.target.value,
      companies: companies
    })
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      if (this.state.companies.length === 1) {
        this.enterToCompany(this.state.companies[0].id)
      }
    }
  }

  render() {

    if (UserStore.loadingCompany) {
      return <LoadingPage/>
    }

    return (<div className="c-app c-default-layout" key={this.state.hasCompany}>

      <div className="c-wrapper">
        <TheHeader/>
        <div className="c-body">
          <main className="c-main">
            <CContainer fluid>
              <Suspense fallback={LoadingPage}>
                <CFade>
                  <CFormGroup row className="align-items-center">
                    <CCol md="12">
                      <CLabel htmlFor="email-input" className=" mb-0">
                        <strong>
                          <Translation>Vyhledat společnost</Translation>
                        </strong>
                      </CLabel>
                      {/*</CCol>*/}
                      {/*<CCol md="9">*/}
                      <CInputGroup>
                        <CInput
                          autoFocus
                          data-tip data-for='store_tooltip' data-event='click'
                          type="text"
                          name="filter_name"
                          value={this.state.filter_name}
                          onKeyPress={this.handleKeyPress.bind(this)}
                          onBlur={()=> {
                          }}
                          onChange={this.handleChangeFilename.bind(this)}
                        />
                      </CInputGroup>
                    </CCol>
                  </CFormGroup>
                  <CRow>
                    {this.renderListOfCompanies()}
                    <CCol sm="12" xl="6" key={CompanyStore.companies.length} className={"company-area"}>
                      <div className={"w-100 my-5 text-success d-flex flex-column align-items-center justify-content-center"}>
                        <div className={"cursor-pointer d-flex flex-column align-items-center justify-content-center"} onClick={this.closeModal.bind(this)}>
                          <div>
                            <AiOutlinePlus size={80}/>
                          </div>
                          <div>
                            <Translation>Přidat novou společnost</Translation>
                          </div>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </CFade>
              </Suspense>
            </CContainer>
          </main>
        </div>
        <TheFooter/>
      </div>

      <CModal
        show={this.state.show_modal}
        centered={true}
        onClose={() => this.closeModal()}
        size={'md'}
      >
        <CModalHeader>
          <h3><Translation>Přidat novou společnost</Translation></h3>
        </CModalHeader>
        <CModalBody>
          {/*<CCard>*/}
          {/*  <CCardHeader>*/}
          {/*    <h3><Translation>Přidat novou společnost</Translation></h3>*/}
          {/*  </CCardHeader>*/}
          {/*  <CCardBody>*/}
              {/*<FaRegBuilding size={"5rem"}/>*/}
              {(this.state.company_data)?
                <div>
                  <div>
                    <div><strong>Název:</strong> {this.state.company_data.name}</div>
                    <div><strong>IČ:</strong> {this.state.company_data.ic}</div>
                  </div>
                  {this.state.is_new?
                    <div>
                      <div className={"my-3"}>
                        <strong>Opravdu si přejete společnost vytvořit?</strong>
                      </div>
                      <div className={"w-100 d-inline-flex justify-content-between align-items-center"}>
                        <ButtonDefault
                          loading={CompanyStore.loadingCreating}
                          CButtonSettings={{
                            color: "danger",
                            className: "",
                            variant: 'outline',
                            onClick: this.cleanEnterIc.bind(this),
                          }}
                        >
                          <Translation>Zadat jiné IČ</Translation>
                        </ButtonDefault>
                        {(this.state.company_data.name)?
                          <ButtonDefault
                            loading={CompanyStore.loadingCreating}
                            CButtonSettings={{
                              color: "success",
                              className: "",
                              variant: '',
                              onClick: this.createCompany.bind(this),
                            }}
                          >
                            <Translation>Vytvořit</Translation>
                          </ButtonDefault>
                          : null}
                      </div>
                    </div>
                    :
                    <>
                      <div className={"text-left my-3"}>
                        <strong>
                          Společnost již existuje. Kontaktujte společnost se žádostí o přidání do systému.
                        </strong>
                      </div>
                      <div>
                        <ButtonDefault
                          loading={CompanyStore.loadingCreating}
                          CButtonSettings={{
                            color: "success",
                            className: "",
                            variant: 'outline',
                            onClick: this.cleanEnterIc.bind(this),
                          }}
                        >
                          <Translation>Zadat jiné IČ</Translation>
                        </ButtonDefault>
                      </div>
                    </>
                  }

                </div>
                :
                <>
                  <div className={'mb-2'}>
                    <Translation>Pro přidání nové společnosti zadejte IČO společnosti</Translation>
                  </div>
                  <div>
                    <CFormGroup row className={"align-items-center"}>
                      <CCol md={`1`}>
                        <CLabel htmlFor="email-input mb-0">
                          <strong>
                            <Translation>IČO</Translation>
                          </strong>
                        </CLabel>
                      </CCol>
                      <CCol md={`9`}>
                        <CInput name={"new_ic"} defaultValue={this.state.new_ic} onChange={this.handleChangeIco.bind(this)} />
                      </CCol>
                    </CFormGroup>
                  </div>
                  <div className="w-100">

                    <ButtonDefault
                      loading={CompanyStore.loadingAres}
                      CButtonSettings={{
                        color: "success",
                        className: "",
                        variant: '',
                        onClick: this.getCompanyInformation.bind(this),
                      }}
                    >
                      <Translation>Vyhledat</Translation>
                    </ButtonDefault>
                  </div>
                </>
              }

        </CModalBody>
      </CModal>
    </div>)
  }
}

export default observer(ListOfCompanies)

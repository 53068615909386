import React, {useEffect} from "react";
import Translation from "../../../../components/translation";
import {
  CBadge,
  CButton,
  CDataTable,
  CTooltip
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import CustomersStore from "../../../../stores/Admin/Customers";
import {toJS} from "mobx";
import {observer} from "mobx-react";

const AdminCustomers = observer((props) => {

  useEffect(() => {
    async function getAll() {
      await CustomersStore.getAll()
    }

    getAll()
  }, [])


  const fields = [
    {
      label: <Translation>Název</Translation>,
      key: 'name'
    },
    {
      label: <Translation>Fakturační období</Translation>,
      key: 'invoice_period',
      _style: { width: '20%'}
    },

    {
      label: <Translation>Akce</Translation>,
      key: 'action',
      _style: { width: '20%'}
    },
  ]

  const handleEdit = (e) => {

  }

  const handleRemove = (e) => {

  }

  const handleCreate = () => {
    props.history.push('/admin/zakaznici/novy')
  }

  const handleShowDetail = (id) => {

  }

  return <React.Fragment>
    <div className="card border-radius-t0 border-top-0">
      <div className="card-body">
        <div className="body-content">

          <div className="d-inline-flex justify-content-between w-100 mb-2 align-items-center">
            <div>
              <h4>
                <Translation>Zákazníci</Translation>
              </h4>
            </div>
            <div className="button-area">

              <CButton
                key={'create_user'}
                color={'success'}

                className="align-items-center d-inline-flex"
                onClick={handleCreate}
              >
                <CIcon name="cil-user-follow" className="mr-2" />
                <Translation>Vytvořit zákazníka</Translation>
              </CButton>

            </div>
          </div>
          <div>
            <CDataTable
              items={toJS(CustomersStore.data)}
              fields={fields}
              hover
              striped
              // bordered
              size="sm"
              itemsPerPage={10}
              pagination={{
                align: 'end'
              }}
              scopedSlots = {{
                "name": (item) => (
                  <td className="cursor-pointer" onClick={()=> handleShowDetail(item.id)}>
                   <div>
                     <strong>
                       {item.name}
                     </strong>
                   </div>
                    <div>
                      <small>IČ: {item.ic}</small>
                    </div>
                  </td>
                ),
                'invoice_period': (item) => (
                  <td>
                    {item.invoice_period === 'annual'?

                        <CBadge color="primary" shape={['rounded-pill']}>
                          <Translation>roční</Translation>
                        </CBadge>
                    :
                      <CBadge color="primary" shape={['rounded-pill']}>
                        <Translation>měsíční</Translation>
                      </CBadge>
                    }

                  </td>
                ),
                'action':
                  (item)=>(
                    <React.Fragment>

                      <td>
                        <CTooltip
                          content={<Translation>Upravit uživatele</Translation>}
                          placement={"top"}
                        >
                          <CButton
                            key={'0'}
                            color={'warning'}
                            variant={'ghost'}
                            onClick={()=> handleEdit(item.id)}
                          >
                            <CIcon name="cil-pencil"/>
                          </CButton>
                        </CTooltip>

                          <CTooltip
                            content={<Translation>Odebrat uživatele ze společnosti</Translation>}
                            placement={"top"}
                          >
                            <CButton
                              key={'1'}
                              color={'danger'}
                              variant={'ghost'}
                              className={"ml-2"}
                              onClick={() => handleRemove(item)}
                            >
                              <CIcon name="cil-trash"/>
                            </CButton>
                          </CTooltip>

                      </td>

                    </React.Fragment>
                  )
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
})

export default AdminCustomers
